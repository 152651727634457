import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import {cn} from "../../utils/cn"



import { useEffect } from "react";
import styled from '@emotion/styled';
import { IconMenu } from "@tabler/icons-react";
import { LogoutButton } from '../../components/LogoutButton';

interface ApplicationNavbarProps {
  className?: string;
  openMenuClassName?: string;
}

const ApplicationNavbar: React.FC<ApplicationNavbarProps> = ({className, openMenuClassName = "bg-gray-100"}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const user = useUser();
  const location = useLocation();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const loggedIn = user.state === "LoggedIn";

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [location]);


  // Close sidebar when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);

  return (
    <MenuContainer menuOpen={menuOpen} ref={menuRef} className={cn("flex text-black bg-transparent transition-colors duration-700 flex-col absolute top-0 pt-16 lg:pt-3 px-4 w-full lg:px-8 z-10", menuOpen ? openMenuClassName : "", className)}>
        <div className="absolute left-5 top-3 lg:top-5 text-2xl font-bold z-[101]">
          <Link to="/" className="text-2xl font-bold ">
            <img src="/assets/testsuite/logoIcon.png" alt="Logo" className="h-10 inline-block mr-2" />
            TestSuite
          </Link>
        </div>
      <MenuIcon onClick={() => setMenuOpen(menuOpen => !menuOpen)} className="absolute right-4 top-4 lg:hidden stroke-current" />
      <MenuLinks className="flex flex-col gap-4 h-full lg:w-full justify-end lg:flex-row lg:items-center " menuOpen={menuOpen}>
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
            <Link to="/experiments" className="text-black text-center shadow-[0_0_0_1px_#fff_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 hidden md:block">Experiments</Link>
            <Link to="/datasets" className="text-black text-center shadow-[0_0_0_1px_#fff_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 hidden md:block">Datasets</Link>
            <Link to="/studies" className="text-black text-center shadow-[0_0_0_1px_#fff_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 hidden md:block">Studies</Link>
            <Link to="/llmjobs" className="text-black text-center shadow-[0_0_0_1px_#fff_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 hidden md:block">LLM Jobs</Link>
            <Link to="/ndgraphics" className="text-black text-center shadow-[0_0_0_1px_#fff_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 hidden md:block">DataCT</Link>
            <LogoutButton />
        </div>
      </MenuLinks>
    </MenuContainer>
  );
}

const MenuLinks = styled.nav<{ menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: max-height .5s ease-in-out;
  text-wrap: nowrap;

  ${props => props.menuOpen && `margin-bottom: 16px;`}

  @media (max-width: 1024px) { 
    ${props => props.menuOpen ? "max-height: 300px;" : "max-height: 0;"}
  }
  
  overflow: hidden;
  @media (min-width: 1024px) {
    height: 55px;
    width: 100%;
    flex-direction: row;
    margin: 0px;
  }
  `

const MenuIcon = styled(IconMenu)`
  width: fit-content;
  height: 48px;
  width: 48px;
  padding-bottom: 16px;
`

const MenuContainer = styled.div<{ menuOpen: boolean }>`
  @media (min-width: 1024px) {
    background: none;
  }

`

export default ApplicationNavbar;
