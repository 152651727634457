import { useEffect, useRef } from 'react';

interface CubeAnimationProps {
  width?: number;
  height?: number;
  size?: number;
  speed?: {
    x?: number;
    y?: number;
    z?: number;
    w?: number;
  };
  lineWidth?: number;
  color?: string;
}

export const CubeAnimation = ({
  width = Math.max(window.innerWidth, window.innerHeight),
  height = Math.max(window.innerHeight, window.innerHeight),
  size = 400,
  speed = { x: 0.005, y: 0.001, z: 0.005, w: 0.004 },
  lineWidth = 15,
  color = 'rgba(0, 0, 0, 0.5)',
}: CubeAnimationProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');
    if (!c) return;

    // Set canvas dimensions
    canvas.width = width;
    canvas.height = height;
    c.translate(canvas.width/2, canvas.height/2);
    c.strokeStyle = color;
    c.lineWidth = lineWidth;

    // Animation state
    let rx = 0, ry = 0, rz = 0, rw = 0;

    // Camera setup
    const Camera = {
      focalLength: 55,
      wFocalLength: 15,
      x: 0, y: 0, z: 0, w: 0,
      rotX: 0, rotY: 0, rotZ: 0
    };
    
    Camera.z = -(Camera.focalLength**2);
    Camera.w = -(Camera.wFocalLength**2);

    class Vertex {
      loc: number[];
      ploc: number[];

      constructor(x: number, y: number, z: number, w: number) {
        this.loc = [
          x/Camera.focalLength,
          y/Camera.focalLength,
          z/Camera.focalLength,
          w/Camera.focalLength
        ];
        this.ploc = [];
      }

      rotate(xr: number, yr: number, zr: number, wr: number) {
        // 4D Rotation on YW Axis
        let yy = this.loc[1];
        this.loc[1] = yy*Math.cos(wr)-this.loc[3]*Math.sin(wr);
        this.loc[3] = yy*Math.sin(wr)+this.loc[3]*Math.cos(wr);
        
        // Constants
        let x = this.loc[0];
        let y = this.loc[1];
        let z = this.loc[2];
        
        // Rotation Data
        let sx = Math.sin(xr);
        let sy = Math.sin(yr);
        let sz = Math.sin(zr);
        let cx = Math.cos(xr);
        let cy = Math.cos(yr);
        let cz = Math.cos(zr);
        
        // Repeating Parts of Equation
        let eq1 = sz*y+cz*x;
        let eq2 = cz*y-sz*x;
        let eq3 = cy*z+sy*eq1;
        
        // Applying Transformations
        this.loc[0] = cy*eq1-sy*z;
        this.loc[1] = sx*eq3+cx*eq2;
        this.loc[2] = cx*eq3-sx*eq2;
      }

      project() {
        // Projects 4D to 3D
        this.loc[3] -= Camera.w/Camera.wFocalLength;
        this.loc[0] = -this.loc[0]/this.loc[3]*Camera.wFocalLength;
        this.loc[1] = -this.loc[1]/this.loc[3]*Camera.wFocalLength;
        this.loc[2] = -this.loc[2]/this.loc[3]*Camera.wFocalLength;
        
        // Camera Location
        let x = this.loc[0]-Camera.x/Camera.focalLength;
        let y = this.loc[1]-Camera.y/Camera.focalLength;
        let z = this.loc[2]-Camera.z/Camera.focalLength;
        
        // Camera Rotation
        let sx = Math.sin(Camera.rotX);
        let sy = Math.sin(Camera.rotY);
        let sz = Math.sin(Camera.rotZ);
        let cx = Math.cos(Camera.rotX);
        let cy = Math.cos(Camera.rotY);
        let cz = Math.cos(Camera.rotZ);
        
        // Repeating Parts of Equation
        let eq1 = sz*y+cz*x;
        let eq2 = cz*y-sz*x;
        let eq3 = cy*z+sy*eq1;
        
        // Camera Transformations
        let dx = cy*eq1-sy*z;
        let dy = sx*eq3+cx*eq2;
        let dz = cx*eq3-sx*eq2;
        
        // Projection
        this.ploc = [
          Camera.focalLength/dz*dx*Camera.focalLength,
          Camera.focalLength/dz*dy*Camera.focalLength
        ];
      }
    }

    class Face {
      vertices: Vertex[];
      noCull?: boolean;

      constructor(v1: Vertex, v2: Vertex, v3: Vertex, v4: Vertex, noCull?: boolean) {
        this.vertices = [v1, v2, v3, v4];
        if (noCull) this.noCull = noCull;
      }

      show() {
        if (!c) return;
        c.beginPath();
        c.moveTo(this.vertices[0].ploc[0], this.vertices[0].ploc[1]);
        for (let i = 1; i < this.vertices.length; i++) {
          c.lineTo(this.vertices[i].ploc[0], this.vertices[i].ploc[1]);
        }
        c.closePath();
        c.stroke();
      }
    }

    class Cube4d {
      size: number;
      vertices: Vertex[];
      faces: Face[];
      rx: number;
      ry: number;
      rz: number;
      rw: number;

      constructor(
        offset: { x: number; y: number; z: number; w: number },
        rotation: { x: number; y: number; z: number; w: number },
        size: number
      ) {
        this.size = size;
        this.rx = rotation.x;
        this.ry = rotation.y;
        this.rz = rotation.z;
        this.rw = rotation.w;
        this.vertices = [];
        this.faces = [];
        this.initVertices(offset);
        this.initFaces();
        this.show();
      }

      initVertices(offset: { x: number; y: number; z: number; w: number }) {
        let w = this.size;
        for (let i = 0; i < 16; i++) {
          let b_x = i%2<1 ? w/2 : -w/2;
          let b_y = i%4<2 ? w/2 : -w/2;
          let b_z = i%8<4 ? w/2 : -w/2;
          let b_w = i%16<8 ? w/2 : -w/2;
          
          let v_x = b_x + offset.x;
          let v_y = b_y + offset.y;
          let v_z = b_z + offset.z;
          let v_w = b_w + offset.w;
          
          this.vertices[i] = new Vertex(v_x, v_y, v_z, v_w);
          
          if (Math.abs(this.rx) + Math.abs(this.ry) + Math.abs(this.rz) + Math.abs(this.rw) > 0) {
            this.vertices[i].rotate(this.rx, this.ry, this.rz, this.rw);
          }
          this.vertices[i].project();
        }
      }

      initFaces() {
        for (let i = 0; i < 4; i++) {
          this.faces.push(
            new Face(
              this.vertices[i*4],
              this.vertices[i*4+2],
              this.vertices[i*4+3],
              this.vertices[i*4+1]
            )
          );
          this.faces.push(
            new Face(
              this.vertices[i],
              this.vertices[i+(4*2)],
              this.vertices[i+(4*3)],
              this.vertices[i+(4*1)]
            )
          );
        }
      }

      show() {
        for (let i = 0; i < this.faces.length; i++) {
          this.faces[i].show();
        }
      }
    }

    function draw() {
      if (!c) return;
      const animationFrame = requestAnimationFrame(draw);
      c.clearRect(-canvas.width/2, -canvas.height/2, canvas.width, canvas.height);
      
      // Update rotation angles
      rx = (rx + (speed.x ?? 0)) % (Math.PI * 2);
      ry = (ry + (speed.y ?? 0)) % (Math.PI * 2);
      rz = (rz + (speed.z ?? 0)) % (Math.PI * 2);
      rw = (rw + (speed.w ?? 0)) % (Math.PI * 2);
      
      new Cube4d(
        { x: 0, y: 0, z: 0, w: 0 },
        { x: rx, y: ry, z: rz, w: rw },
        size
      );

      return () => cancelAnimationFrame(animationFrame);
    }

    draw();
  }, [width, height, size, speed, lineWidth, color]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%'
      }}
    />
  );
}; 