import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from "@tabler/icons-react";
import Modal from '../components/modal';
import Searchbar from '../components/Searchbar';
import { FullStudy as StudyType } from '../../api/OasisBackendApi';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import CreateStudy from '../components/forms/CreateStudy';
import { FullPageLoader } from '../components/loader';
import StudyCard from '../components/Cards/StudyCard';
import BackButton from '../../components/ui/back_button';

const Studies: React.FC = () => {
  const navigate = useNavigate();
  const [studies, setStudies] = useState<StudyType[] | undefined>(undefined);
  const [filteredStudies, setFilteredStudies] = useState<StudyType[]>([]);
  const [isCreateStudyModalOpen, setIsCreateStudyModalOpen] = useState(false);
  const db = useOasisBackend();

  useEffect(() => {
    const fetchStudies = async () => {
      try {
        const response = await db.endpoints.testsuite.testsuiteApiGetUserStudiesRetrieve();
        setStudies(response.data);
        setFilteredStudies(response.data);
      } catch (error) {
        console.error('Error fetching studies:', error);
      }
    };

    fetchStudies();
  }, [db.endpoints.testsuite]);

  const handleStudyCardClick = (studyId: number) => {
    navigate(`/manage-study/${studyId}`);
  };

  const handleFilterChange = useCallback((filteredItems: StudyType[]) => {
    setFilteredStudies(filteredItems);
  }, []);

  const searchableProps = useMemo(
    () => ['name', 'description', 'created_at', 'status'],
    []
  );

  const onStudyCreated = (newStudy: StudyType) => {
    console.log(newStudy);
    navigate(`/study/${newStudy.id}`);
    setIsCreateStudyModalOpen(false);
  };

  if (!studies) {
    return (
      <div className="h-full rounded bg-gray-100">
        <BackButton />
        <div className="container mx-auto py-12">
          <h1 className="text-4xl font-bold text-center mb-8">Your Studies</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <button
              onClick={() => setIsCreateStudyModalOpen(true)}
              className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center"
            >
              <IconPlus className="h-12 w-12 text-gray-600" />
            </button>
          </div>
        </div>
        <FullPageLoader />
        <Modal isOpen={isCreateStudyModalOpen} onClose={() => setIsCreateStudyModalOpen(false)} size="large">
          <CreateStudy/>
        </Modal>
      </div>
    );
  }

  return (
    <div className="h-full rounded bg-gray-100">
      <BackButton />
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Your Studies</h1>
        
        <Searchbar<StudyType>
          items={studies}
          searchableProperties={searchableProps}
          onFilterChange={handleFilterChange}
        />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <button
            onClick={() => setIsCreateStudyModalOpen(true)}
            className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center"
          >
            <IconPlus className="h-12 w-12 text-gray-600" />
          </button>

          {filteredStudies.map((study) => (
            <StudyCard key={study.id} study={study} onCardClick={handleStudyCardClick} />
          ))}
        </div>
      </div>

      <Modal isOpen={isCreateStudyModalOpen} onClose={() => setIsCreateStudyModalOpen(false)} size="large">
        <CreateStudy/>
      </Modal>
    </div>
  );
};

export default Studies;
