import { useState } from "react";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { SemanticSearchResult, Entity } from "../../api/OasisBackendApi";
import { toast, ToastContainer } from "react-toastify";
const SearchPlayground = ({ knowledgebase_id, oasis_enabled }: { knowledgebase_id: string, oasis_enabled: boolean }) => {
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const db = useOasisBackend();
    const [semanticResults, setSemanticResults] = useState<SemanticSearchResult[]>([]);
    const [oasisResults, setOasisResults] = useState<Entity[]>([]);
    const [selectedSearchTypes, setSelectedSearchTypes] = useState<string[]>(["semantic"]);
    const [semanticRerank, setSemanticRerank] = useState(false);
    const [oasisRerank, setOasisRerank] = useState(false);

    let availableSearchTypes: { label: string, value: string }[] = [];

    if (oasis_enabled) {
        availableSearchTypes = [
            {
                label: "Semantic",
                value: "semantic"
            },
            {
                label: "Oasis",
                value: "oasis"
            }
        ]
    }else{
        availableSearchTypes = [{
            label: "Semantic",
            value: "semantic"
        }]
    }

    const handleSearchTypeChange = (searchType: string) => {
        setSelectedSearchTypes(prev => {
            if (prev.includes(searchType)) {
                return prev.filter(type => type !== searchType);
            }
            return [...prev, searchType];
        });
    };

    const handleSearch = async () => {
        if (query.length === 0) {
            console.log("No query");
            toast.error("Please enter a query");
            return;
        }
        setLoading(true);
        setError(null);
        // Create an array of promises for all selected search types
        const searchPromises = [];

        if (selectedSearchTypes.includes('oasis')) {
            console.log("Oasis Search", query, oasisRerank);
            setOasisResults([]);
            // Assuming there's an Oasis search endpoint
            const oasisPromise = db.endpoints.api.apiKnowledgeBaseSearchRetrieve(knowledgebase_id, { 
                q: query, 
                type: "oasis",
                rerank: oasisRerank 
            })
                .then(response => setOasisResults(response.data.oasis));
            searchPromises.push(oasisPromise);
        }
        
        if (selectedSearchTypes.includes('semantic')) {
            console.log("Semantic Search", query, semanticRerank);
            setSemanticResults([]);
            const semanticPromise = db.endpoints.api.apiKnowledgeBaseSearchRetrieve(knowledgebase_id, { 
                q: query, 
                type: "semantic",
                rerank: semanticRerank 
            })
                .then(response => setSemanticResults(response.data.semantic));
            searchPromises.push(semanticPromise);
        }

        
        // Wait for all search requests to complete
        await Promise.all(searchPromises);
        
        setLoading(false);
    };
    
    return (
        <div className="flex flex-col gap-4 p-8">
            <ToastContainer />
            <input className="p-2 rounded-md bg-gray-800 text-white outline-none" type="text" value={query} onChange={(e) => setQuery(e.target.value)} />   
            <div className="flex flex-row gap-4 items-center">
                {availableSearchTypes.map((type) => (
                    <label key={type.value} className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={selectedSearchTypes.includes(type.value)}
                            onChange={() => handleSearchTypeChange(type.value)}
                        />
                        {type.label}
                    </label>
                ))}
            </div>
            <div className="flex flex-row gap-2">
                    <button 
                        className={`p-2 rounded-md ${selectedSearchTypes.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-400 hover:bg-gray-500'}`}
                        onClick={handleSearch}
                        disabled={selectedSearchTypes.length === 0}
                    >
                        Search
                    </button>
                </div>
            
            <div className="flex flex-row gap-4">
                {selectedSearchTypes.includes('semantic') && (
                    <div className="flex-1">
                        <div className="flex items-center gap-2 mb-2">
                            <h2 className="font-bold">Semantic Results</h2>
                            <label className="flex items-center gap-1">
                                <input
                                    type="checkbox"
                                    checked={semanticRerank}
                                    onChange={(e) => setSemanticRerank(e.target.checked)}
                                />
                                Rerank
                            </label>
                        </div>
                        {semanticResults.map((result, index) => (
                            <div key={`${result.document_id}-${index}`} className="bg-gray-800 p-4 rounded-md mb-2">
                                {(() => {
                                    try {
                                        const jsonData = JSON.parse(result.text);
                                        if (jsonData.images && jsonData.name) {
                                            return (
                                                <>
                                                    <h3>{jsonData.name}</h3>
                                                    <img src={jsonData.images[0]} alt={jsonData.name} />
                                                </>
                                            );
                                        }
                                        return <h3>{result.text}</h3>;
                                    } catch (e) {
                                        console.error("Could not parse semantic result", e);
                                        return <h3>{result.text}</h3>;
                                    }
                                })()}
                                <p className="text-gray-400">{result.document_id}</p>
                            </div>
                        ))}
                    </div>
                )}
                
                {selectedSearchTypes.includes('oasis') && (
                    <div className="flex-1">
                        <div className="flex items-center gap-2 mb-2">
                            <h2 className="font-bold">Oasis Results</h2>
                            <label className="flex items-center gap-1">
                                <input
                                    type="checkbox"
                                    checked={oasisRerank}
                                    onChange={(e) => setOasisRerank(e.target.checked)}
                                />
                                Rerank
                            </label>
                        </div>
                        {oasisResults.map((result) => (
                            <div key={result.content} className="bg-gray-800 p-4 rounded-md mb-2">
                                {result.content?.name && <h3>{result.content.name}</h3>}
                                {result.content?.images?.length > 0 && (
                                    <img src={result.content.images[0]} alt={result.content.name || 'Product image'} />
                                )}
                                <p>{JSON.stringify(result.content)}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchPlayground;