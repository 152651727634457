import React, { useEffect, useRef } from 'react';
import { Dot } from './dot';

interface RenderableObject {
  render: (ctx: CanvasRenderingContext2D, width: number, height: number) => void;
}

interface RendererProps {
  objects: RenderableObject[];
  width?: number;
  height?: number;
}

export const Renderer: React.FC<RendererProps> = ({ 
  objects, 
  width = '100%', 
  height = '100%' 
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas size to match container
    const updateCanvasSize = () => {
      const container = canvas.parentElement;
      if (!container) return;
      
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Render all objects
    objects.forEach(obj => {
      obj.render(ctx, canvas.width, canvas.height);
    });

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [objects]);

  return (
    <canvas
      ref={canvasRef}
      className="block w-full h-full bg-black"
      style={{ 
        width: width, 
        height: height 
      }}
    />
  );
};

export default Renderer;
