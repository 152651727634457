import React, { useState } from 'react';
import { BACKEND_URL } from '../../hooks/useOasisBackend';
import Cookies from 'js-cookie';
import { Document } from '../../api/OasisBackendApi';

interface UploadStatus {
    file: File;
    status: 'pending' | 'uploading' | 'success' | 'error';
    error?: string;
}

const DocumentUploader = ({knowledgeBaseId, onUploadSuccess}: {knowledgeBaseId: string, onUploadSuccess: (documentId: Document) => void}) => {
    const [uploadQueue, setUploadQueue] = useState<UploadStatus[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files).map(file => ({
                file,
                status: 'pending' as const,
            }));
            setUploadQueue(prev => [...prev, ...newFiles]);
        }
    };

    const uploadFile = async (fileStatus: UploadStatus): Promise<void> => {
        const formData = new FormData();
        formData.append('document_blob', fileStatus.file);
        formData.append('document_name', fileStatus.file.name);

        try {
            const response = await fetch(`${BACKEND_URL}/api/knowledge-base/${knowledgeBaseId}/documents/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken') || '',
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Upload failed for ${fileStatus.file.name}`);
            }

            const data = await response.json();
            setUploadQueue(prev =>
                prev.map(item =>
                    item.file === fileStatus.file
                        ? { ...item, status: 'success' }
                        : item
                )
            );
            onUploadSuccess(data as Document);
        } catch (error) {
            setUploadQueue(prev =>
                prev.map(item =>
                    item.file === fileStatus.file
                        ? { ...item, status: 'error', error: (error as Error).message }
                        : item
                )
            );
        }
    };

    const handleUpload = async () => {
        if (uploadQueue.length === 0 || !knowledgeBaseId) return;
        
        setIsUploading(true);
        
        // Filter for pending uploads only
        const pendingUploads = uploadQueue.filter(item => item.status === 'pending');
        
        // Update status to uploading for all pending files
        setUploadQueue(prev =>
            prev.map(item =>
                item.status === 'pending'
                    ? { ...item, status: 'uploading' }
                    : item
            )
        );

        try {
            // Upload all files concurrently
            await Promise.all(pendingUploads.map(uploadFile));
        } finally {
            setIsUploading(false);
        }
    };

    const removeFile = (fileToRemove: File) => {
        setUploadQueue(prev => prev.filter(item => item.file !== fileToRemove));
    };

    const retryUpload = async (fileStatus: UploadStatus) => {
        setUploadQueue(prev =>
            prev.map(item =>
                item.file === fileStatus.file
                    ? { ...item, status: 'uploading', error: undefined }
                    : item
            )
        );
        await uploadFile(fileStatus);
    };

    const retryAllFailed = async () => {
        setIsUploading(true);
        
        const failedUploads = uploadQueue.filter(item => item.status === 'error');
        
        // Update status to uploading for all failed files
        setUploadQueue(prev =>
            prev.map(item =>
                item.status === 'error'
                    ? { ...item, status: 'uploading', error: undefined }
                    : item
            )
        );

        try {
            await Promise.all(failedUploads.map(uploadFile));
        } finally {
            setIsUploading(false);
        }
    };

    const getStatusColor = (status: UploadStatus['status']) => {
        switch (status) {
            case 'pending': return 'text-gray-500';
            case 'uploading': return 'text-blue-500';
            case 'success': return 'text-green-500';
            case 'error': return 'text-red-500';
            default: return 'text-gray-500';
        }
    };

    const getStatusText = (status: UploadStatus['status']) => {
        switch (status) {
            case 'pending': return 'Pending';
            case 'uploading': return 'Uploading...';
            case 'success': return 'Uploaded';
            case 'error': return 'Failed';
            default: return 'Unknown';
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-xl mb-4">Document Uploader</h1>
            
            <div className="mb-4">
                <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="mb-2"
                    accept=".pdf,.doc,.docx,.txt,.csv"  // Add appropriate file types
                />
                <p className="text-sm text-gray-500">
                    Supported files: PDF, DOC, DOCX, TXT, CSV
                </p>
            </div>
            
            {uploadQueue.length > 0 && (
                <div className="mb-4">
                    <h2 className="text-lg mb-2">Files:</h2>
                    <div className="space-y-2">
                        {uploadQueue.map((item, index) => (
                            <div 
                                key={index} 
                                className="flex items-center justify-between p-2 border rounded"
                            >
                                <span className="truncate max-w-xs">
                                    {item.file.name}
                                </span>
                                <div className="flex items-center space-x-2">
                                    <span className={`${getStatusColor(item.status)}`}>
                                        {getStatusText(item.status)}
                                    </span>
                                    {item.status === 'pending' && (
                                        <button
                                            onClick={() => removeFile(item.file)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            ×
                                        </button>
                                    )}
                                    {item.status === 'error' && (
                                        <button
                                            onClick={() => retryUpload(item)}
                                            className="text-blue-500 hover:text-blue-700 text-sm"
                                            disabled={isUploading}
                                        >
                                            Retry
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            <button
                onClick={handleUpload}
                disabled={isUploading || uploadQueue.length === 0 || !knowledgeBaseId || !uploadQueue.some(item => item.status === 'pending')}
                className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
            >
                {isUploading ? 'Uploading...' : 'Upload Documents'}
            </button>

            {uploadQueue.some(item => item.status === 'error') && (
                <button
                    onClick={retryAllFailed}
                    disabled={isUploading}
                    className="ml-2 bg-gray-500 text-white px-4 py-2 rounded disabled:bg-gray-300 hover:bg-gray-600 transition-colors"
                >
                    Retry Failed Uploads
                </button>
            )}
        </div>
    );
};

export default DocumentUploader;