import React, { useState } from 'react';
import BarChart from '../charts/BarChart';
import LineChart from '../charts/LineChart';
import ScatterPlot from '../charts/ScatterPlot';
import CorrelationMatrix from '../charts/HeatMap';
import BoxChart from '../charts/BoxChart';
import Modal from '../modal';
import { FullAnalysis } from '../../../api/OasisBackendApi';
import { Link } from 'react-router-dom';
import { IconEye, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

interface AnalysisResultsCardProps {
  analysis: any;
  expanded?: boolean;
}

const AnalysisResultsCard: React.FC<AnalysisResultsCardProps> = ({ analysis, expanded = false }) => {
  const { outputs } = analysis;
  const [selectedChart, setSelectedChart] = useState<JSX.Element | null>(null);
  const [selectedChartIndex, setSelectedChartIndex] = useState<number>(-1);

  const navigateCharts = (direction: 'next' | 'prev') => {
    if (!outputs.charts) return;
    const chartEntries = Object.entries(outputs.charts);
    const totalCharts = chartEntries.length;
    
    let newIndex = direction === 'next' 
      ? selectedChartIndex + 1
      : selectedChartIndex - 1;

    // Handle cycling
    if (newIndex >= totalCharts) newIndex = 0;
    if (newIndex < 0) newIndex = totalCharts - 1;

    const [column, chartData] = chartEntries[newIndex];
    setSelectedChart(renderChart(column, chartData));
    setSelectedChartIndex(newIndex);
  };

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!selectedChart) return;
      
      if (e.key === 'ArrowRight') {
        navigateCharts('next');
      } else if (e.key === 'ArrowLeft') {
        navigateCharts('prev');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedChart, selectedChartIndex]);

  const handleChartClick = (column: string, chartData: any, index: number) => {
    setSelectedChart(renderChart(column, chartData));
    setSelectedChartIndex(index);
  };

  const renderChart = (column: string, chartData: any) => {
    const commonProps = {
      data: chartData.data,
      ...chartData.options
    };

    switch (chartData.type) {
      case 'bar':
        return (
          <BarChart
            key={column}
            {...commonProps}
          />
        );
      case 'line':
        return (
          <LineChart
            key={column}
            {...commonProps}
          />
        );
      case 'scatter':
        return (
          <ScatterPlot
            key={column}
            {...commonProps}
          />
        );
      case 'heatmap':
        return (
          <CorrelationMatrix
            key={column}
            {...commonProps}
          />
        );
      case 'box':
        return (
          <BoxChart
            key={column}
            {...commonProps}
          />
        );
      default:
        return null;
    }
  };

  if (expanded) {
    console.log('expanded');
  }

  return (
    <div className={`analysis-results-container p-5 ${expanded ? '' : 'max-h-[80vh] overflow-y-auto'}`}>
      {outputs.Stats && (
        <div className="stats-container mb-6">
          {analysis.search_collection && (
            <Link 
              to={`/search?collection_id=${analysis.search_collection.id}`}
              className="inline-flex items-center gap-2 px-4 py-2 bg-slate-500 text-white rounded-md hover:bg-slate-700 transition-colors"
            >
              <IconEye className="h-5 w-5" />
              View Search Results
            </Link>
          )}
          <h3 className='text-sm text-gray-500 mb-4'>Parameters</h3>
          <pre>{JSON.stringify(analysis.params, null, 2)}</pre>
          <h3 className="text-xl font-semibold mb-4">Statistics</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(outputs.Stats).map(([statName, value]) => (
              <div key={statName} className="bg-gray-100 rounded-lg p-4">
                <h4 className="font-medium text-lg mb-2">{statName}</h4>
                <p className="text-sm whitespace-pre-wrap">{
                    typeof value === 'number' ? value.toFixed(2) : 
                    typeof value === 'string' ? value :
                    Array.isArray(value) ? value.join(', ') :
                    value instanceof Date ? value.toLocaleDateString() :
                    value instanceof Object ? Object.entries(value).map(([key, val]) => `${key}: ${val}`).join(',\n') :
                    String(value)
                  }
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {outputs.charts && (
        <div className="charts-container">
          <h3 className="text-xl font-semibold mb-4">Charts</h3>
          <div className="charts-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(outputs.charts).map(([column, chartData], index) => (
              <div
                key={column}
                className="chart-wrapper bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform hover:scale-105 p-4 aspect-video"
                onClick={() => handleChartClick(column, chartData, index)}
              >
                <div className="w-[200%] h-[200%] scale-50 origin-top-left flex items-center justify-center">
                  {renderChart(column, chartData)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {outputs.errors && (
        <div className="errors-container">
          <h3 className="text-xl font-semibold mb-4">Errors</h3>
          <pre>{JSON.stringify(outputs.errors, null, 2)}</pre>
        </div>
      )}

      <Modal isOpen={!!selectedChart} onClose={() => {
        setSelectedChart(null);
        setSelectedChartIndex(-1);
      }} size='large'>
        <div className='relative p-10 w-full h-[90%]'>
          {selectedChart}
          {selectedChart && (
            <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-4">
              <button
                onClick={() => navigateCharts('prev')}
                className="p-2 bg-slate-500 text-white rounded-full hover:bg-slate-700 transition-colors"
              >
                <IconChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={() => navigateCharts('next')}
                className="p-2 bg-slate-500 text-white rounded-full hover:bg-slate-700 transition-colors"
              >
                <IconChevronRight className="h-6 w-6" />
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AnalysisResultsCard;
