import ApplicationNavbar from "../../components/ApplicationNavbar";
import SwaggerUI from "swagger-ui-react";
import styled from "@emotion/styled";
import "swagger-ui-react/swagger-ui.css"; // Make sure you import Swagger's default CSS first

const PRIMARY_COLOR = "#007bff";
const SUCCESS_COLOR = "#28a745";
const WARNING_COLOR = "#ffc107";
const DANGER_COLOR  = "#dc3545";
const FONT_FAMILY   = "'Open Sans', sans-serif";

const SwaggerContainer = styled.div`
  /* Layout & container styling */
  flex: 1;
  overflow: auto;
  padding: 2rem;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  /* Base typography */
  font-family: ${FONT_FAMILY};
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  /* Make sure we override swagger-ui classes from the parent scope */
  .swagger-ui {
    /* Tweak the global background if you like */
    background-color: #fafafa;
    color: #333;
    padding-bottom: 2rem; /* some extra spacing at bottom */
  }

  .swagger-ui .info hgroup.main {
    margin-bottom: 1.5rem;
  }

  .swagger-ui .info hgroup.main .title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.2em;
  }

  .swagger-ui .info .description,
  .swagger-ui .info .description p {
    font-size: 1rem;
    color: #555;
  }

  .swagger-ui .opblock-tag-section {
    margin: 2rem 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;
  }

  /* Tag heading (e.g. "documents", "knowledge-base") */
  .swagger-ui .opblock-tag {
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background-color: ${PRIMARY_COLOR};
    color: #fff;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  /* Expand/Collapse arrow for tag sections */
  .swagger-ui .opblock-tag button.expand-operation {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    margin-left: 0.5rem;
  }

  /* Individual operation blocks (GET, POST, etc.) */
  .swagger-ui .opblock {
    background-color: #fff;
    border-radius: 8px;
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    border-left: 5px solid #eee; /* Fallback border color; overridden by method color */
  }

  /* Summary bar at top of each operation */
  .swagger-ui .opblock-summary {
    background: #f7f7f7;
    padding: 8px 16px;
    border-radius: 6px;
    margin: -1rem -1.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* Path and method label */
  .swagger-ui .opblock-summary-path {
    font-size: 1rem;
    word-break: break-all; /* so long paths wrap nicely */
    color: #444;
  }
  .swagger-ui .opblock-summary-method {
    font-weight: bold;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    color: #fff;
    margin-right: 0.5rem;
    text-transform: uppercase;
  }
  .swagger-ui .opblock-summary-description {
    font-size: 0.9rem;
    color: #666;
  }

  /* Method colors */
  .swagger-ui .opblock-get .opblock-summary-method {
    background-color: ${SUCCESS_COLOR} !important;
  }
  .swagger-ui .opblock-post .opblock-summary-method {
    background-color: ${PRIMARY_COLOR} !important;
  }
  .swagger-ui .opblock-put .opblock-summary-method {
    background-color: ${WARNING_COLOR} !important;
    color: #000;
  }
  .swagger-ui .opblock-delete .opblock-summary-method {
    background-color: ${DANGER_COLOR} !important;
  }

  /* Try it out / Execute buttons */
  .swagger-ui .opblock .opblock-summary-control,
  .swagger-ui .btn.try-out__btn,
  .swagger-ui .btn.execute,
  .swagger-ui .btn.cancel {
    background-color: ${PRIMARY_COLOR};
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    margin: 0 4px;
    cursor: pointer;
  }
  .swagger-ui .opblock .opblock-summary-control:hover,
  .swagger-ui .btn.try-out__btn:hover,
  .swagger-ui .btn.execute:hover,
  .swagger-ui .btn.cancel:hover {
    background-color: #0056b3;
  }

  /* Authorization button */
  .swagger-ui .btn.authorize {
    background-color: #f5f5f5;
    color: #000;
    font-weight: 600;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 1rem;
  }
  .swagger-ui .btn.authorize.unlocked {
    color: ${PRIMARY_COLOR}; /* reflect "unlocked" state if you want */
    border-color: ${PRIMARY_COLOR};
  }
  .swagger-ui .btn.authorize:hover {
    background-color: ${PRIMARY_COLOR};
    color: #fff;
  }

  /* Inputs and textareas */
  .swagger-ui input[type="text"],
  .swagger-ui input[type="number"],
  .swagger-ui input[type="file"],
  .swagger-ui textarea {
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px;
    margin: 4px 0;
    font-size: 0.95rem;
  }

  /* The “Copy” button for the path */
  .swagger-ui .copy-to-clipboard {
    background: #eee;
    border-radius: 4px;
    padding: 4px;
    margin-right: 4px;
    cursor: pointer;
  }

  /* Expand arrow for each operation (right side) */
  .swagger-ui .opblock-control-arrow {
    background: none;
    border: none;
    cursor: pointer;
  }

  /* Models/Schemas section at the bottom */
  .swagger-ui section.models.is-open h4 {
    font-size: 1.2rem;
    margin: 1.5rem 0;
    font-weight: 600;
  }
  .swagger-ui section.models.is-open .model-box {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-bottom: 1rem;
  }
  .swagger-ui section.models.is-open .model-box .model-title {
    font-weight: 600;
    color: #333;
  }
`;

const ApiDocs = () => {
  return (
    <div className="flex flex-col h-screen pt-16 bg-gray-100">
      <h1 className="text-2xl font-semibold px-8 py-4">API Documentation</h1>
      <ApplicationNavbar />
      <SwaggerContainer>
        <SwaggerUI url="api-docs.yaml" tryItOutEnabled withCredentials />
      </SwaggerContainer>
    </div>
  );
};

export default ApiDocs;
