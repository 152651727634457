// empty component called OperationsCard

import React, { useEffect, useState } from 'react';
import { FullOperation as FullOperationType, LLMJob } from '../../../api/OasisBackendApi';
import { Link } from 'react-router-dom';
import OptionsButton from '../OptionsButton';
import Modal from '../modal';
import { IconX } from '@tabler/icons-react';
import { useOasisBackend } from '../../../hooks/useOasisBackend';

interface LLMOperationsCardProps {
    operation: FullOperationType;
    className?: string;
    system_input_color?: string;
    user_input_color?: string;
    input_dataset_color?: string;
    onCardClick?: () => void;
    buttons?: {
        label: string;
        onClick: () => void;
    }[];
}

interface LLMJobModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    LLMJobs: LLMJob[];
}

const LLMJobModal: React.FC<LLMJobModalProps> = ({ isOpen, setIsOpen, LLMJobs }) => {
    // State to track which jobs are expanded
    const [llmJobs, setLLMJobs] = useState<LLMJob[]>(LLMJobs);
    const [expandedJobs, setExpandedJobs] = useState<Set<number>>(new Set());
    const db = useOasisBackend();

    useEffect(() => {
        setLLMJobs(LLMJobs);
    }, [LLMJobs])

    const toggleJobExpansion = (jobId: number) => {
        setExpandedJobs(prev => {
            const newSet = new Set(prev);
            if (newSet.has(jobId)) {
                newSet.delete(jobId);
            } else {
                newSet.add(jobId);
            }
            return newSet;
        });
    };

    const redownloadLLMJob = async (jobId: number) => {
        console.log('Redownload LLM Job:', jobId);
        const response = await db.endpoints.testsuite.testsuiteApiRedownloadLlmJobCreate({ id: jobId });
        if (response.status === 200) {
            console.log('Job Redownloaded Successfully');
            // Update the job status in the modal
            const newLLMJob = response.data
            setLLMJobs(prev => {
                const updatedJobs = prev.map(job => {
                    if (job.id === jobId) {
                        return newLLMJob
                    }
                    return job;
                });
                return updatedJobs;
            }
            );
        }
    };

    const cancelLLMJob = async (jobId: number) => {
        console.log('Cancel LLM Job:', jobId);
        const response = await db.endpoints.testsuite.testsuiteApiCancelLlmJobCreate({ id: jobId });
        if (response.status === 200) {
            console.log('Job Cancelled Successfully');
            // Update the job status in the modal
            const newLLMJob = response.data
            setLLMJobs(prev => {
                const updatedJobs = prev.map(job => {
                    if (job.id === jobId) {
                        return newLLMJob
                    }
                    return job;
                });
                return updatedJobs;
            });
        }
    };

    const retryLLMJob = async (jobId: number) => {
        console.log('Retry LLM Job:', jobId);
        const response = await db.endpoints.testsuite.testsuiteApiRetryLlmJobCreate({ id: jobId });
        if (response.status === 200) {
            console.log('Job Retried Successfully');
            // Update the job status in the modal
            const newLLMJob = response.data
            setLLMJobs(prev => {
                const updatedJobs = prev.map(job => {
                    if (job.id === jobId) {
                        return newLLMJob
                    }
                    return job;
                });
                return updatedJobs;
                }
            );
        }
    };

    const redownloadAllFailed = async () => {
        console.log('Redownload All Failed');
        const response = await db.endpoints.testsuite.testsuiteApiRedownloadAllLlmJobsCreate();
        if (response.status === 200) {
            console.log('All Failed Jobs Redownloaded Successfully');
            // Update the job status in the modal
            const newLLMJobs = response.data as LLMJob[];
            setLLMJobs(newLLMJobs);
        }
    };



    return (
        <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        >
                <h2 className='text-2xl font-semibold mb-6'>LLM Jobs</h2>
                <button onClick={redownloadAllFailed}>
                    Redownload All Failed
                </button>
                <div className='flex flex-col gap-4'>
                    {llmJobs.map((job) => (
                        <div 
                            key={job.id} 
                            className='border border-gray-200 rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow'
                        >
                            <div className="flex justify-between items-center">
                                <div className="flex flex-col sm:flex-row sm:gap-4">
                                    <p className='font-medium'>ID: {job.id}</p>
                                    <p className='text-gray-600'>Created At: {new Date(job.created_at).toLocaleString()}</p>
                                    <p className='text-gray-600'>Status: {job.status}</p>
                                    <button 
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            cancelLLMJob(job.id);
                                        }} 
                                        className='text-red-500 hover:text-red-700 focus:outline-none'>
                                            Cancel
                                        </button>
                                    <button
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            retryLLMJob(job.id);
                                        }} 
                                        className='text-blue-500 hover:text-blue-700 focus:outline-none'>
                                            Retry
                                    </button>
                                    <button 
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            redownloadLLMJob(job.id);
                                        }} 
                                        className='text-green-500 hover:text-green-700 focus:outline-none'
                                        >
                                            Redownload
                                        </button>
                                </div>
                                <button 
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        toggleJobExpansion(job.id);
                                    }} 
                                    className='text-blue-500 hover:text-blue-700 focus:outline-none'
                                >
                                    {expandedJobs.has(job.id) ? 'Hide Details' : 'Show Details'}
                                </button>
                            </div>

                            {/* Error Message Section */}
                            {job.error_message && (
                                <div className='mt-4 bg-red-50 p-3 rounded'>
                                    <h3 className='text-lg font-medium mb-2'>Error:</h3>
                                    <p className='text-sm text-red-600'>{job.error_message}</p>
                                </div>
                            )}

                            {/* Pass Through Data Section */}
                            {job.pass_through_data && (
                                <div className='mt-4 bg-green-50 p-3 rounded'>
                                    <h3 className='text-lg font-medium mb-2'>Pass Through Data:</h3>
                                    <pre className='whitespace-pre-wrap text-sm text-green-600'>
                                        {JSON.stringify(job.pass_through_data, null, 2)}
                                    </pre>
                                </div>
                            )}

                            {/* Messages Section */}
                            {expandedJobs.has(job.id) && (
                                <div className='mt-4 bg-gray-50 p-3 rounded'>
                                    <h3 className='text-lg font-medium mb-2'>Messages:</h3>
                                    <pre className='whitespace-pre-wrap text-sm text-gray-800'>
                                        {JSON.stringify(job.messages, null, 2)}
                                    </pre>
                                </div>
                            )}

                            {/* Data Section (New) */}
                            {job.datum && (
                                <div className='mt-4 bg-blue-50 p-3 rounded'>
                                    <h3 className='text-lg font-medium mb-2'>Datum:</h3>
                                    <pre className='whitespace-pre-wrap text-sm text-blue-600'>
                                        {JSON.stringify(job.datum, null, 2)}
                                    </pre>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
        </Modal>
    );
};



const OperationsCard: React.FC<LLMOperationsCardProps> = ({ operation, className, system_input_color, user_input_color, input_dataset_color, onCardClick, buttons }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [IsLLMJobModalOpen, setIsLLMJobModalOpen] = useState(false);

    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    const viewLLMJobs = (e: any) => {
        e.stopPropagation();
        console.log('View LLM Jobs');
        setIsLLMJobModalOpen(true);
    };


    return (
        <>
            <LLMJobModal isOpen={IsLLMJobModalOpen} setIsOpen={setIsLLMJobModalOpen} LLMJobs={operation.llm_jobs as any || []} />
            <div
                className={`bg-white flex flex-col items-center justify-center shadow-md rounded-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-200 w-full ${className} relative`}
                onClick={(e) => {
                    e.stopPropagation();
                    onCardClick && onCardClick();
                }}
            >
                <div className="absolute top-2 right-2 text-xs text-gray-400">
                    ID: {operation.id}
                </div>
                {operation.llm_jobs && operation.llm_jobs.length > 0 ? (<div className="w-full" onClick={viewLLMJobs}>View LLM Jobs</div>) : null}
                {operation.minimum_cost && (<div className="w-full text-sm">Minimum Cost Not Including output and Images: {operation.minimum_cost}$</div>)}
                <div className="flex flex-col md:flex-row">
                    <div className='flex flex-col m-2 w-full md:w-1/5'>
                        <h2 className="text-lg font-semibold text-gray-800">{operation.name}</h2>
                        <p className="text-sm text-gray-600">
                            {showFullDescription
                                ? operation.description.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))
                                : truncateText(operation.description, 100)}
                            {operation.description.length > 100 && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowFullDescription(!showFullDescription);
                                    }}
                                    className="ml-2 text-gray-400 hover:text-gray-800 hover:underline hover:cursor-pointer italic"
                                >
                                    {showFullDescription ? 'show less' : 'show more'}
                                </button>
                            )}
                        </p>
                        <p className="text-sm text-gray-500 py-2">{operation?.execution_class}</p>
                        {operation?.allowed_params?.model ? (
                            <p className="text-sm text-gray-600 bg-blue-100 rounded-full my-2 p-2 w-fit">{operation?.model || 'No model selected'}</p>
                        ) : null}
                    </div>
                    <div className="flex flex-grow flex-row">
                        <div className="flex flex-col w-2/3">
                        {operation?.allowed_params?.input_dataset ? (
                            <div className='flex justify-end items-center'>
                            <div className="flex flex-wrap items-center">
                                {operation.input_dataset ? (
                                    <>
                                        <p className={`text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 ${input_dataset_color}`}>{truncateText(operation.input_dataset?.name || 'None', 20)}</p>
                                        
                                    </>
                                ) : (
                                    <>
                                        <p className="text-sm text-red-600 px-2 py-1 bg-red-100 rounded-md my-2">No dataset selected</p>
                                    </>
                                )}
                                <div className='w-5 border-b-2 border-gray-300'></div>
                            </div>
                            </div>
                        ) : null}

                            {operation.params && (
                                <>
                                    {operation?.allowed_params?.type && (
                                        <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2">Type: {operation?.params?.type ? operation.params.type : 'None'}</p>
                                    )}
                                    {operation?.allowed_params?.query && (
                                        <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2">Query: {operation?.params?.query ? operation.params.query : 'None'}</p>
                                    )}
                                    {operation?.allowed_params?.top_k && (
                                        <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2">Top K: {operation?.params?.top_k ? operation.params.top_k : 'None'}</p>
                                    )}
                                </>
                            )}

                            {operation?.allowed_params?.system_prompt_template ? (
                                <div className='flex justify-end items-center'>
                                    <div className="flex flex-wrap items-center">
                                        {operation.system_prompt_template ? (
                                            <>
                                                <p className={`text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 ${system_input_color}`}>{truncateText(operation.system_prompt_template?.dataset?.name || 'None', 20)}</p>
                                                <div className='w-5 border-b-2 border-gray-300'></div>
                                                <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2">{truncateText(operation.system_prompt_template?.name || 'None', 20)}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-sm text-red-600 px-2 py-1 bg-red-100 rounded-md my-2">No prompt template selected</p>
                                            </>
                                        )}
                                        <div className='w-2 border-b-2 border-gray-300'></div>
                                        <p className='text-sm text-gray-400'>SYSTEM</p>
                                        <div className='w-2 border-b-2 border-gray-300'></div>
                                    </div>
                                </div>
                            ) : null}

                            {operation?.allowed_params?.user_prompt_template ? (
                                <div className='flex justify-end items-center'>
                                    <div className="flex flex-wrap items-center">
                                        {operation.user_prompt_template ? (
                                            <>
                                                <p className={`text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2 ${user_input_color}`}>{truncateText(operation.user_prompt_template?.dataset?.name || 'None', 20)}</p>
                                                <div className='w-5 border-b-2 border-gray-300'></div>
                                                <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md my-2">{truncateText(operation.user_prompt_template?.name || 'None', 20)}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-sm text-red-600 px-2 py-1 bg-red-100 rounded-md my-2">No prompt template selected</p>
                                            </>
                                        )}
                                        <div className='w-2 border-b-2 border-gray-300'></div>
                                        <p className='text-sm text-gray-400'>USER</p>
                                        <div className='w-2 border-b-2 border-gray-300'></div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {operation?.allowed_params?.output_dataset ? (
                        <div className="flex flex-wrap 1/3 border-l-2 border-gray-300">
                            <div className='flex h-full justify-center items-center '>
                                <div className='w-4 border-b-2 border-gray-300'></div>
                                <div className='flex flex-col h-full justify-center'>
                                    {operation.output_dataset ? (
                                        <>
                                            <p className="text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md">{truncateText(operation.output_dataset.name, 20)}</p>
                                            <Link to={`/dataset/${operation.output_dataset.id}`} className='text-sm text-gray-600 px-2 py-1 rounded-md'>View</Link>
                                        </>
                                    ) : (
                                        <p className="text-sm text-red-600 px-2 py-1 bg-red-100 rounded-md">not selected</p>
                                    )}
                                </div>
                            </div>
                        </div>) : null}
                        </div>
                        
                        <div className='flex justify-end flex-col items-center m-2'>
                            <p className='text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md'>{operation.status}</p>
                            {operation.status === 'RUNNING' && operation.num_llm_jobs ? (
                                <div className='flex flex-col gap-1'>
                                    <p className='text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md'>
                                        Completed: {operation.num_llm_jobs.completed} / {operation.num_llm_jobs.total}
                                    </p>
                                    <p className='text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md'>
                                        Pending: {operation.num_llm_jobs.pending}
                                    </p>
                                    <p className='text-sm text-gray-600 px-2 py-1 bg-gray-100 rounded-md'>
                                        Running: {operation.num_llm_jobs.running}
                                    </p>
                                    <p className='text-sm text-red-600 px-2 py-1 bg-red-100 rounded-md'>
                                        Failed: {operation.num_llm_jobs.failed}
                                    </p>
                                </div>
                            ) : null}

                            
                        </div>
                    {buttons ? (
                        <OptionsButton buttons={buttons} className='absolute bottom-0 right-0' />
                    ) : null}
                </div>

            </div>
        </>

    );
};

export default OperationsCard;
