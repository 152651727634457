import React from 'react';
import Plot from 'react-plotly.js';
import { LineChartProps } from './ChartInterfaces';

const LineChart: React.FC<LineChartProps> = ({ 
  data, 
  title = 'Line Chart', 
  xAxisLabel, 
  yAxisLabel, 
  showLegend = false,
  lineColor = 'blue' 
}) => {
  // Handle both single line and multiple line data
  const traces = Array.isArray(data.yValues) && typeof data.yValues[0] === 'object'
    ? data.yValues.map((line: any, index: number) => ({
        x: data.xValues,
        y: line.values,
        name: line.name,
        type: 'scatter' as const,
        mode: 'lines+markers' as const,
        marker: { color: ['blue', 'red', 'green', 'purple'][index % 4] },
        line: { shape: 'spline' as const }
      }))
    : [{
        x: data.xValues,
        y: data.yValues,
        type: 'scatter' as const,
        mode: 'lines+markers' as const,
        marker: { color: lineColor },
        line: { shape: 'spline' as const }
      }];

  return (
    <Plot
      data={traces}
      layout={{
        title,
        xaxis: { title: xAxisLabel },
        yaxis: { title: yAxisLabel },
        margin: { t: 50, b: 40, l: 50, r: 10 },
        showlegend: showLegend,
      }}
      config={{responsive: true}}
      className="w-full h-full"
    />
  );
};

export default LineChart;