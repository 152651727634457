import { useEffect, useState } from 'react'
import { useOasisBackend } from '../hooks/useOasisBackend'


const Usage = () => {
    const db = useOasisBackend()
    const [totalPagesUsedToday, setTotalPagesUsedToday] = useState<number | null>(null)
    const [totalPagesRemainingToday, setTotalPagesRemainingToday] = useState<string | null>(null)

    const getUsage = async () => {
        const response = await db.endpoints.api.apiUsageRetrieve()
        console.log(response.data)
        setTotalPagesUsedToday(response.data.total_pages_used_today)
        setTotalPagesRemainingToday(response.data.total_pages_remaining_today)
    }

    useEffect(() => {
        getUsage()
    }, [])

    return (
        <div className="max-w-3xl mx-auto p-6 text-white">
            <h1 className="text-2xl font-bold mb-6">Usage</h1>
            <div>
                <div>
                    <h2>Total Pages Used Today</h2>
                    <p>{totalPagesUsedToday}</p>
                </div>
                <div>
                    <h2>Total Pages Remaining Today</h2>
                    <p>{totalPagesRemainingToday}</p>
                </div>
            </div>
        </div>
    )
}

export default Usage