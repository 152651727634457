// src/testsuite/utils/ObjectND.ts
import { VertexND } from './VertexND';
import { CameraND } from './CameraND';

export abstract class ObjectND {
  vertices: VertexND[] = [];
  protected averageDepth: number = 0;

  constructor(
    public dimensions: number,
    public position: number[] = Array(dimensions).fill(0),
    public color: string = '#FFFFFF'
  ) {}

  abstract project(camera: CameraND): void;
  abstract draw(ctx: CanvasRenderingContext2D): void;
  abstract rotate(rotation: number[]): void;

  getAverageDepth(): number {
    return this.averageDepth;
  }
}