export class CameraND {
  constructor(
    public focalLengths: number[],
    public position: number[],
    public rotation: number[]
  ) {}

  project(point: number[]): { projected: [number, number], depth: number } {
    let location = [...point];
    const n = point.length;

    // Project down dimensions recursively
    for (let dim = n; dim > 3; dim--) {
      location[dim-1] -= this.position[dim-1];

      // Create projection matrix for current dimension
      const projectionMatrix = [];
      for (let r = 0; r < dim; r++) {
        const row = [];
        for (let c = 0; c < dim-1; c++) {
          if (r === c) {
            row.push(1/(this.focalLengths[dim-1] - location[dim-1]));
          } else {
            row.push(0);
          }
        }
        projectionMatrix.push(row);
      }

      // Apply projection
      location = this.multiplyVectorMatrix(location.slice(0, dim), projectionMatrix);
    }

    // Get the 3D point before final projection
    const point3D = location.slice(0, 3);
    // Calculate depth before perspective projection
    const depth = point3D[2] - this.position[2];

    // Return both projected coordinates and depth
    return {
      projected: this.perspectiveProjection(point3D),
      depth: depth
    };
  }

  private perspectiveProjection(point: number[]): [number, number] {
    const near = 200;
    const far = 100000;
    const f = this.focalLengths[2];
    
    point[2] -= this.position[2];

    const projectedPoint = [
      point[0] * f + point[2],
      point[1] * f + point[2],
      point[2] + 2,
      -point[2]
    ];

    if (projectedPoint[3] !== 0) {
      projectedPoint[0] /= projectedPoint[3];
      projectedPoint[1] /= projectedPoint[3];
      projectedPoint[2] /= projectedPoint[3];
    }

    return [projectedPoint[0], projectedPoint[1]];
  }

  private multiplyVectorMatrix(vector: number[], matrix: number[][]): number[] {
    const result = new Array(matrix[0].length).fill(0);
    for (let i = 0; i < matrix[0].length; i++) {
      for (let j = 0; j < vector.length; j++) {
        result[i] += vector[j] * matrix[j][i];
      }
    }
    return result;
  }
}