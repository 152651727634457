import { useSearchParams } from "react-router-dom";
import { SignupForm } from "../../components/SignupButton";
import { useEffect, useState } from "react";
import { LoginForm } from "../../components/Login";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { useUser } from "../../hooks/useUser";



const ExternalOAuthSignIn = () => {
    const [searchParams, ] = useSearchParams();
    const app_slug = searchParams.get('app_slug');
    const redirect_url = searchParams.get('redirect_url');
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    const userApi = useUser();
    const db = useOasisBackend()

    useEffect(() => {
        if (userApi.state === "LoggedIn") {
            redirectWithCode()
        }
    }, [userApi.state])

    const redirectWithCode = async () => {
        if (!app_slug) {
            return
        }
        const response = await db.endpoints.externalOauthToken.externalOauthTokenCreate({app_slug: app_slug})
        if (redirect_url) {
            console.log("redirecting to", redirect_url)
            window.location.href = redirect_url + `?access_token=${response.data.access_token}&secret_token=${response.data.secret_token}`
        }
    }

    return(
        <div className="flex flex-col justify-center text-center w-full gap-4 pt-12">
            {app_slug && redirect_url ? (
                <div>
                    <h1 className="text-lg">Authenticate with Thoughtful Oasis</h1>
                    {loggingIn ? <div>Don't have an account? <button onClick={() => setLoggingIn(false)}  className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Sign Up</button></div> :
                    <div> Already have an account? <button onClick={() => setLoggingIn(true)}  className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Log In</button></div>}

                    {loggingIn ? 
                    <LoginForm successCallback = {redirectWithCode} /> :
                    <SignupForm successCallback = {redirectWithCode} /> }
                </div>
            ) : (
                <h1 className="text-lg">Authentication will not work, app is not specified</h1>
            )}
            

        </div>
    )

}

export default ExternalOAuthSignIn;