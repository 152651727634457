import React, { useState, useEffect } from 'react';
import { IconX } from "@tabler/icons-react";
import Fuse, { FuseResult } from 'fuse.js'; // Import Fuse and FuseResult

// Add new interfaces for sorting
interface SortConfig {
  property: string;
  direction: 'asc' | 'desc';
}

interface SearchbarProps<T> {
  items: T[];
  searchableProperties: string[]; // Allow string paths for nested properties
  onFilterChange: (filteredItems: T[]) => void;
  theme?: 'light' | 'dark';
}

// Add new interface for filter groups
interface FilterGroup<T> {
  property: keyof T;
  value?: string;
  min?: number;
  max?: number;
}

function getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => {
    if (Array.isArray(acc)) {
      // If the current part is an array, map over it and return the array of values
      return acc.map(item => item[part]);
    }
    return acc && acc[part];
  }, obj);
}

function Searchbar<T>({ items, searchableProperties, onFilterChange, theme = 'light' }: SearchbarProps<T>) {
  const [searchTerm, setSearchTerm] = useState('');
  const [generalSearch, setGeneralSearch] = useState('');
  const [selectedProperty, setSelectedProperty] = useState<keyof T | ''>('');
  const [filterGroups, setFilterGroups] = useState<FilterGroup<T>[][]>([[]]);

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [rangeFilter, setRangeFilter] = useState<{ min: number; max: number }>({ min: 0, max: Infinity });

  const [noResults, setNoResults] = useState(false);

  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const [selectedFilterIndices, setSelectedFilterIndices] = useState<{groupIndex: number, filterIndex: number} | null>(null);

  // Add theme-based classes
  const baseInputClasses = `p-2 border rounded ${
    theme === 'dark' 
      ? 'bg-gray-800 border-gray-700 text-gray-200 placeholder-gray-400'
      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
  }`;

  const baseSelectClasses = `p-2 border rounded ${
    theme === 'dark'
      ? 'bg-gray-800 border-gray-700 text-gray-200'
      : 'bg-white border-gray-300 text-gray-900'
  }`;

  const baseButtonClasses = `p-2 rounded ${
    theme === 'dark'
      ? 'bg-gray-700 text-gray-200 hover:bg-gray-600'
      : 'bg-slate-500 text-white hover:bg-slate-600'
  }`;

  useEffect(() => {
    let filteredItems = items;

    if (generalSearch) {
      const fuse = new Fuse(items, {
        keys: searchableProperties,
        threshold: 0.3,
        ignoreLocation: true,
      });
      filteredItems = fuse.search(generalSearch).map((result: FuseResult<T>) => result.item);
    }

    // Modified filtering logic to support OR combinations
    filteredItems = filteredItems.filter((item) =>
      // Every group of filters is combined with AND
      filterGroups.every((group) =>
        // Within each group, filters are combined with OR
        group.length === 0 || group.some((filter) => {
          const value = getNestedValue(item, filter.property as string);

          if (filter.min !== undefined && filter.max !== undefined) {
            return (
              typeof value === 'number' &&
              value >= filter.min &&
              value <= filter.max
            );
          } else if (filter.value !== undefined) {
            if (typeof value === 'boolean') {
              return value.toString() === filter.value;
            }
            return String(value)
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }
          return true;
        })
      )
    );

    // Apply sorting
    if (sortConfig) {
      filteredItems.sort((a, b) => {
        const aValue = getNestedValue(a, sortConfig.property);
        const bValue = getNestedValue(b, sortConfig.property);

        if (aValue === bValue) return 0;
        
        const comparison = aValue < bValue ? -1 : 1;
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      });
    }

    setNoResults(filteredItems.length === 0);
    onFilterChange(filteredItems);
  }, [filterGroups, items, onFilterChange, generalSearch, searchableProperties, sortConfig]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault(); // Prevent default behavior
        const searchInput = document.getElementById('general-search-input') as HTMLInputElement;
        if (searchInput) {
          searchInput.focus(); // Focus the search input
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (selectedProperty) {
      const newSuggestions = items
        .flatMap(item => {
          const nestedValue = getNestedValue(item, selectedProperty as string);
          // If nestedValue is an array, return it directly; otherwise, wrap it in an array
          return Array.isArray(nestedValue) ? nestedValue : [nestedValue];
        })
        .filter((val, index, self) => self.indexOf(val) === index) // Remove duplicates
        .filter(val => val.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleGeneralSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGeneralSearch(value);
  };

  const handlePropertyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProperty(e.target.value as keyof T);
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'min' | 'max') => {
    const value = parseFloat(e.target.value);
    setRangeFilter((prev) => ({
      ...prev,
      [type]: isNaN(value) ? (type === 'min' ? 0 : Infinity) : value,
    }));
  };

  const addFilter = () => {
    if (selectedProperty) {
      const value = getNestedValue(items[0], selectedProperty as string);
      let newFilter: FilterGroup<T>;

      if (typeof value === 'number' && rangeFilter) {
        newFilter = {
          property: selectedProperty,
          min: rangeFilter.min,
          max: rangeFilter.max,
        };
      } else if (typeof value === 'boolean') {
        newFilter = {
          property: selectedProperty,
          value: searchTerm || 'false',
        };
      } else if (searchTerm) {
        newFilter = {
          property: selectedProperty,
          value: searchTerm,
        };
      } else {
        return;
      }

      setFilterGroups(prevGroups => {
        if (selectedFilterIndices) {
          const { groupIndex } = selectedFilterIndices;
          
          // Add the new filter to the existing group
          return prevGroups.map((group, idx) => 
            idx === groupIndex 
              ? [...group, newFilter] // Add new filter to existing group
              : group
          );
        } else {
          // If no filter is selected, add as a new AND condition (new single-filter group)
          return [...prevGroups, [newFilter]];
        }
      });

      setSelectedFilterIndices(null);
      setSearchTerm('');
      setSelectedProperty('');
      setSuggestions([]);
      setRangeFilter({ min: 0, max: Infinity });
    }
  };

  const removeFilter = (groupIndex: number, filterIndex: number) => {
    setFilterGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex] = newGroups[groupIndex].filter((_, i) => i !== filterIndex);
      
      // Remove empty groups, but keep at least one empty group
      const filteredGroups = newGroups.filter(group => group.length > 0);
      return filteredGroups.length > 0 ? filteredGroups : [[]];
    });
    
    // Clear selection if the removed filter was selected
    if (selectedFilterIndices?.groupIndex === groupIndex && 
        selectedFilterIndices?.filterIndex === filterIndex) {
      setSelectedFilterIndices(null);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      addFilter();
    }
  };

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchTerm(e.target.value);
  };

  const renderInputField = () => {
    const value = getNestedValue(items[0], selectedProperty as string);

    if (Array.isArray(value)) {
      // Render dropdown for array properties
      return (
        <select
          value={searchTerm}
          onChange={handleDropdownChange}
          onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
          className={`w-full ${baseInputClasses}`}
        >
          <option value="">Select value</option>
          {value.map((val, index) => (
            <option key={index} value={val}>
              {val}
            </option>
          ))}
        </select>
      );
    } else if (typeof value === 'number') {
      // Render range input for number properties
      return (
        <div className="flex mb-2">
          <input
            type="number"
            placeholder="Min"
            onChange={(e) => handleRangeChange(e, 'min')}
            onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            className={`mr-2 ${baseInputClasses}`}
          />
          <input
            type="number"
            placeholder="Max"
            onChange={(e) => handleRangeChange(e, 'max')}
            onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            className={`${baseInputClasses}`}
          />
        </div>
      );
    } else if (typeof value === 'boolean') {
      // Render checkbox for boolean properties
      return (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={searchTerm === 'true'}
            onChange={(e) => setSearchTerm(e.target.checked ? 'true' : 'false')}
            onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            className="mr-2"
          />
          <label>{String(selectedProperty)}</label>
        </div>
      );
    } else {
      // Render text input for other types
      return (
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
          placeholder="Add filter..."
          className={`w-full ${baseInputClasses}`}
        />
      );
    }
  };

  const handleSort = (property: string) => {
    setSortConfig((prevSort) => {
      if (!prevSort || prevSort.property !== property) {
        return { property, direction: 'asc' };
      }
      return { property, direction: prevSort.direction === 'asc' ? 'desc' : 'asc' };
    });
  };

  const toggleSortDirection = () => {
    setSortConfig((prevSort) => 
      prevSort ? { ...prevSort, direction: prevSort.direction === 'asc' ? 'desc' : 'asc' } : null
    );
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    // If clicking the container, just unselect
    if ((e.target as HTMLElement).id === 'filter-groups-container') {
      setSelectedFilterIndices(null);
    }
  };

  return (
    <div className={`mb-4 ${theme === 'dark' ? 'text-gray-200' : 'text-gray-900'}`}>
      <div className="flex mb-2">
        <input
          id="general-search-input"
          type="text"
          value={generalSearch}
          onChange={handleGeneralSearchChange}
          placeholder="Search all properties..."
          className={`mr-2 flex-grow ${baseInputClasses}`}
        />
      </div>
      <div className="flex mb-2">
        <select
          value={String(selectedProperty)}
          onChange={handlePropertyChange}
          className={`mr-2 ${baseSelectClasses}`}
        >
          <option value="">Select property</option>
          {searchableProperties.map((prop) => (
            <option key={String(prop)} value={String(prop)}>
              {String(prop)}
            </option>
          ))}
        </select>
        <div className="relative flex-grow">
          {renderInputField()}
          {suggestions.length > 0 && (
            <ul className={`absolute border rounded mt-1 max-h-40 overflow-y-auto w-full ${
              theme === 'dark'
                ? 'bg-gray-800 border-gray-700'
                : 'bg-white border-gray-300'
            }`}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSearchTerm(suggestion);
                    setSuggestions([]);
                  }}
                  className={`p-2 cursor-pointer ${
                    theme === 'dark'
                      ? 'hover:bg-gray-700'
                      : 'hover:bg-gray-200'
                  }`}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button onClick={addFilter} className={`ml-2 ${baseButtonClasses}`}>
          Add Filter
        </button>
      </div>
      <div 
        id="filter-groups-container"
        className={`flex flex-wrap gap-2 p-2 rounded-lg ${
          theme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
        }`}
        onClick={handleContainerClick}
      >
        {filterGroups.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {groupIndex > 0 && (
              <span className={`text-xs font-medium mx-1 ${
                theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
              }`}>
                AND
              </span>
            )}
            <div className={`flex items-center rounded-lg p-1 ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-gray-300'
            }`}>
              {group.map((filter, filterIndex) => (
                <React.Fragment key={filterIndex}>
                  {filterIndex > 0 && (
                    <span className="text-xs font-medium text-gray-500 mx-1">
                      OR
                    </span>
                  )}
                  <div 
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFilterIndices({ groupIndex, filterIndex });
                    }}
                    className={`
                      bg-gray-200 rounded-full px-2 py-0.5 text-sm font-medium text-gray-700 
                      flex items-center cursor-pointer transition-all
                      ${selectedFilterIndices?.groupIndex === groupIndex && 
                        selectedFilterIndices?.filterIndex === filterIndex 
                        ? 'ring-2 ring-blue-500 bg-blue-50' 
                        : 'hover:bg-gray-300'}
                    `}
                  >
                    <span className="truncate max-w-[150px]">
                      {String(filter.property)}:
                      {filter.value ? filter.value : ` ${filter.min} - ${filter.max}`}
                    </span>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFilter(groupIndex, filterIndex);
                      }} 
                      className="ml-1 text-gray-500 hover:text-gray-700"
                    >
                      <IconX size={14} />
                    </button>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ))}
        
        {filterGroups.every(group => group.length === 0) && (
          <div className={theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}>
            Add filters to begin
          </div>
        )}
      </div>

      {noResults && (
        <div className={`mt-2 ${
          theme === 'dark' ? 'text-gray-400' : 'text-slate-500'
        }`}>
          No search results found.
        </div>
      )}
      
      <div className="flex items-center mt-2">
        <span className="mr-2">Sort by:</span>
        <select
          value={sortConfig?.property || ''}
          onChange={(e) => handleSort(e.target.value)}
          className={`mr-2 ${baseSelectClasses}`}
        >
          <option value="">None</option>
          {searchableProperties.map((prop) => (
            <option key={String(prop)} value={String(prop)}>
              {String(prop)}
            </option>
          ))}
        </select>
        
        {sortConfig && (
          <>
            <button
              onClick={toggleSortDirection}
              className={`px-3 py-1 rounded mr-2 ${baseButtonClasses}`}
            >
              {sortConfig.direction === 'asc' ? '↑ Ascending' : '↓ Descending'}
            </button>
            <button
              onClick={() => setSortConfig(null)}
              className={theme === 'dark' ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'}
            >
              <IconX size={16} />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Searchbar;
