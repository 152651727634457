import { ControlPanel } from '../ControlPanel';

interface CameraParametersPanelProps {
  cameraFocalLengths: number[];
  cameraPosition: number[];
  cameraRotation: number[];
  onFocalLengthsChange: (focalLengths: number[]) => void;
  onPositionChange: (position: number[]) => void;
  onRotationChange: (rotation: number[]) => void;
}

export function CameraParametersPanel({
  cameraFocalLengths,
  cameraPosition,
  cameraRotation,
  onFocalLengthsChange,
  onPositionChange,
  onRotationChange,
}: CameraParametersPanelProps) {
  return (
    <ControlPanel title="Camera Parameters" defaultExpanded={false}>
      <div className="space-y-4">
        <div className="bg-gray-800/70 p-2 rounded">
          <h2 className="font-bold">Focal Lengths</h2>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {cameraFocalLengths.map((fl, i) => (
              <input
                key={i}
                type="number"
                value={fl}
                step="0.1"
                onChange={(e) => {
                  const newFocalLengths = [...cameraFocalLengths];
                  newFocalLengths[i] = Number(e.target.value);
                  onFocalLengthsChange(newFocalLengths);
                }}
                className="bg-white/20 rounded px-2 py-1 w-20"
              />
            ))}
          </div>
        </div>

        <div className="bg-gray-800/70 p-2 rounded">
          <h2 className="font-bold">Position</h2>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {cameraPosition.map((pos, i) => (
              <input
                key={i}
                type="number"
                value={pos}
                step="0.1"
                onChange={(e) => {
                  const newPosition = [...cameraPosition];
                  newPosition[i] = Number(e.target.value);
                  onPositionChange(newPosition);
                }}
                className="bg-white/20 rounded px-2 py-1 w-20"
              />
            ))}
          </div>
        </div>

        <div className="bg-gray-800/70 p-2 rounded">
          <h2 className="font-bold">Rotation</h2>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {cameraRotation.map((rot, i) => (
              <input
                key={i}
                type="number"
                value={rot}
                step="0.1"
                onChange={(e) => {
                  const newRotation = [...cameraRotation];
                  newRotation[i] = Number(e.target.value);
                  onRotationChange(newRotation);
                }}
                className="bg-white/20 rounded px-2 py-1 w-20"
              />
            ))}
          </div>
        </div>
      </div>
    </ControlPanel>
  );
} 