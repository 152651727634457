// src/testsuite/utils/PointND.ts
import { ObjectND } from './ObjectND';
import { VertexND } from './VertexND';
import { CameraND } from './CameraND';

export class PointND extends ObjectND {
  diameter: number;

  constructor(
    dimensions: number,
    position: number[],
    color: string = '#FFFFFF',
    diameter: number = 5,
    rotationAngles: number[] = Array(dimensions).fill(0)
  ) {
    super(dimensions, position, color);
    this.diameter = diameter;
    this.vertices = [new VertexND(dimensions, position)];
    this.rotate(rotationAngles);
    console.log('point', position);
  }

  project(camera: CameraND) {
    this.vertices[0].project(camera);
    this.averageDepth = this.vertices[0].depth;
  }

  rotate(rotationAngles: number[]) {
    this.vertices[0].rotate(rotationAngles);
  }

  draw(ctx: CanvasRenderingContext2D) {
    const vertex = this.vertices[0];
    ctx.beginPath();
    ctx.fillStyle = this.color;
    ctx.arc(vertex.projectedPos[0], vertex.projectedPos[1], this.diameter, 0, Math.PI * 2);
    ctx.fill();
  }
}