import React from 'react';

interface ControlPanelProps {
  children: React.ReactNode;
  title: string;
  defaultExpanded?: boolean;
}

export function ControlPanel({ children, title, defaultExpanded = true }: ControlPanelProps) {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded);

  return (
    <div className={`bg-black/50 backdrop-blur-sm rounded-lg opacity-90 p-4 text-white transition-all duration-300 text-gray-200 font-mono font-thin ${
      isExpanded ? '' : 'w-fit flex items-center justify-center'
    }`}>
      <button 
        onClick={() => setIsExpanded(!isExpanded)}
        className="absolute top-2 right-2 px-2 rounded bg-slate-500 hover:opacity-75"
      >
        {isExpanded ? '−' : '+'}
      </button>
      {!isExpanded && <div className="pr-8">{title}</div>}
      <div className={`transition-all duration-300 ${
        isExpanded 
          ? 'max-h-[1000px] opacity-100' 
          : 'hidden'
      }`}>
        {children}
      </div>
    </div>
  );
}  