import { useOasisBackend } from '../../hooks/useOasisBackend';
import { IconTrash, IconDownload } from '@tabler/icons-react';
import { Document } from '../../api/OasisBackendApi';
import TesseractLoader from '../ui/tesseract-loader';
import { useState } from 'react';
import {toast} from 'react-toastify';

interface DocumentListProps {
  knowledgeBaseId: string;
  onDelete: (documentId: string) => void;
  documents: Document[];
}

const DocumentList: React.FC<DocumentListProps> = ({ onDelete, documents, knowledgeBaseId }) => {
  const db = useOasisBackend();
  const [loading, setLoading] = useState(false);

  const handleDownloadDocument = async (documentId: string) => {
    setLoading(true);
    try {
      const response = await db.endpoints.api.apiDocumentsRetrieve(documentId);
      const url = response.data.raw_document_presigned_url;
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading document:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteDocument = async (documentId: string) => {
    try {
      await db.endpoints.api.apiDocumentsDestroy(documentId);
      onDelete(documentId);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const retryDocumentParsing = async (knowledgeBaseId: string) => {
    try {
      // const response = await db.endpoints.api.apiKnowledgeBaseRetryDocumentParsingCreate(knowledgeBaseId);
      // console.log(response);
      toast.success("Document parsing retried");
    } catch (error) {
      console.error('Error retrying document parsing:', error);
      toast.error("Error retrying document parsing");
    }
  }

  return (
    <div className="flex flex-col gap-2">
        <h3 className="text-white">Documents</h3>
        {loading && <TesseractLoader />}
        {
          documents.some((document) => document.parsing_status === "document_parsing_failed") && (
            <button onClick={() => retryDocumentParsing(knowledgeBaseId)}>Retry Document Parsing</button>
          )
        }
        {!loading && <ul className="divide-y divide-gray-700 bg-gray-800">
        {documents.map((document) => (
          <li
          key={document.id}
          className="flex items-center justify-between py-3 px-4 hover:bg-gray-700"
        >
          <div>
            <p className="text-sm font-medium text-gray-200">{document.name}</p>
            <p className="text-sm font-medium text-gray-200">{document.file_type}</p>
            <p className="text-sm font-medium text-gray-200">Indexing Status: {document.semantic_indexing_status}</p>
            <p className="text-sm font-medium text-gray-200">Parsing Status: {document.parsing_status}</p>
            <p className="text-sm text-gray-400">
              {new Date(document.created_at).toLocaleDateString()}
            </p>
          </div>
          <button 
            onClick={() => handleDownloadDocument(document.id)}
            className="p-2 text-gray-400 hover:text-blue-400 rounded-full hover:bg-gray-700"
            aria-label="Download document"
          >
            <IconDownload size={20} />
          </button>
          <button
            onClick={() => handleDeleteDocument(document.id)}
            className="p-2 text-gray-400 hover:text-red-400 rounded-full hover:bg-gray-700"
            aria-label="Delete document"
          >
            <IconTrash size={20} />
          </button>
        </li>
      ))}
    </ul>}
    </div>
  );
};

export default DocumentList;