import { useOasisBackend } from "../../hooks/useOasisBackend";
import Modal from "../Modal";
import { useState } from "react";

const LANGUAGES = {
    'Afrikaans': 'af',
    'Albanian': 'sq',
    'Amharic': 'am',
    'Arabic': 'ar',
    'Armenian': 'hy',
    'Azerbaijani': 'az',
    'Bengali': 'bn',
    'Bosnian': 'bs',
    'Bulgarian': 'bg',
    'Catalan': 'ca',
    'Chinese (Simplified)': 'zh',
    'Chinese (Traditional)': 'zh-TW',
    'Croatian': 'hr',
    'Czech': 'cs',
    'Danish': 'da',
    'Dari': 'fa-AF',
    'Dutch': 'nl',
    'English': 'en',
    'Estonian': 'et',
    'Farsi (Persian)': 'fa',
    'Filipino, Tagalog': 'tl',
    'Finnish': 'fi',
    'French': 'fr',
    'French (Canada)': 'fr-CA',
    'Georgian': 'ka',
    'German': 'de',
    'Greek': 'el',
    'Gujarati': 'gu',
    'Haitian Creole': 'ht',
    'Hausa': 'ha',
    'Hebrew': 'he',
    'Hindi': 'hi',
    'Hungarian': 'hu',
    'Icelandic': 'is',
    'Indonesian': 'id',
    'Irish': 'ga',
    'Italian': 'it',
    'Japanese': 'ja',
    'Kannada': 'kn',
    'Kazakh': 'kk',
    'Korean': 'ko',
    'Latvian': 'lv',
    'Lithuanian': 'lt',
    'Macedonian': 'mk',
    'Malay': 'ms',
    'Malayalam': 'ml',
    'Maltese': 'mt',
    'Marathi': 'mr',
    'Mongolian': 'mn',
    'Norwegian (Bokmål)': 'no',
    'Pashto': 'ps',
    'Polish': 'pl',
    'Portuguese (Brazil)': 'pt',
    'Portuguese (Portugal)': 'pt-PT',
    'Punjabi': 'pa',
    'Romanian': 'ro',
    'Russian': 'ru',
    'Serbian': 'sr',
    'Sinhala': 'si',
    'Slovak': 'sk',
    'Slovenian': 'sl',
    'Somali': 'so',
    'Spanish': 'es',
    'Spanish (Mexico)': 'es-MX',
    'Swahili': 'sw',
    'Swedish': 'sv',
    'Tamil': 'ta',
    'Telugu': 'te',
    'Thai': 'th',
    'Turkish': 'tr',
    'Ukrainian': 'uk',
    'Urdu': 'ur',
    'Uzbek': 'uz',
    'Vietnamese': 'vi',
    'Welsh': 'cy'
} as const;

const TranslateKnowledgeBaseButton = ({knowledgeBaseId}: {knowledgeBaseId: string}) => {
    const db = useOasisBackend();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    const handleTranslate = async () => {
        // Dummy function for translation
        console.log(`Translating knowledge base ${knowledgeBaseId} to ${selectedLanguage}`);
        const response = await db.endpoints.api.apiKnowledgeBaseTranslateCreate(knowledgeBaseId,{
            target_language_code: selectedLanguage
        });
        console.log("Response",response);
        setIsModalOpen(false);
    };

    return (
        <>
            <button 
                onClick={() => setIsModalOpen(true)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
                Translate
            </button>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="p-4 bg-gray-800 text-white rounded-md">
                    <h2 className="text-xl font-bold mb-4">Select Translation Language</h2>
                    <select
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        className="w-full p-2 border rounded mb-4 bg-gray-700 text-white"
                    >
                        <option value="">Select a language</option>
                        {Object.entries(LANGUAGES).map(([language, code]) => (
                            <option key={code} value={code}>
                                {language}
                            </option>
                        ))}
                    </select>
                    <div className="flex justify-end gap-2">
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleTranslate}
                            disabled={!selectedLanguage}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
                        >
                            Translate
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TranslateKnowledgeBaseButton;
