import { useState, useEffect, useMemo, useRef } from 'react';
import { DatumCard } from './Cards/DatumCard';
import { Dataset, SearchResult } from '../../api/OasisBackendApi';
import { useOasisBackend } from '../../hooks/useOasisBackend'
import AutocompleteDropdown from './AutocompleteDropdown';

interface SearchResultsProps {
  searchResults: {
    id: number;
    query: string;
    dataset: Dataset;
    created_at: string;
    results: {
      query: string;
      oasis?: {
        [key: string]: number[][];  // Allow any string keys with number[][] values
      };
      keyword?: number[][];
      semantic?: number[][];
    };
    account?: number | null;
    params?: {
      correct_datum?: number;
      oasis_search_params?: {
        oasis_queries: {
          [key: string]: number[][];
        };
        oasis_weights: {
          [key: string]: number;
        };
      };
    };
  };
  onSearchResultUpdate?: (updatedResult: SearchResult) => void;
}

export function SearchResults({ searchResults, onSearchResultUpdate }: SearchResultsProps) {
  console.log("Search results", searchResults)
  const db = useOasisBackend();
  
  // Add console log to see search results
  console.log('Current search results:', searchResults);
  
  const [datumCache, setDatumCache] = useState<Record<string, any>>({});
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [filterText, setFilterText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [displayCounts, setDisplayCounts] = useState<Record<string, number>>({});
  const ITEMS_PER_PAGE = 20;
  const [showSearchParams, setShowSearchParams] = useState(false);
  const [selectedParamIndex, setSelectedParamIndex] = useState(0);
  const [dimensionDescriptions, setDimensionDescriptions] = useState<Record<string, string>>({});


   // Flatten nested results into searchType -> datumIds format
  const flattenedResults = useMemo(() => {
    // console.log('Flattening results:', searchResults.results);
    const results: Record<string, number[][]> = {};
    
    if (searchResults.results.oasis) {
      // Handle any keys in the oasis object
      Object.entries(searchResults.results.oasis).forEach(([type, ids]) => {
        results[`oasis_${type}`] = Array.isArray(ids[0]) && Array.isArray(ids[0][0]) 
          ? (ids as unknown as number[][][])[0] 
          : ids;
      });
    }
    if (searchResults.results.keyword) {
      results.keyword = Array.isArray(searchResults.results.keyword[0]) && Array.isArray(searchResults.results.keyword[0][0]) 
        ? (searchResults.results.keyword as unknown as number[][][])[0] 
        : searchResults.results.keyword;
    }
    if (searchResults.results.semantic) {
      results.semantic = Array.isArray(searchResults.results.semantic[0]) && Array.isArray(searchResults.results.semantic[0][0]) 
        ? (searchResults.results.semantic as unknown as number[][][])[0] 
        : searchResults.results.semantic;
    }
    
    console.log('Flattened results:', results);
    return results; 
  }, [searchResults]);

  // Initialize selectedTypes with all available types by default
  const [selectedTypes, setSelectedTypes] = useState<Set<string>>(() => 
    new Set(Object.keys(flattenedResults))
  );

   // Fetch dimension descriptions from first datum's dataset
   useEffect(() => {
    const fetchDimensionDescriptions = async () => {
      // Get first datum id from any search type
      let firstDatumId: number | null = null;
      for (const datumGroups of Object.values(flattenedResults)) {
        if (datumGroups.length > 0 && datumGroups[0].length > 0) {
          firstDatumId = datumGroups[0][0];
          break;
        }
      }

      if (!firstDatumId) return;

      try {
        // Fetch the first datum
        const datumResponse = await db.endpoints.testsuite.testsuiteApiGetDatumRetrieve(firstDatumId.toString());
        if (!datumResponse.data?.dataset) return;

        // Fetch the dataset
        const datasetResponse = await db.endpoints.testsuite.testsuiteApiGetDatasetRetrieve(datumResponse.data.dataset.toString());
        if (!datasetResponse.data?.meta_data?.dimension_likert_mapping) return;

        setDimensionDescriptions(datasetResponse.data.meta_data.dimension_likert_mapping);
      } catch (error) {
        console.error('Error fetching dimension descriptions:', error);
      }
    };

    fetchDimensionDescriptions();
  }, [flattenedResults, db.endpoints.testsuite]);


  // Initialize display counts for each search type
  useEffect(() => {
    const initialCounts = Object.keys(flattenedResults).reduce((acc, searchType) => {
      acc[searchType] = ITEMS_PER_PAGE;
      return acc;
    }, {} as Record<string, number>);
    setDisplayCounts(initialCounts);
  }, [flattenedResults]);

  // Modify the fetchDatums function to only fetch visible items
  useEffect(() => {
    const fetchDatums = async () => {
      const newDatums: Record<string, any> = {};
      let hasNewDatums = false;

      for (const [searchType, datumGroups] of Object.entries(flattenedResults)) {
        let fetchedCount = 0;
        const maxItems = displayCounts[searchType] || ITEMS_PER_PAGE;

        const correctDatumId = searchResults.params?.correct_datum;
        if (correctDatumId && !datumCache[correctDatumId]) {
          try {
            const response = await db.endpoints.testsuite.testsuiteApiGetDatumRetrieve(correctDatumId.toString());
            newDatums[correctDatumId] = response.data;
            hasNewDatums = true;
          } catch (error) {
            console.error(`Failed to fetch correct datum ${correctDatumId}:`, error);
          }
        }

        for (const group of datumGroups) {
          for (const id of group) {
            if (fetchedCount >= maxItems) break;
            if (!datumCache[id] && !newDatums[id]) {
              try {
                const response = await db.endpoints.testsuite.testsuiteApiGetDatumRetrieve(id.toString());
                newDatums[id] = response.data;
                hasNewDatums = true;
              } catch (error) {
                console.error(`Failed to fetch datum ${id}:`, error);
              }
            }
            fetchedCount++;
          }
          if (fetchedCount >= maxItems) break;
        }
      }

      if (hasNewDatums) {
        setDatumCache(prev => ({ ...prev, ...newDatums }));
      }
    };

    fetchDatums();
  }, [db.endpoints.testsuite, displayCounts, flattenedResults, searchResults.params?.correct_datum]);

  const toggleSearchType = (searchType: string) => {
    const newSelected = new Set(selectedTypes);
    if (newSelected.has(searchType)) {
      newSelected.delete(searchType);
    } else {
      newSelected.add(searchType);
    }
    setSelectedTypes(newSelected);
  };

  // Extract available properties from first datum
  const availableProperties = useMemo(() => {
    const properties = new Set<string>();
    const firstDatum = Object.values(datumCache)[0];
    
    // console.log('Extracting properties from first datum:', firstDatum);
    
    if (firstDatum?.content) {
      const extractProperties = (obj: any, prefix = '') => {
        // Add the current prefix as a property if it exists
        if (prefix) {
          properties.add(prefix);
        }
        
        Object.entries(obj).forEach(([key, value]) => {
          const fullKey = prefix ? `${prefix}.${key}` : key;
          properties.add(fullKey);
          
          // Handle nested objects, but not arrays or null values
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            extractProperties(value, fullKey);
          }
        });
      };
      
      try {
        extractProperties(firstDatum.content);
        // console.log('Extracted properties:', Array.from(properties));
      } catch (error) {
        console.error('Error extracting properties:', error);
      }
    } else {
      console.log('No content found in first datum');
    }
    
    return Array.from(properties).sort();
  }, [datumCache]);

  // Add property selection handlers
  const handlePropertySelect = (property: string) => {
    setSelectedProperties(prev => {
      if (!prev.includes(property)) {
        return [...prev, property];
      }
      return prev;
    });
    setFilterText('');
  };

  const handleRemoveProperty = (property: string) => {
    setSelectedProperties(prev => prev.filter(p => p !== property));
  };

  // Add this function before the return statement
  const getCorrectDatumRank = (datumGroups: number[][]): number | null => {
    const correctDatum = searchResults.params?.correct_datum;
    if (!correctDatum) return null;

    for (let groupIndex = 0; groupIndex < datumGroups.length; groupIndex++) {
      if (datumGroups[groupIndex].includes(correctDatum)) {
        return groupIndex + 1;
      }
    }
    return null;
  };

  const getCorrectDatumStats = (datumGroups: number[][]): { 
    rank: number | null, 
    datumsBefore: number, 
    datumsInSameRank: number,
    datumsAfter: number 
  } => {
    const correctDatum = searchResults.params?.correct_datum;
    if (!correctDatum) return { rank: null, datumsBefore: 0, datumsInSameRank: 0, datumsAfter: 0 };

    let rank = 0;
    let datumsBefore = 0;
    let datumsInSameRank = 0;
    let datumsAfter = 0;
    let foundCorrect = false;

    for (let groupIndex = 0; groupIndex < datumGroups.length; groupIndex++) {
      if (datumGroups[groupIndex].includes(correctDatum)) {
        rank = groupIndex + 1;
        datumsInSameRank = datumGroups[groupIndex].length;
        foundCorrect = true;
      } else if (!foundCorrect) {
        datumsBefore += datumGroups[groupIndex].length;
      } else {
        datumsAfter += datumGroups[groupIndex].length;
      }
    }

    return { rank, datumsBefore, datumsInSameRank, datumsAfter };
  };

  // Add this function before the return statement
  const handleLoadMore = (searchType: string) => {
    setDisplayCounts(prev => ({
      ...prev,
      [searchType]: (prev[searchType] || ITEMS_PER_PAGE) + ITEMS_PER_PAGE
    }));
  };

  // Add new function to handle correct datum updates
  const handleCorrectDatumChange = async (datumId: number, isCorrect: boolean) => {
    if (!isCorrect) {
      if (!window.confirm('Are you sure you want to unset this as the correct datum?')) {
        return;
      }
    } else {
      if (!window.confirm('Are you sure you want to mark this as the correct datum?')) {
        return;
      }
    }

    try {
      const response = await db.endpoints.testsuite.testsuiteApiTestsuiteUpdateSearchFormattedUpdate(
        '.json',
        searchResults.id,
        {
          correct_datum: isCorrect ? datumId : null
        }
      );
      
      // Call the parent's update function with the new data
      if (response.data && onSearchResultUpdate) {
        onSearchResultUpdate(response.data);
      }
      
    } catch (error) {
      console.error('Failed to update correct datum:', error);
      alert('Failed to update correct datum. Please try again.');
    }
  };

  return (
    <div className="space-y-4">

      {/* Query */}
      <div className="mb-4 bg-gray-100 p-4 rounded-lg">
        <div className="flex justify-between items-center">
          <p className="italic">{searchResults.query}</p>
          <button
            onClick={() => setShowSearchParams(!showSearchParams)}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            {showSearchParams ? 'Hide Search Params' : 'Show Search Params'}
          </button>
        </div>
        
        {/* New container for search params */}
        {showSearchParams && searchResults.params?.oasis_search_params && (
          <div className="mt-2 pt-2 border-t border-gray-300">
            <div className="flex justify-between items-center mb-2">
              <h4 className="font-bold text-sm">Search Parameters:</h4>
              <div className="flex gap-2">
                {(Array.isArray(searchResults.params.oasis_search_params) 
                  ? searchResults.params.oasis_search_params 
                  : [searchResults.params.oasis_search_params]
                ).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedParamIndex(index)}
                    className={`px-3 py-1 text-sm rounded ${
                      selectedParamIndex === index 
                        ? 'bg-blue-600 text-white' 
                        : 'bg-gray-200 hover:bg-gray-300'
                    }`}
                  >
                    Ontology {index + 1}
                  </button>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 text-sm">
              {Object.entries(
                (() => {
                  console.log("Search Results Params", searchResults.params, selectedParamIndex)
                  const queries = (Array.isArray(searchResults.params.oasis_search_params.oasis_queries)
                    ? searchResults.params.oasis_search_params.oasis_queries[selectedParamIndex]
                    : searchResults.params.oasis_search_params.oasis_queries) || {};
                  
                  const weights = searchResults.params?.oasis_search_params?.oasis_weights;
                  
                  if (!weights) {
                    return queries;
                  }
                  
                  // Sort by weights in descending order (higher weights first)
                  return Object.fromEntries(
                    Object.entries(queries)
                      .sort((a, b) => {
                        const weightA = weights[a[0]] || 0;
                        const weightB = weights[b[0]] || 0;
                        return weightB - weightA;
                      })
                  );
                })()
              ).map(([key, value]) => {
                const correctDatum = searchResults.params?.correct_datum 
                  ? datumCache[searchResults.params.correct_datum]
                  : null;
                const correctValue = correctDatum?.meta_data?.embedding?.[key];
                const weight = searchResults.params?.oasis_search_params?.oasis_weights[key];
                
                // Handle both old and new parameter formats
                console.log("Value", value)
                const params = typeof value === 'number' 
                  ? { value: value }  // New format: single value
                  : value as { min: number; max: number; elas_low?: number; elas_high?: number }; // Old format
                
                // Check if value is within range for both formats
                const isWithinRange = typeof correctValue === 'number' && (
                  'value' in params 
                    ? correctValue === params.value
                    : correctValue >= params.min && correctValue <= params.max
                );

                let outOfRangeAmount = 0;
                let outOfRangeText = '';
                
                if (typeof correctValue === 'number' && !isWithinRange) {
                  if ('value' in params) {
                    outOfRangeAmount = Math.abs(correctValue - params.value);
                    outOfRangeText = ` (diff: ${outOfRangeAmount.toFixed(2)})`;
                  } else {
                    if (correctValue < params.min) {
                      outOfRangeAmount = (params.min - correctValue) / (params.max - params.min);
                      outOfRangeText = ` (${outOfRangeAmount.toFixed(2)}x below min)`;
                    } else {
                      outOfRangeAmount = (correctValue - params.max) / (params.max - params.min);
                      outOfRangeText = ` (${outOfRangeAmount.toFixed(2)}x above max)`;
                    }
                  }
                }

                const valueColor = correctValue === undefined
                  ? 'text-gray-400'
                  : isWithinRange
                    ? 'text-green-600'
                    : outOfRangeAmount <= 0.1
                      ? 'text-yellow-600'
                      : outOfRangeAmount <= 0.3
                        ? 'text-orange-600'
                        : 'text-red-600';

                return (
                  <div key={key} className="grid grid-cols-2 gap-4 p-2 hover:bg-gray-200 rounded">
                    <div className="flex flex-col gap-1">
                      <div className="font-bold">{key}:</div>
                      {dimensionDescriptions?.[key] && (
                        <div className="text-gray-600">{dimensionDescriptions[key]}</div>
                      )}
                      {weight && (
                        <div className="text-gray-600">Weight: {weight}</div>
                      )}
                      <div className="font-mono">
                        {'value' in params
                          ? `value: ${params.value}`
                          : `min: ${params.min}, max: ${params.max}
                            ${params.elas_low !== undefined ? `, elas_low: ${params.elas_low}` : ''}
                            ${params.elas_high !== undefined ? `, elas_high: ${params.elas_high}` : ''}`
                        }
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="font-bold">Correct Value:</div>
                      <div className={`font-mono ${valueColor}`}>
                        {correctValue !== undefined 
                          ? `${JSON.stringify(correctValue)}${outOfRangeText}`
                          : <span className="italic">Not found</span>
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* Property selection */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Display Properties
        </label>
        <div className="flex items-center w-full">
          <input
            ref={inputRef}
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
              setShowDropdown(true);
            }}
            onFocus={() => {
              setShowDropdown(true);
              if (inputRef.current) {
                const rect = inputRef.current.getBoundingClientRect();
                setDropdownPosition({ top: rect.bottom, left: rect.left });
              }
            }}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
            placeholder="Search properties..."
          />
        </div>
        <div className="flex flex-wrap mt-2">
          {selectedProperties.map((property, index) => (
            <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mb-2">
              <button
                className="mr-2 text-blue-600 hover:text-blue-800"
                onClick={() => handleRemoveProperty(property)}
              >
                ×
              </button>
              {property}
            </span>
          ))}
        </div>
      </div>

      {/* Search type toggles */}
      <div className="flex gap-4 flex-wrap">
        {Object.keys(flattenedResults).map((searchType) => (
          <label key={searchType} className="flex items-center gap-2">
            <input 
              type="checkbox"
              checked={selectedTypes.has(searchType)}
              onChange={() => toggleSearchType(searchType)}
            />
            <span>{searchType}</span>
          </label>
        ))}
      </div>

      {/* Results containers */}
      <div className="flex gap-4 overflow-x-auto">
        {Object.entries(flattenedResults).map(([searchType, datumGroups]) => {
          if (!selectedTypes.has(searchType)) return null;

          const { rank, datumsBefore, datumsInSameRank, datumsAfter } = getCorrectDatumStats(datumGroups);
          let displayedItems = 0;
          const maxItems = displayCounts[searchType] || ITEMS_PER_PAGE;
          const totalItems = datumGroups.reduce((sum, group) => sum + group.length, 0);

          return (
            <div 
              key={searchType}
              className="flex-1 min-w-[400px] m-2 bg-gray-100 rounded-lg p-4"
            >
              <div className="flex flex-col gap-2 mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold">{searchType}</h3>
                  {rank && (
                    <span className="text-sm bg-green-100 text-green-800 px-2 py-1 rounded">
                      Rank {rank}
                    </span>
                  )}
                </div>
                {rank && (
                  <div className="text-sm text-gray-600 space-y-1">
                    <div>Above: {datumsBefore} results</div>
                    <div>Same rank: {datumsInSameRank} results</div>
                    <div>Below: {datumsAfter} results</div>
                  </div>
                )}
              </div>
              <div className="space-y-2 max-h-[80vh] overflow-y-auto">
                {datumGroups.map((group, groupIndex) => {
                  if (displayedItems >= maxItems) return null;
                  
                  const itemsToShow = group.slice(0, maxItems - displayedItems);
                  displayedItems += itemsToShow.length;

                  return (
                    <div key={groupIndex} className="space-y-2">
                      {itemsToShow.map((id) => (
                        datumCache[id] ? (
                          <DatumCard 
                            key={id}
                            datum={datumCache[id]}
                            properties={selectedProperties}
                            rank={groupIndex}
                            isCorrect={searchResults.params?.correct_datum === id}
                            onCorrectChange={(isCorrect) => handleCorrectDatumChange(id, isCorrect)}
                          />
                        ) : (
                          <div key={id} className="animate-pulse h-20 bg-gray-200 rounded" />
                        )
                      ))}
                    </div>
                  );
                })}
              </div>
              {displayedItems < totalItems && (
                <button
                  onClick={() => handleLoadMore(searchType)}
                  className="mt-4 w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Load More
                </button>
              )}
            </div>
          );
        })}
      </div>

      {showDropdown && (
        <AutocompleteDropdown
          options={availableProperties}
          onSelect={handlePropertySelect}
          position={dropdownPosition}
          filterText={filterText}
        />
      )}
    </div>
  );
}