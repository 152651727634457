import React, { useState, useCallback, useMemo } from 'react';
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { LLMJob } from '../../api/OasisBackendApi';
import BackButton from '../../components/ui/back_button';
import Searchbar from '../components/Searchbar';
import JobCard from '../components/Cards/JobCard';

const MAX_SAMPLE_SIZE = 10; // Number of random jobs to sample for keys
const MAX_DISPLAY_LENGTH = 100; // Maximum length for displayed content

const LLMJobs: React.FC = () => {
  const db = useOasisBackend();
  const [jobs, setJobs] = useState<LLMJob[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<LLMJob[]>([]);
  const [operationId, setOperationId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [passthroughKeys, setPassthroughKeys] = useState<string[]>([]);

  const handleFilterChange = useCallback((filteredItems: LLMJob[]) => {
    setFilteredJobs(filteredItems);
  }, []);

  // Function to discover passthrough_data keys from a sample of jobs
  const discoverPassthroughKeys = useCallback((jobs: LLMJob[]) => {
    // Take a random sample of jobs if there are many
    const sampleJobs = jobs.length > MAX_SAMPLE_SIZE 
      ? Array.from({ length: MAX_SAMPLE_SIZE }, () => jobs[Math.floor(Math.random() * jobs.length)])
      : jobs;

    // Collect all unique keys from passthrough_data
    const keys = new Set<string>();
    sampleJobs.forEach(job => {
      if (job.pass_through_data && typeof job.pass_through_data === 'object') {
        Object.keys(job.pass_through_data).forEach(key => keys.add(key));
      }
    });

    return Array.from(keys);
  }, []);

  // Function to prepare jobs for search with truncated content
  const prepareJobsForSearch = useCallback((jobs: LLMJob[]) => {
    return jobs.map(job => ({
      ...job,
      passthrough_data: Object.fromEntries(
        Object.entries(job.pass_through_data || {}).map(([key, value]) => [
          key,
          typeof value === 'string' 
            ? value 
            : JSON.stringify(value)
        ])
      )
    }));
  }, []);

  const searchableProps = useMemo(() => [
    'id', 
    'status', 
    'created_at', 
    'model',
    ...passthroughKeys.map(key => `passthrough_data.${key}`)
  ], [passthroughKeys]);

  const handleSearch = async () => {
    if (!operationId.trim()) return;
    
    setIsLoading(true);
    try {
      const response = await db.endpoints.testsuite.testsuiteApiGetOperationLlmJobsRetrieve(operationId);
      const discoveredKeys = discoverPassthroughKeys(response.data);
      setPassthroughKeys(discoveredKeys);
      
      const preparedJobs = prepareJobsForSearch(response.data);
      setJobs(preparedJobs);
      setFilteredJobs(preparedJobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full rounded bg-gray-100">
      <BackButton />
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">LLM Jobs</h1>
        
        {/* Operation ID Input */}
        <div className="mb-6 max-w-md mx-auto">
          <div className="flex gap-2">
            <input
              type="text"
              value={operationId}
              onChange={(e) => setOperationId(e.target.value)}
              placeholder="Enter Operation ID"
              className="flex-1 p-2 border rounded"
            />
            <button
              onClick={handleSearch}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Search'}
            </button>
          </div>
        </div>

        {jobs.length > 0 && (
          <>
            <Searchbar<LLMJob>
              items={jobs}
              searchableProperties={searchableProps}
              onFilterChange={handleFilterChange}
            />
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-6">
              {filteredJobs.map((job) => (
                <JobCard key={job.id} job={job} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LLMJobs;
