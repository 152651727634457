import { useState } from "react";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import styled from "@emotion/styled";
import { IconSend, IconX } from "@tabler/icons-react";
import ApplicationNavbar from "../../components/ApplicationNavbar";
import TesseractLoader from "../ui/tesseract-loader";
import Markdown from "react-markdown";
import Modal from "../Modal";
import { BACKEND_URL } from "../../hooks/useOasisBackend";

const ChatFontContainer = styled.div`
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    color: #fff;
`

const SearchModal = ({text_results}: {text_results: {text: string, document_id: string, chunk_id: string}[]}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return <>
        <Modal isOpen={isModalOpen} onClose={() => {
            setIsModalOpen(false)
        }}>
            <div className="flex flex-col gap-1 bg-gray-800 text-white rounded-md p-4 overflow-y-auto max-h-[60vh]">
                <div className="flex flex-row justify-end items-center w-full">
                    <IconX className="text-white" onClick={() => {
                        setIsModalOpen(false)
                    }} />
                </div>
                <div className="flex flex-col gap-4">
                    {text_results.map((result: any) => {
                            return <div>
                            <p>Text: {result.text}</p>
                            <p>Document ID: {result.document_id}</p>
                            <p>Chunk ID: {result.chunk_id}</p>
                        </div>
                    })}
                </div>
        </div>
    </Modal>
        <button className="text-white bg-gray-400 rounded-md p-2" onClick={() => {
            setIsModalOpen(true)
        }}>
            View Full Results
        </button>
    </>
}

const SearchToolResultRenderer = ({results}: {results: any}) => {
    // Parse the results
    let parsedResults = JSON.parse(results)
    if (!!!parsedResults){
        return <div>
            <p>No results found</p>
        </div>
    }
    return <div>
        <div className={`flex flex-col gap-1 justify-start my-4`}>
            <p className="font-bold text-lg">Search Tool Results</p> 
            <div className="flex flex-col gap-1">
                {parsedResults.map((result: any) => {
                    return <div>
                    <p>Text: {result["text"].substring(0, 50)}...</p>
                    <p>Document ID: {result["document_id"]}</p>
                    <p>Chunk ID: {result["chunk_id"]}</p>
                </div>
            })}
            </div>
        </div>
        <SearchModal text_results={parsedResults} />
    </div>
}

const TaskCompletedResultRenderer = ({results}: {results: any}) => {
    return <div>
        <div className={`flex flex-row justify-start`}>
            <p className="font-bold text-lg">Task Marked Completed</p>
        </div>
    </div>
}

const ToolCallRenderer = ({message}: {message: any}) => {
    return <div className={`flex flex-col text-white text-left justify-start`}>
        {message.content && 
        <div className={`flex flex-row justify-start items-center w-full`}>
                <div className={`flex flex-col py-4 rounded-md text-white`}>
                    <Markdown>{message.content}</Markdown>
                </div>
            </div>}
        {message.tool_calls.map((tool: any) => {
            return <div className={`flex flex-col text-white text-left justify-start rounded-md p-4 my-4 bg-gray-800`}>
                <p className="font-bold">
                    Function Called: {tool.function.name}
                </p>
                <p>
                    Function Arguments: {tool.function.arguments}
                </p>
            </div>
        })}
    </div>
}

const Chat = ({knowledgebase_id}: {knowledgebase_id: string}) => {
    const db = useOasisBackend()
    const [content, setContent] = useState<string>("");
    const [messages, setMessages] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [animatedText, setAnimatedText] = useState<string>("");
    const [isComplete, setIsComplete] = useState<boolean>(true);

    const submitContent = async () => {
        if (!content.trim()) return;
        setIsComplete(false);
        
        // Add user message immediately
        const userMessage = {
            role: "user",
            content: content
        };
        const newMessages = [...messages, userMessage];
        setMessages(newMessages);
        setContent("");
        setAnimatedText("");
        
        try {
            // Get CSRF token
            const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
            
            // Use the streaming endpoint
            const response = await fetch(`${BACKEND_URL}/api/knowledge-base/${knowledgebase_id}/chat-stream/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken || '',
                },
                body: JSON.stringify({
                    messages: newMessages,
                    temperature: 0.7,
                    model_name: "openai/gpt-4o" // You can make this configurable if needed
                }),
                credentials: 'include',
            });
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const reader = response.body?.getReader();
            const decoder = new TextDecoder();
            
            let done = false;
            let toolCalls: {id: string, type: string, function: {name: string, arguments: string}}[] = [];
            let currentAnimatedText = "";
            
            while (!done) {
                const { value, done: doneReading } = await reader?.read() ?? {};
                done = doneReading ?? true;
                const chunkValue = decoder.decode(value, { stream: true });
                console.log("Chunk Value", chunkValue)
                const parsedChunk = parseConcatenatedJsonObjects(chunkValue)

                parsedChunk.forEach((chunk: any) => {
                    console.log("Chunk", chunk)
                   if (chunk.role === "tool"){
                        console.log("Tool", chunk)
                        setMessages((prevMessages) => [...prevMessages, chunk])
                    } else if (chunk.tool_calls){
                        console.log("Tool Calls", chunk)
                        setMessages((prevMessages) => [...prevMessages, chunk])
                    } else if (chunk?.content){
                        console.log("Content", chunk)
                        currentAnimatedText += chunk.content
                        setAnimatedText(prev => prev + chunk.content)
                    } else if (chunk?.completed) {
                        console.log("Completed", chunk)
                        const finalText = currentAnimatedText
                        if (finalText.trim().length > 0){
                            setMessages((prevMessages) => [...prevMessages, {role: "assistant", content: finalText}])
                            setAnimatedText("")
                            currentAnimatedText = ""
                        }
                        setIsComplete(true)
                    }
                })

           }
            console.log("Tool Calls", toolCalls)
        } catch (error) {
            console.error("Error submitting content", error)
        }
    };
    console.log("Messages", messages)
    function parseConcatenatedJsonObjects(input: string): unknown[] {
        const results: unknown[] = [];
      
        let braceCount = 0;
        let startIndex = -1;
      
        let inString = false;    // Are we currently inside of a double-quoted string?
        let escaping = false;    // Did we just see a backslash inside a string?
      
        for (let i = 0; i < input.length; i++) {
          const char = input[i];
      
          // Handle backslash escaping inside strings
          if (inString && char === '\\') {
            // Flip escaping on/off if we're in a string.
            escaping = !escaping;
            continue;
          }
      
          // If we encounter a quote and we're not in the middle of an escape,
          // toggle whether we are 'inString' or not.
          if (char === '"' && !escaping) {
            inString = !inString;
          } else if (inString) {
            // If we're in a string and it's not a quote or backslash, just move on
            // but reset escaping to false.
            escaping = false;
            continue;
          } else {
            // We are NOT in a string, so brace characters should count towards object depth.
            if (char === '{') {
              braceCount++;
              // If we just started a new top-level object, record its start index.
              if (braceCount === 1) {
                startIndex = i;
              }
            } else if (char === '}') {
              braceCount--;
              // If we've ended a top-level object, slice it out and parse.
              if (braceCount === 0 && startIndex !== -1) {
                const objectStr = input.slice(startIndex, i + 1).trim();
                try {
                  const parsed = JSON.parse(objectStr);
                  results.push(parsed);
                } catch (err) {
                  console.error('Failed to parse chunk:', objectStr, err);
                }
                startIndex = -1; // Reset after finishing an object
              }
            }
          }
      
          // If we're not dealing with a quote, reset `escaping` so it doesn’t carry forward
          if (char !== '\\') {
            escaping = false;
          }
        }
      
        return results;
      }
    

    const renderMessage = (index: number, message: any) => {
        if (message.role === "tool") {
            if (message?.name === "search") {
                return <SearchToolResultRenderer results={message.content} />
            } else if (message?.name === "task_complete") {
                return <TaskCompletedResultRenderer results={message.content} />
            } else {
                return <div>Unknown tool: {message.content}</div>
            }
        } else if (message.role === "assistant" && message.tool_calls) {
            return <ToolCallRenderer message={message} />
        } else if (message.role === "user") {
            return <div key={index} className={`flex flex-row justify-end items-center w-full`}>
                <div className={`flex flex-col py-4 rounded-md text-white`}>
                    {message.content}
                </div>
            </div>
        } else if (message.role === "assistant") {
            return <div key={index} className={`flex flex-row justify-start items-center w-full`}>
                <div className={`flex flex-col py-4 rounded-md text-white`}>
                    <Markdown>{message.content}</Markdown>
                </div>
            </div>
        } else if (message.role === "system") {
            return <div key={index} className={`flex flex-row justify-start items-center w-full`}>
                <div className={`flex flex-col py-4 rounded-md text-white`}>
                    <p className="text-sm font-bold">
                        System Prompt
                    </p>
                    {message.content}
                </div>
            </div>
        }
    }

    return(
        <div className="overflow-y-auto">
            <ApplicationNavbar className="text-white" openMenuClassName="bg-[#000b1e]" />
                    
                    <ChatFontContainer className="w-full max-h-[60vh] p-4 md:p-16 overflow-y-scroll bg-transparent">
                        {
                            messages.map((message, index) => {
                                return renderMessage(index, message)
                            })
                        }
                        {!isComplete && !isLoading && (
                            <div className="flex flex-row justify-start items-center w-full">
                                <div className={`flex flex-col py-4 rounded-md text-white`}>
                                    <Markdown>{animatedText}</Markdown>
                                </div>
                            </div>
                        )}
                        {isLoading && <div className="flex flex-row justify-center items-center w-full">
                            <TesseractLoader />
                        </div>}
                    </ChatFontContainer>

                    <div className="flex flex-row absolute bottom-10 w-full h-[10vh] md:pb-4 justify-center items-center self-end">
                        <input 
                            className="w-11/12 h-[48p] bg-transparent text-white text-lg outline-none" 
                            type="text" 
                            value={content} 
                            onChange={(e) => setContent(e.target.value)} placeholder="Type a message" 
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  submitContent();
                                }
                            }}
                            disabled={isLoading || !isComplete}
                        />
                        <button 
                            className="text-white inline" 
                            onClick={submitContent}
                            disabled={isLoading || !isComplete}
                        >
                            <IconSend />
                        </button>
                    </div>

    </div>
    
    )
}


export default Chat;