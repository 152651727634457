import { ControlPanel } from '../ControlPanel';

interface PerformancePanelProps {
  fps: number;
  multiplications: number;
}

export function PerformancePanel({ fps, multiplications }: PerformancePanelProps) {
  return (
    <ControlPanel title="Performance" defaultExpanded={false}>
      <div className="space-y-4">
        <div className="bg-gray-800/70 p-2 rounded">
          <h2 className="font-bold">Performance</h2>
          <div className="bg-black p-2 rounded mt-2">FPS: {fps}</div>
          <div className="bg-black p-2 rounded mt-2">Multiplications: {multiplications}</div>
        </div>
      </div>
    </ControlPanel>
  );
}