import { useState, useEffect } from 'react';
import { Dataset, SearchResult, SearchCollection } from '../../api/OasisBackendApi';
import Modal from './modal';
import DatasetLibrary from './Libraries/DatasetLibrary';
import { useOasisBackend } from '../../hooks/useOasisBackend';
const BenchmarkDataset = ({ answerDataset }: { answerDataset: Dataset }) => {
    const [benchmarkResults, setBenchmarkResults] = useState<any>(
        answerDataset?.meta_data?.benchmarks?.current_benchmarks
    );
    console.log(benchmarkResults, answerDataset.meta_data?.benchmarks);
    const [isDatasetModalOpen, setIsDatasetModalOpen] = useState(false);
    const [queryDataset, setQueryDataset] = useState<Dataset | null>(null);
    const db = useOasisBackend();

    useEffect(() => {
        console.log(benchmarkResults, answerDataset.meta_data?.benchmarks);
        setBenchmarkResults(answerDataset.meta_data?.benchmarks?.current_benchmarks);
    }, [answerDataset.meta_data?.benchmarks]);

    const runBenchmark = async () => {
        if (queryDataset) {
            const res = await db.endpoints.testsuite.testsuiteApiTestsuiteRunBenchmarkDatasetCreate({query_dataset_id: queryDataset.id, answer_dataset_id: answerDataset.id});
            console.log("BechmarkResults", res);
            setBenchmarkResults(res.data);
        }
    }

    const handleDatasetSelect = (dataset: Dataset) => {
        setQueryDataset(dataset);
        setIsDatasetModalOpen(false);
    }   
    return (
        <div>
            <h1>Benchmark Dataset</h1>
            <Modal
                isOpen={isDatasetModalOpen}
                onClose={() => setIsDatasetModalOpen(false)}
                size="large"
            >
                <DatasetLibrary
                    onSelect={handleDatasetSelect}
                    onCancel={() => setIsDatasetModalOpen(false)}
                />
            </Modal>    
            <div>
                <button 
                    onClick={() => {
                        setIsDatasetModalOpen(true);
                    }}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                    Select Query Dataset
                </button>
            </div>
            {queryDataset && (
                <div>
                    <h2>Query Dataset: {queryDataset.name}</h2>
                    <button 
                        onClick={runBenchmark}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                    >
                        Run Benchmark
                    </button>
                </div>
            )}
            {
                answerDataset.meta_data.benchmarks && (
                    <div>
                        <h2>Current Benchmarks</h2>
                        <p>Oasis Ranked Below Score: {answerDataset.meta_data.benchmarks?.oasis_ranked_below_score}</p>
                        <p>Semantic Ranked Below Score: {answerDataset.meta_data.benchmarks?.semantic_ranked_below_score}</p>
                    </div>
                )
            }
            {
                benchmarkResults && (
                    <div>
                        <h2>Benchmark Results</h2>
                        <p>Oasis Ranked Below Score: {benchmarkResults.oasis_ranked_below_score}</p>
                        <p>Semantic Ranked Below Score: {benchmarkResults.semantic_ranked_below_score}</p>
                    </div>
                )
            }
        </div>
    )
}

export default BenchmarkDataset;