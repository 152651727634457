import { useMemo } from 'react';
import {
  SpaceData,
  DataPoint,
  DimensionMapping,
  ColorMapping,
  VisualizationMode
} from './types/NDgraphics';

interface ObjectData {
  position: number[];
  color: string;
  direction?: number[];
  length?: number;
}

export interface SpaceDataVisualizerProps {
  spaceData: SpaceData;
  nValues: number[];
  dimensions: number;
  colorMapping?: ColorMapping;
  opacity: number;
  dimensionMappings?: DimensionMapping[];
  visualizationMode: VisualizationMode;
}

interface DensityInfo {
  total: number;
  byGroup?: Map<string, number>;
}

export function CalculateObjectData({
  spaceData,
  nValues,
  dimensions,
  colorMapping,
  opacity,
  dimensionMappings,
  visualizationMode,
}: SpaceDataVisualizerProps) {
  // Move getColor inside so it has access to the props
  const getColor = (point: DataPoint, normalizedPosition: number[]): string => {
    if (!colorMapping) {
      return `rgba(255, 255, 255, ${opacity})`;
    }

    if (colorMapping.type === 'gradient') {
      let value: number;
      
      if (colorMapping.source.type === 'dimension') {
        const dimIndex = colorMapping.source.dimensionIndex!;
        // Use raw value instead of normalized position
        const rawValue = point.position[dimIndex];
        const dim = spaceData.dimensions[dimIndex];
        value = (rawValue - dim.min) / (dim.max - dim.min);
      } else {
        const key = colorMapping.source.key!;
        // Find min and max values for this content key
        const values = spaceData.points
          .map(p => Number(p.content[key]))
          .filter(v => !isNaN(v));
        const min = Math.min(...values);
        const max = Math.max(...values);
        
        // Normalize the value to [0,1] range
        const rawValue = Number(point.content[key]) || 0;
        value = (max === min) ? 0 : (rawValue - min) / (max - min);
      }

      const stops = colorMapping.colorStops;
      // Find the appropriate color stops and interpolate
      for (let i = 0; i < stops.length - 1; i++) {
        if (value >= stops[i].position && value <= stops[i + 1].position) {
          const t = (value - stops[i].position) / (stops[i + 1].position - stops[i].position);
          return interpolateColors(stops[i].color, stops[i + 1].color, t, opacity);
        }
      }
      return adjustOpacity(stops[stops.length - 1].color, opacity);
    } else {
      const key = colorMapping.source.key;
      const value = String(point.content[key]);
      const color = colorMapping.colorMap[value];
      if (color) {
        return adjustOpacity(color, opacity);
      } else {
        // Use default color with its own opacity, just multiply by global opacity
        const { r, g, b, a } = parseColor(colorMapping.defaultColor);
        return `rgba(${r}, ${g}, ${b}, ${a * opacity})`;
      }
    }
  };

  // Create default mappings if none provided
  const mappings = dimensionMappings || Array.from({ length: dimensions }, (_, i) => ({
    sourceIndex: i < spaceData.dimensions.length ? i : -1,
    targetIndex: i
  }));

  if (visualizationMode === 'raw') {
    // Direct mapping of points
    return spaceData.points.map(point => {
      const mappedPosition = mappings.map((mapping, i) => {
        if (mapping.sourceIndex === -1 || mapping.sourceIndex >= point.position.length) return 0;

        const value = point.position[mapping.sourceIndex];
        const dim = spaceData.dimensions[mapping.sourceIndex];
        return ((value - dim.min) / (dim.max - dim.min)) * (nValues[i] - 1);
      });

      // Map direction if it exists
      const mappedDirection = point.direction ? mappings.map((mapping, i) => {
        if (mapping.sourceIndex === -1 || mapping.sourceIndex >= point.direction!.length) return 0;
        return point.direction![mapping.sourceIndex];
      }) : undefined;

      let color;
      if (colorMapping) {
        const normalizedPos = mappedPosition.map((pos, i) => (pos / (nValues[i] - 1)) * 2 - 1);
        color = getColor(point, normalizedPos);
      } else {
        color = `rgba(255, 255, 255, ${opacity})`;
      }
      
      return {
        position: mappedPosition,
        color,
        direction: mappedDirection,
        length: point.length
      };
    });
  }

  // Modify the density calculation to track groups when needed
  const densityMap = new Map<string, DensityInfo>();

  spaceData.points.forEach(point => {
    const mappedPosition = mappings.map(mapping => {
      if (mapping.sourceIndex === -1 || mapping.sourceIndex >= point.position.length) return 0;
      
      const value = point.position[mapping.sourceIndex];
      const dim = spaceData.dimensions[mapping.sourceIndex];
      return ((value - dim.min) / (dim.max - dim.min)) * 2 - 1;
    });
    
    const objectIndices = mappedPosition.map((norm, i) => {
      const index = Math.floor((norm + 1) / 2 * (nValues[i] - 1));
      return Math.max(0, Math.min(index, nValues[i] - 1));
    });
    
    const key = objectIndices.join(',');
    const currentInfo = densityMap.get(key) || { total: 0 };
    currentInfo.total += 1;

    // Track group counts if using group color mapping
    if (colorMapping?.type === 'group') {
      const groupKey = String(point.content[colorMapping.source.key]);
      if (!currentInfo.byGroup) currentInfo.byGroup = new Map();
      currentInfo.byGroup.set(groupKey, (currentInfo.byGroup.get(groupKey) || 0) + 1);
    }

    densityMap.set(key, currentInfo);
  });

  // Find max densities
  const maxTotalDensity = Math.max(...Array.from(densityMap.values()).map(info => info.total));
  let maxGroupDensities = new Map<string, number>();

  if (colorMapping?.type === 'group') {
    // Calculate max density per group
    densityMap.forEach((info) => {
      info.byGroup?.forEach((count, groupKey) => {
        maxGroupDensities.set(groupKey, Math.max(maxGroupDensities.get(groupKey) || 0, count));
      });
    });
  }

  // Generate object data
  const objects: ObjectData[] = [];

  const generateObjects = (currentDim: number, currentOffset: number[]) => {
    if (currentDim >= dimensions) {
      const key = currentOffset.join(',');
      const densityInfo = densityMap.get(key);
      
      if (!densityInfo || densityInfo.total === 0) return;
      
      const normalizedDensity = densityInfo.total / maxTotalDensity;
      const contrastedDensity = Math.pow(normalizedDensity, 0.5);
      
      // Skip if the resulting opacity would be 0
      if (contrastedDensity * opacity <= 0) return;

      if (!colorMapping) {
        // Default white with density-based opacity
        objects.push({
          position: currentOffset,
          color: `rgba(255, 255, 255, ${contrastedDensity * opacity})`
        });
      } else if (colorMapping.type === 'gradient') {
        // Use gradient color but modulate opacity by density
        const baseColor = getColor(spaceData.points[0], currentOffset);
        const { r, g, b } = parseColor(baseColor);
        // Skip if the color would be black (0,0,0) or fully transparent
        if ((r < 1 && g < 1 && b < 1) || contrastedDensity * opacity <= 0) return;
        objects.push({
          position: currentOffset,
          color: `rgba(${r}, ${g}, ${b}, ${contrastedDensity * opacity})`
        });
      } else if (colorMapping.type === 'group' && densityInfo.byGroup) {
        // Blend colors from all groups present in this object
        let totalR = 0, totalG = 0, totalB = 0, totalWeight = 0;
        
        densityInfo.byGroup.forEach((groupCount, groupKey) => {
          if (colorMapping.colorMap[groupKey]) {
            const groupDensity = groupCount / (maxGroupDensities.get(groupKey) || 1);
            const contrastedGroupDensity = Math.pow(groupDensity, 0.5);
            const weight = contrastedGroupDensity;
            
            const { r, g, b } = parseColor(colorMapping.colorMap[groupKey]);
            totalR += r * weight;
            totalG += g * weight;
            totalB += b * weight;
            totalWeight += weight;
          }
        });

        if (totalWeight > 0) {
          // Normalize the blended color and apply the overall density-based opacity
          objects.push({
            position: currentOffset,
            color: `rgba(
              ${Math.round(totalR / totalWeight)},
              ${Math.round(totalG / totalWeight)},
              ${Math.round(totalB / totalWeight)},
              ${contrastedDensity * opacity}
            )`
          });
        }
      }
      return;
    }

    for (let i = 0; i < nValues[currentDim]; i++) {
      generateObjects(currentDim + 1, [...currentOffset, i]);
    }
  };

  generateObjects(0, []);
  return objects;
}

// Helper functions for color manipulation
function interpolateColors(color1: string, color2: string, t: number, opacity: number): string {
  console.log('color1', color1);
  console.log('color2', color2);
  const c1 = parseColor(color1);
  const c2 = parseColor(color2);
  
  // Interpolate alpha values and multiply by the global opacity
  const alpha1 = parseFloat(color1.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1');
  const alpha2 = parseFloat(color2.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1');
  const interpolatedAlpha = (alpha1 + (alpha2 - alpha1) * t) * opacity;

  console.log('interpolatedAlpha', interpolatedAlpha);
  
  return `rgba(
    ${Math.round(c1.r + (c2.r - c1.r) * t)},
    ${Math.round(c1.g + (c2.g - c1.g) * t)},
    ${Math.round(c1.b + (c2.b - c1.b) * t)},
    ${interpolatedAlpha}
  )`;
}

function parseColor(color: string) {
  // Extract RGBA values using regex
  const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/);
  if (rgba) {
    return {
      r: parseInt(rgba[1]),
      g: parseInt(rgba[2]),
      b: parseInt(rgba[3]),
      a: parseFloat(rgba[4] ?? '1')
    };
  }
  
  // Fallback to canvas method for other color formats
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  ctx.fillStyle = color;
  return {
    r: parseInt(ctx.fillStyle.slice(1, 3), 16),
    g: parseInt(ctx.fillStyle.slice(3, 5), 16),
    b: parseInt(ctx.fillStyle.slice(5, 7), 16),
    a: 1
  };
}

function adjustOpacity(color: string, opacity: number): string {
  const { r, g, b } = parseColor(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function useSpaceDataVisualizer(props: SpaceDataVisualizerProps) {
  return useMemo(() => CalculateObjectData(props), [props]);
} 