import { useState } from "react";
import { useEffect } from "react";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { useNavigate, useParams } from "react-router-dom";
import { ReadKnowledgeBase } from "../../api/OasisBackendApi";
import EditKnowledgeBase from "../../components/knowledgeBaseComponenets/EditKnowledgeBase";
import Chat from "../../components/knowledgeBaseComponenets/Chat";
import ApplicationNavbar from "../../components/ApplicationNavbar";
import { cn } from "../../utils/cn";
import SearchPlayground from "../../components/knowledgeBaseComponenets/SearchPlayground";

const ManageKnowledgeBase = () => {
    const { knowledgebase_id } = useParams();
    const navigate = useNavigate();
    const db = useOasisBackend();
    const [tab, setTab] = useState<"edit" | "test" | "chat">("edit");

    const [knowledgeBase, setKnowledgeBase] = useState<ReadKnowledgeBase | null>(null);

    useEffect(() => {
        if(!knowledgebase_id) {
            navigate("/playground")
            return;
        }
        const fetchKnowledgeBase = async () => {
            const response = await db.endpoints.api.apiKnowledgeBasesRetrieve(knowledgebase_id);
            setKnowledgeBase(response.data);
        }
        fetchKnowledgeBase();
    }, [knowledgebase_id]);

    if(!knowledgebase_id) {
        navigate("/playground")
        return <div>No knowledgebase id</div>
    }

    
    return(
        <div className="flex flex-col min-h-screen pt-16 gap-2 bg-gray-900 text-white">
            <ApplicationNavbar />
            <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2 w-full justify-evenly py-8">
                    <button className={cn("p-2 rounded-md", tab === "edit" && "bg-gray-400")} onClick={() => setTab("edit")}>Edit</button>
                    <button className={cn("p-2 rounded-md", tab === "test" && "bg-gray-400")} onClick={() => setTab("test")}>Test</button>
                    <button className={cn("p-2 rounded-md", tab === "chat" && "bg-gray-400")} onClick={() => setTab("chat")}>Chat</button>
                </div>
                {tab === "edit" && knowledgeBase && <EditKnowledgeBase knowledgeBase={knowledgeBase} setKnowledgeBase={setKnowledgeBase} />}
                {tab === "test" && knowledgeBase && <SearchPlayground knowledgebase_id={knowledgeBase.id} oasis_enabled={knowledgeBase.oasis_enabled} />}
                {tab === "chat" && knowledgeBase && <Chat knowledgebase_id={knowledgebase_id} />}
            </div>
        </div>
    )
}

export default ManageKnowledgeBase;