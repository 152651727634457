import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { UserProvider } from '../hooks/useUser';
import { commonRoutes } from '../commonPages/CommonRoutes';
import { Toaster } from 'react-hot-toast';
import Home from './pages/Home';

const VnogIndex = () => {
    return(
        <UserProvider mountingScreen={<></>}>
            <Helmet>
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <title>VNOG: Characteristics Estimation & Document Retrieval</title>
        </Helmet>
            <BrowserRouter future={{ v7_startTransition: true }} basename={''}>
                <Toaster />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    {commonRoutes}
                </Routes>
            </BrowserRouter>
        </UserProvider>
    )
}

export default VnogIndex;