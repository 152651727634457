interface AxisProps {
  label: string;          // Axis label (e.g., "Temperature")
  unit: string;           // Unit label (e.g., "°C")
  divisions: number;      // Number of gridlines
  color: string;          // Color of the axis and labels
  startPoint: {          // Starting point (0-100 for both x and y)
    x: number;
    y: number;
  };
  endPoint: {            // Ending point (0-100 for both x and y)
    x: number;
    y: number;
  };
  gridColor?: string;    // Optional color for gridlines
  fontSize?: number;     // Optional font size in pixels
  labelFontSize?: number; // New property for label font size
  numberFontSize?: number; // New property for division numbers font size
  tickLength: number;    // Remove the optional '?' since it has a default value
  range?: {              // Optional numeric range for division labels
    min: number;
    max: number;
  };
  categories?: string[]; // Optional array of categorical values
  decimals?: number;     // Optional number of decimal places (defaults to 1)
}

export class Axis {
  props: AxisProps;

  constructor(props: AxisProps) {
    this.props = {
      ...props,
      gridColor: props.gridColor ?? `${props.color}33`,  // Default: transparent version of main color
      fontSize: props.fontSize ?? 12,
      labelFontSize: props.labelFontSize ?? props.fontSize ?? 12,
      numberFontSize: props.numberFontSize ?? props.fontSize ?? 12,
      tickLength: props.tickLength ?? 5
    };
  }
  

  render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
    const { 
      label, unit, divisions, color, startPoint, endPoint, 
      gridColor, labelFontSize, numberFontSize, tickLength, range, categories, decimals = 1 
    } = this.props;

    // Convert normalized coordinates to pixel coordinates
    const start = {
      x: (startPoint.x / 100) * width,
      y: (startPoint.y / 100) * height
    };
    const end = {
      x: (endPoint.x / 100) * width,
      y: (endPoint.y / 100) * height
    };

    // Draw main axis line
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();

    // Calculate axis length and direction
    const axisLength = Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
    );
    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    // Determine if this is a vertical or horizontal axis
    const isVertical = Math.abs(endPoint.x - startPoint.x) < Math.abs(endPoint.y - startPoint.y);
    
    // Draw divisions and gridlines
    for (let i = 0; i <= divisions; i++) {
      const fraction = i / divisions;
      // Offset position for categorical data
      const adjustedFraction = categories ? fraction + 0.5/divisions : fraction;
      const x = start.x + (end.x - start.x) * adjustedFraction;
      const y = start.y + (end.y - start.y) * adjustedFraction;

      // Only draw tick and gridline if we're not past the last category
      if (!categories || i < divisions) {
        // Draw tick marks
        const tickX = Math.sin(angle) * tickLength;
        const tickY = -Math.cos(angle) * tickLength;

        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.moveTo(x - tickX, y - tickY);
        ctx.lineTo(x + tickX, y + tickY);
        ctx.stroke();

        // Draw gridlines
        ctx.beginPath();
        ctx.strokeStyle = gridColor!;
        ctx.lineWidth = 1;
        ctx.moveTo(x, y);
        
        if (isVertical) {
          ctx.lineTo(width * 0.9, y);
        } else {
          ctx.lineTo(x, height * 0.1);
        }
        ctx.stroke();
      }

      // Draw division labels
      if (range || categories) {
        let labelText = '';
        if (categories) {
          // For categorical data, offset by half a division to center between ticks
          const categoryIndex = Math.min(i, categories.length - 1);
          // Only show label if we're not at the last division
          if (i < divisions) {
            labelText = categories[categoryIndex];
          }
        } else if (range) {
          // For numeric ranges (existing logic)
          const value = range.min + (range.max - range.min) * fraction;
          labelText = value.toFixed(decimals);
        }

        // Only render if we have text to show
        if (labelText) {
          ctx.save();
          ctx.font = `${numberFontSize}px Arial`;
          ctx.fillStyle = color;
          ctx.textAlign = 'right';
          ctx.textBaseline = 'middle';
          
          // Calculate position with offset for categories
          const xPos = categories ? 
            start.x + (end.x - start.x) * (fraction + 0.5/divisions) :
            x;
          const yPos = categories ?
            start.y + (end.y - start.y) * (fraction + 0.5/divisions) :
            y;

          // Position labels based on axis orientation
          if (isVertical) {
            ctx.textAlign = 'right';
            ctx.fillText(labelText, xPos - tickLength * 2, yPos);
          } else {
            ctx.textAlign = 'center';
            ctx.textBaseline = 'top';
            ctx.fillText(labelText, xPos, yPos + tickLength * 2);
          }
          ctx.restore();
        }
      }
    }

    // Draw label
    ctx.save();
    ctx.font = `${labelFontSize}px Arial`;
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Position label in the middle of the axis
    const labelX = start.x + (end.x - start.x) * 0.5;
    const labelY = start.y + (end.y - start.y) * 0.5;

    // Determine if the center point (50,50) is above/left or below/right of the axis
    const centerX = width * 0.5;
    const centerY = height * 0.5;
    
    // Calculate the distance from center to the axis line
    const distanceToCenter = (centerY - labelY) * Math.cos(angle) - (centerX - labelX) * Math.sin(angle);
    const labelOffset = (distanceToCenter > 0 ? -6 : 6) * tickLength;  // Increased from 2.5 to 6

    ctx.translate(labelX, labelY);
    ctx.rotate(angle);
    ctx.fillText(`${label} (${unit})`, 0, labelOffset);
    ctx.restore();
  }

  updatePosition(startPoint: { x: number; y: number }, endPoint: { x: number; y: number }): void {
    this.props.startPoint = startPoint;
    this.props.endPoint = endPoint;
  }
} 