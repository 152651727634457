import { Dot } from './dot';
import { Axis } from './axis';

interface DataPoint {
    x: number | string;
    y: number | string;
}

interface AxisConfig {
    label: string;
    unit: string;
    range?: { min: number; max: number };
    divisions?: number;
    decimals?: number;
    categories?: string[];
}

interface ScatterChartProps {
    xAxis: AxisConfig;
    yAxis: AxisConfig;
    data: DataPoint[];
    pointSize?: number;
    pointColor?: string;
    padding?: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
}

export class ScatterChart {
    private xAxis: Axis;
    private yAxis: Axis;
    private dots: Dot[] = [];
    private padding: Required<ScatterChartProps['padding']>;
    private pointSize: number;
    private pointColor: string;
    private data: DataPoint[] = [];

    constructor({
        xAxis,
        yAxis,
        data = [],
        pointSize = 5,
        pointColor = 'rgba(255, 0, 0, 0.5)',
        padding = { left: 5, right: 1, top: 1, bottom: 5 }
    }: ScatterChartProps) {
        this.padding = padding;
        this.pointSize = pointSize;
        this.pointColor = pointColor;

        // Create axes
        this.xAxis = new Axis({
            label: xAxis.label,
            unit: xAxis.unit,
            divisions: xAxis.divisions ?? 10,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: 100 - this.padding.right, y: 100 - this.padding.bottom },
            tickLength: 5,
            range: xAxis.range,
            decimals: xAxis.decimals ?? 1,
            categories: xAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.yAxis = new Axis({
            label: yAxis.label,
            unit: yAxis.unit,
            divisions: yAxis.divisions ?? 10,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: this.padding.left, y: this.padding.top },
            tickLength: 5,
            range: yAxis.range,
            decimals: yAxis.decimals ?? 1,
            categories: yAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.updateData(data);
    }

    private convertToCanvasCoordinates(point: DataPoint): { x: number; y: number } {
        const padding = {
            left: this.padding?.left ?? 10,
            right: this.padding?.right ?? 10,
            top: this.padding?.top ?? 10,
            bottom: this.padding?.bottom ?? 10
        };

        let xCoord: number;
        let yCoord: number;

        // Handle X coordinate
        if (typeof point.x === 'string' && this.xAxis.props.categories) {
            const categoryIndex = this.xAxis.props.categories.indexOf(point.x);
            if (categoryIndex === -1) throw new Error(`Category "${point.x}" not found in x-axis categories`);
            const xScale = (100 - padding.left - padding.right) / (this.xAxis.props.categories.length - 1);
            xCoord = (categoryIndex * xScale) + padding.left;
        } else if (typeof point.x === 'number' && this.xAxis.props.range) {
            const xRange = this.xAxis.props.range.max - this.xAxis.props.range.min;
            const xScale = (100 - padding.left - padding.right) / xRange;
            xCoord = ((point.x - this.xAxis.props.range.min) * xScale) + padding.left;
        } else {
            throw new Error('Invalid x-axis configuration for data point type');
        }

        // Handle Y coordinate
        if (typeof point.y === 'string' && this.yAxis.props.categories) {
            const categoryIndex = this.yAxis.props.categories.indexOf(point.y);
            if (categoryIndex === -1) throw new Error(`Category "${point.y}" not found in y-axis categories`);
            const yScale = (100 - padding.top - padding.bottom) / (this.yAxis.props.categories.length - 1);
            yCoord = 100 - ((categoryIndex * yScale) + padding.bottom);
        } else if (typeof point.y === 'number' && this.yAxis.props.range) {
            const yRange = this.yAxis.props.range.max - this.yAxis.props.range.min;
            const yScale = (100 - padding.top - padding.bottom) / yRange;
            yCoord = 100 - (((point.y - this.yAxis.props.range.min) * yScale) + padding.bottom);
        } else {
            throw new Error('Invalid y-axis configuration for data point type');
        }

        return { x: xCoord, y: yCoord };
    }

    updateData(newData: DataPoint[]): void {
        this.data = newData;
        this.dots = newData.map(point => {
            const canvasCoords = this.convertToCanvasCoordinates(point);
            return new Dot({
                x: canvasCoords.x,
                y: canvasCoords.y,
                size: this.pointSize,
                color: this.pointColor
            });
        });
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        // Render axes first
        this.xAxis.render(ctx, width, height);
        this.yAxis.render(ctx, width, height);

        // Render all dots
        this.dots.forEach(dot => dot.render(ctx, width, height));
    }

    // Getter for the range of the axes
    get xRange() {
        return this.xAxis.props.range;
    }

    get yRange() {
        return this.yAxis.props.range;
    }
} 