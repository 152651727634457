import { useEffect } from "react";
import { LogoutItem } from "./LogoutButton";
import { LoginItem } from "./Login";
import { Link, useLocation } from "react-router-dom";
import styled from '@emotion/styled';
import React from "react";
import { cn } from "../utils/cn";
import { useUser } from "../hooks/useUser";
import { IconMenu } from "@tabler/icons-react";
import { SignUpItem } from "./SignupButton";
interface ApplicationNavbarProps {
  className?: string;
  openMenuClassName?: string;
}

const ApplicationNavbar: React.FC<ApplicationNavbarProps> = ({
  className,
  openMenuClassName = "bg-gray-800"
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const user = useUser();
  const location = useLocation();
  const loggedIn = user.state === "LoggedIn";

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [location]);

  return (
    <MenuContainer 
      menuOpen={menuOpen} 
      className={cn(
        "flex bg-transparent transition-colors duration-700 flex-col absolute top-0 pt-6 px-4 w-full md:px-8 z-10 text-white", 
        menuOpen ? openMenuClassName : "", 
        className
      )}
    >
      <MenuIcon onClick={() => setMenuOpen(menuOpen => !menuOpen)} className="md:hidden stroke-current" />
      <MenuLinks 
        className="flex flex-col gap-4 h-full md:w-full md:justify-between md:flex-row md:items-center" 
        menuOpen={menuOpen}
      >
        <div className="flex flex-col gap-4 md:flex-row">
          <Link to="/playground">Playground</Link>
          {loggedIn && <Link to="/manage-api">Manage API</Link>}
          <Link to="/api-docs">API Docs</Link>
        </div>
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          {loggedIn &&
            <div className="flex flex-col gap-4 md:w-full md:flex-row">
                <Link to="/settings">Account Management</Link>
                <LogoutItem />
              </div>}
          {!loggedIn && <>              
            <LoginItem />
            <SignUpItem />
          </>}
        </div>
      </MenuLinks>
    </MenuContainer>
  );
}

const MenuLinks = styled.nav<{ menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: max-height .5s ease-in-out;
  text-wrap: nowrap;

  ${props => props.menuOpen && `margin-bottom: 16px;`}

  @media (max-width: 768px) { 
    ${props => props.menuOpen ? "max-height: 300px;" : "max-height: 0;"}
    background: transparent;
  }
  
  overflow: hidden;
  @media (min-width: 768px) {
    height: 55px;
    width: 100%;
    flex-direction: row;
    margin: 0px;
    background: transparent;
  }
  `

const MenuIcon = styled(IconMenu)`
  width: fit-content;
  height: 48px;
  width: 48px;
  padding-bottom: 16px;
`

const MenuContainer = styled.div<{ menuOpen: boolean }>`
  @media (min-width: 768px) {
    background: none;
  }

`

export default ApplicationNavbar;
