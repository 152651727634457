import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import SubmitButton from '../SubmitButton';
import { Dataset as DatasetType } from '../../../api/OasisBackendApi';

interface CreateDatasetProps {
  empty?: boolean;
  onDatasetCreated: (newDataset: DatasetType) => void;
}

const CreateDataset: React.FC<CreateDatasetProps> = ({ empty: defaultEmpty = false, onDatasetCreated }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [data_type, setDataType] = useState('');
  const [data_source, setDataSource] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const db = useOasisBackend();
  const [isEmptyDataset, setIsEmptyDataset] = useState(defaultEmpty);
  const [metadata, setMetadata] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Parse metadata JSON if provided
    let parsedMetadata = {};
    if (metadata) {
      try {
        parsedMetadata = JSON.parse(metadata);
      } catch (error) {
        alert('Invalid JSON in metadata field');
        setIsSubmitting(false);
        return;
      }
    }

    if (!name || !description || !data_type || !data_source || (files.length === 0 && !isEmptyDataset)) {
      alert('Please fill in all required fields and upload at least one file.');
      setIsSubmitting(false);
      return;
    }

    // Create an empty CSV file if empty is true and no files are uploaded
    const filesToUpload = files.length === 0 && isEmptyDataset ? [new File([''], 'empty.csv', { type: 'text/csv' })] : files;

    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateDatasetCreate({
        name,
        description,
        data_type,
        data_source,
        files: filesToUpload,
        meta_data: parsedMetadata
      });

      onDatasetCreated(response.data as DatasetType);
    } catch (error) {
      console.error('Error creating dataset:', error);
      alert('Failed to create dataset. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      console.log('New files:', newFiles); // Log new files
      if (files.length === 0 || newFiles.every(file => file.type === files[0].type)) {
        setFiles([...files, ...newFiles]);
        console.log('Updated files state:', [...files, ...newFiles]); // Log updated state
      } else {
        alert('Please upload files of the same type.');
      }
    }
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      const newFiles = Array.from(e.dataTransfer.files);
      if (files.length === 0 || newFiles.every(file => file.type === files[0].type)) {
        setFiles([...files, ...newFiles]);
      } else {
        alert('Please upload files of the same type.');
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleEmptyToggle = () => {
    setIsEmptyDataset(!isEmptyDataset);
    if (!isEmptyDataset) {
      setFiles([]); // Clear files when switching to empty dataset
    }
  };

  return (
    <div className="h-full rounded bg-gray-100" onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()}>
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Create New Dataset</h1>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Dataset Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="data_type">
              Data Type
            </label>
            <input 
              id="data_type"
              type="text" 
              value={data_type} 
              placeholder="real world datum"
              onChange={(e) => setDataType(e.target.value)} 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="data_source">
              Data Source
            </label>
            <input 
              id="data_source"
              type="text" 
              value={data_source} 
              placeholder="The data source representing the datum"
              onChange={(e) => setDataSource(e.target.value)} 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={isEmptyDataset}
                onChange={handleEmptyToggle}
                className="mr-2"
              />
              <span className="text-gray-700 text-sm font-bold">Create Empty Dataset</span>
            </label>
          </div>

          {!isEmptyDataset && (
            <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="files">
                Upload Files (CSV or JSON Lines)
              </label>
              <div className="relative">
                  <input
                      id="files"
                      type="file"
                      accept=".csv, .jsonl"
                      onChange={handleFileChange}
                      className="absolute inset-0 w-full h-full hidden cursor-pointer"
                      multiple
                      required
                  />
                  <label
                      htmlFor="files"
                      className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-500 hover:bg-slate-700 text-white font-bold cursor-pointer flex items-center justify-center"
                  >
                      Add File(s)
                  </label>
              </div>
            </div>
            <div className="mb-4">
              {files.length > 0 && (
                <ul className="list-disc pl-5">
                  {files.map((file, index) => (
                    <li key={index} className="flex justify-between items-center">
                      <span>{file.name}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="ml-4 bg-slate-500 hover:bg-slate-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            </>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="metadata">
              Metadata (Optional JSON)
            </label>
            <textarea
              id="metadata"
              value={metadata}
              onChange={(e) => setMetadata(e.target.value)}
              placeholder='{"key": "value"}'
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-mono"
              rows={4}
            />
          </div>

          <div className="flex items-center justify-center">
            <SubmitButton
              isSubmitting={isSubmitting}
              submitText={isEmptyDataset ? "Create Empty Dataset" : "Create Dataset"}
              loadingText="Creating..."
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDataset;
