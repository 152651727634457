import { useOasisBackend } from "../hooks/useOasisBackend"
import { useState, useEffect } from "react"
import { IconCopy } from "@tabler/icons-react"
import { toast } from "react-hot-toast"

const APIKeyManager = () => {
    const db = useOasisBackend()
    const [publicKey, setPublicKey] = useState("")
    const [privateKey, setPrivateKey] = useState("")

    const getAPIKeys = async () => {
        const response = await db.endpoints.api.apiPublicPrivateKeyRetrieve()
        console.log(response.data)
        setPublicKey(response.data.public_key || "")
    }

    useEffect(() => {
        getAPIKeys()
    }, [])

    const createAPIKeys = async () => {
        const response = await db.endpoints.api.apiPublicPrivateKeyCreate()
        console.log(response.data)
        if (response.status === 200) {
            setPublicKey(response.data.public_token)
            setPrivateKey(response.data.private_token)
        }
    }
    
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
        toast.success("Copied to clipboard")
    }

    return (
        <div className="max-w-3xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6">API Key Manager</h1>
            
            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700">Key Type</th>
                            <th className="px-6 py-3 text-left text-sm font-semibold text-gray-700">Value</th>
                            <th className="px-6 py-3 w-16"></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        <tr>
                            <td className="px-6 py-4 text-sm font-medium text-gray-700">Public Key</td>
                            <td className="px-6 py-4 text-sm text-gray-500 font-mono flex flex-row items-center gap-2">
                                <div>{publicKey}</div>
                                <button 
                                        onClick={() => copyToClipboard(publicKey)}
                                        className="p-1.5 hover:bg-gray-100 rounded transition-colors"
                                        title="Copy to clipboard"
                                    >
                                        <IconCopy className="text-gray-700" size={18} />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4 text-sm font-medium text-gray-700">Private Key</td>
                            <td className="px-6 py-4 text-sm text-gray-500 font-mono">
                                <div className="flex flex-row items-center gap-2">
                                    <div>{privateKey || "XXXX"}</div>
                                    {privateKey && <button 
                                        onClick={() => copyToClipboard(privateKey)}
                                        className="p-1.5 hover:bg-gray-100 rounded transition-colors"
                                        title="Copy to clipboard"
                                    >
                                        <IconCopy className="text-gray-700" size={18} />
                                    </button>}
                                </div>
                                {privateKey && (
                                    <p className="text-amber-600 mt-1 text-xs">
                                        We will never show your private key again. Please save it now.
                                    </p>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-6">
                {publicKey && (
                    <p className="text-sm text-gray-500 mb-3">
                        Generating new keys will invalidate the current keys.
                    </p>
                )}
                <button 
                    onClick={createAPIKeys}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                >
                    Generate New API Keys
                </button>
            </div>
        </div>
    )
}

export default APIKeyManager