import React, { useState } from 'react';
import { useOasisBackend } from '../../../hooks/useOasisBackend';
import SubmitButton from '../SubmitButton';
import { Datum } from '../../../api/OasisBackendApi';

interface CreateDatumProps {
  datasetId: string;
  onDatumCreated: (newDatum: Datum) => void;
}

const CreateDatum: React.FC<CreateDatumProps> = ({ datasetId, onDatumCreated }) => {
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const db = useOasisBackend();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Parse content JSON
    let parsedContent = {};
    try {
      parsedContent = JSON.parse(content);
    } catch (error) {
      alert('Invalid JSON in content field');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await db.endpoints.testsuite.testsuiteApiCreateDatumCreate({
        dataset_id: datasetId,
        content: parsedContent
      });

      onDatumCreated(response.data as Datum);
    } catch (error) {
      console.error('Error creating datum:', error);
      alert('Failed to create datum. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="h-full rounded bg-gray-100">
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Create New Datum</h1>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
              Content (JSON)
            </label>
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder='{"key": "value"}'
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-mono"
              rows={8}
              required
            />
          </div>

          <div className="flex items-center justify-center">
            <SubmitButton
              isSubmitting={isSubmitting}
              submitText="Create Datum"
              loadingText="Creating..."
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDatum;
