import { Axis } from './axis';
import { HalfRoundedRectangle } from './halfRoundedRectangle';

interface DataPoint {
    category: string | number;
    value: number;
}

interface AxisConfig {
    label: string;
    unit: string;
    range?: { min: number; max: number };
    divisions?: number;
    decimals?: number;
    categories?: string[];
}

interface BarChartProps {
    xAxis: AxisConfig;
    yAxis: AxisConfig;
    data: DataPoint[];
    barColor?: string;
    barWidth?: number;
    barRadius?: number;
    padding?: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    orientation?: 'vertical' | 'horizontal';
}

export class BarChart {
    private xAxis: Axis;
    private yAxis: Axis;
    private bars: HalfRoundedRectangle[] = [];
    private padding: Required<BarChartProps['padding']>;
    private barColor: string;
    private barWidth: number;
    private barRadius: number;
    private data: DataPoint[] = [];
    private orientation: 'vertical' | 'horizontal';

    constructor({
        xAxis,
        yAxis,
        data = [],
        barColor = 'rgb(204, 85, 0)', // Warm orange
        barWidth = 8,
        barRadius = 5,
        padding = { left: 5, right: 1, top: 1, bottom: 5 },
        orientation = 'vertical'
    }: BarChartProps) {
        this.padding = padding;
        this.barColor = barColor;
        this.barWidth = barWidth;
        this.barRadius = barRadius;
        this.orientation = orientation;

        // Calculate the actual range for axis ticks
        const valueRange = yAxis.range ?? { min: 0, max: Math.max(...data.map(d => d.value)) };
        
        const categoryConfig = this.calculateAxisConfig(
            data, 
            Boolean(orientation === 'vertical' ? xAxis.categories : yAxis.categories)
        );

        if (orientation === 'vertical') {
            this.xAxis = new Axis({
                label: xAxis.label,
                unit: xAxis.unit,
                divisions: categoryConfig.divisions,
                color: '#777777',
                gridColor: '#33333333',
                startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
                endPoint: { x: 100 - this.padding.right, y: 100 - this.padding.bottom },
                tickLength: 5,
                categories: categoryConfig.categories,
                range: categoryConfig.range,
                decimals: xAxis.decimals ?? 1,
                labelFontSize: 16,
                numberFontSize: 12
            });

            this.yAxis = new Axis({
                label: yAxis.label,
                unit: yAxis.unit,
                divisions: yAxis.divisions ?? 10,
                color: '#777777',
                gridColor: '#33333333',
                startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
                endPoint: { x: this.padding.left, y: this.padding.top },
                tickLength: 5,
                range: valueRange,
                decimals: yAxis.decimals ?? 1,
                labelFontSize: 16,
                numberFontSize: 12
            });
        } else {
            this.yAxis = new Axis({
                label: xAxis.label,
                unit: xAxis.unit,
                divisions: categoryConfig.divisions,
                color: '#777777',
                gridColor: '#33333333',
                startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
                endPoint: { x: this.padding.left, y: this.padding.top },
                tickLength: 5,
                categories: categoryConfig.categories,
                range: categoryConfig.range,
                decimals: xAxis.decimals ?? 1,
                labelFontSize: 16,
                numberFontSize: 12
            });

            this.xAxis = new Axis({
                label: yAxis.label,
                unit: yAxis.unit,
                divisions: yAxis.divisions ?? 10,
                color: '#777777',
                gridColor: '#33333333',
                startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
                endPoint: { x: 100 - this.padding.right, y: 100 - this.padding.bottom },
                tickLength: 5,
                range: valueRange,
                decimals: yAxis.decimals ?? 1,
                labelFontSize: 16,
                numberFontSize: 12
            });
        }

        this.updateData(data);
    }

    private calculateAxisConfig(data: DataPoint[], isCategory: boolean): { 
        range: { min: number; max: number },
        divisions: number,
        categories?: string[]
    } {
        if (isCategory) {
            return {
                range: { min: -0.5, max: data.length - 0.5 },
                divisions: data.length,
                categories: data.map(d => String(d.category))
            };
        } else {
            // Sort data by category for consistent ordering
            const sortedData = [...data].sort((a, b) => 
                Number(a.category) - Number(b.category)
            );
            
            return {
                range: { min: -0.5, max: data.length - 0.5 },
                divisions: data.length,
                // Use the actual numeric values as category labels
                categories: sortedData.map(d => d.category.toString())
            };
        }
    }

    private calculateOptimalBarWidth(): number {
        const availableWidth = 100 - this.padding!.left - this.padding!.right;
        const availableHeight = 100 - this.padding!.top - this.padding!.bottom;

        if (this.orientation === 'vertical') {
            const categoryAxis = this.xAxis;
            let spacing: number;

            if (categoryAxis.props.categories) {
                spacing = availableWidth / categoryAxis.props.categories.length;
            } else {
                // For numeric data, find minimum distance between adjacent points
                const sortedData = [...this.data]
                    .sort((a, b) => Number(a.category) - Number(b.category));
                
                if (sortedData.length <= 1) return this.barWidth;

                let minSpacing = Infinity;
                for (let i = 1; i < sortedData.length; i++) {
                    const range = categoryAxis.props.range!;
                    const scale = availableWidth / (range.max - range.min);
                    const spacing = Math.abs(
                        Number(sortedData[i].category) - Number(sortedData[i-1].category)
                    ) * scale;
                    minSpacing = Math.min(minSpacing, spacing);
                }
                spacing = minSpacing;
            }

            // Use 60% of available space between points for the bar width
            return Math.min(this.barWidth, spacing * 0.6);
        } else {
            const categoryAxis = this.yAxis;
            let spacing: number;

            if (categoryAxis.props.categories) {
                spacing = availableHeight / categoryAxis.props.categories.length;
            } else {
                // For numeric data, find minimum distance between adjacent points
                const sortedData = [...this.data]
                    .sort((a, b) => Number(a.category) - Number(b.category));
                
                if (sortedData.length <= 1) return this.barWidth;

                let minSpacing = Infinity;
                for (let i = 1; i < sortedData.length; i++) {
                    const range = categoryAxis.props.range!;
                    const scale = availableHeight / (range.max - range.min);
                    const spacing = Math.abs(
                        Number(sortedData[i].category) - Number(sortedData[i-1].category)
                    ) * scale;
                    minSpacing = Math.min(minSpacing, spacing);
                }
                spacing = minSpacing;
            }

            // Use 60% of available space between points for the bar width
            return Math.min(this.barWidth, spacing * 0.6);
        }
    }

    updateData(newData: DataPoint[]): void {
        this.data = newData;
        const optimalBarWidth = this.calculateOptimalBarWidth();
        
        this.bars = newData.map(dataPoint => {
            const dimensions = this.calculateBarDimensions(dataPoint, optimalBarWidth);
            
            return new HalfRoundedRectangle({
                ...dimensions,
                radius: this.barRadius,
                color: this.barColor,
                roundedEnd: this.orientation === 'vertical' ? 'start' : 'end',
                orientation: this.orientation
            });
        });
    }

    private calculateBarDimensions(
        dataPoint: DataPoint, 
        barWidth: number
    ): { x: number; y: number; width: number; height: number } {
        const availableWidth = 100 - this.padding!.left - this.padding!.right;
        const availableHeight = 100 - this.padding!.top - this.padding!.bottom;
        
        if (this.orientation === 'vertical') {
            const categoryAxis = this.xAxis;
            const valueAxis = this.yAxis;
            const valueRange = valueAxis.props.range!;
            
            // Find index of the category
            const index = categoryAxis.props.categories!.indexOf(dataPoint.category.toString());
            const x = this.padding!.left + (index + 0.5) * (availableWidth / categoryAxis.props.categories!.length) - barWidth / 2;

            const valueScale = availableHeight / (valueRange.max - valueRange.min);
            const height = ((dataPoint.value - valueRange.min) * valueScale);
            const y = 100 - this.padding!.bottom - height;

            return { x, y, width: barWidth, height };
        } else {
            const categoryAxis = this.yAxis;
            const valueAxis = this.xAxis;
            const valueRange = valueAxis.props.range!;
            
            // Find index of the category
            const index = categoryAxis.props.categories!.indexOf(dataPoint.category.toString());
            const y = this.padding!.top + (index + 0.5) * (availableHeight / categoryAxis.props.categories!.length) - barWidth / 2;

            const valueScale = availableWidth / (valueRange.max - valueRange.min);
            const width = ((dataPoint.value - valueRange.min) * valueScale);

            return { x: this.padding!.left, y, width, height: barWidth };
        }
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        // Render axes first
        this.xAxis.render(ctx, width, height);
        this.yAxis.render(ctx, width, height);

        // Render all bars
        this.bars.forEach(bar => bar.render(ctx, width, height));
    }
} 