import DocumentUploader from "./DocumentUploader";
import DocumentList from "./DocumentList";
import { useOasisBackend } from "../../hooks/useOasisBackend";
import { useEffect, useState } from "react";
import { ReadKnowledgeBase, Document } from "../../api/OasisBackendApi";
import TranslateKnowledgeBaseButton from "./TranslateKnowledgeBaseButton";

const EditKnowledgeBase = ({knowledgeBase, setKnowledgeBase}: {knowledgeBase: ReadKnowledgeBase, setKnowledgeBase: (knowledgeBase: ReadKnowledgeBase) => void}) => {
    const db = useOasisBackend();
    const [editName, setEditName] = useState(knowledgeBase.name);
    const [editDescription, setEditDescription] = useState(knowledgeBase.description);
    const [editLoading, setEditLoading] = useState(false);
    const [editError, setEditError] = useState<string | null>(null);
    const [documents, setDocuments] = useState<Document[]>([]);

    const fetchDocuments = async () => {
        console.log('fetching documents', knowledgeBase.id);
        try {
            const response = await db.endpoints.api.apiKnowledgeBaseDocumentsList(knowledgeBase.id);
            setDocuments(response.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const deleteDocument = (documentId: string) => {
        console.log('deleting document', documentId);
        setDocuments(documents => documents.filter(document => document.id !== documentId));
    }

    const onUploadSuccess = (document: Document) => {
        setDocuments(documents => [...documents, document]);
    }

    useEffect(() => {
        fetchDocuments();
    }, []);

    console.log(knowledgeBase.name);

    const editKnowledgeBase = async () => {
        setEditLoading(true);
        const response = await db.endpoints.api.apiKnowledgeBasesUpdate(knowledgeBase.id, {
            name: editName,
            description: editDescription
        });
        setEditLoading(false);
        if (response.status === 200) {
            setKnowledgeBase({...knowledgeBase, name: editName, description: editDescription});
        } else {
            setEditError("Failed to edit knowledge base");
        }
    }
    return (
        <div className="flex flex-col gap-2 text-white">
            <div>
                <input
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    className="border p-2 rounded-md w-full bg-gray-800 border-gray-700 text-white"
                />
            </div>
            <div>
                <textarea
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                    className="border p-2 rounded-md w-full h-32 bg-gray-800 border-gray-700 text-white"
                />
            </div>
            <button 
                onClick={editKnowledgeBase}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 bg-blue-600 hover:bg-blue-700"
            >
                Edit
            </button>
            <div className="flex flex-row gap-2">
                <TranslateKnowledgeBaseButton knowledgeBaseId={knowledgeBase.id}/>
            </div>
            <DocumentList knowledgeBaseId={knowledgeBase.id} documents={documents} onDelete={deleteDocument}/>
            <div className="flex flex-col gap-2">
                <h3 className="text-white">Documents</h3>
                <DocumentUploader knowledgeBaseId={knowledgeBase.id} onUploadSuccess={onUploadSuccess}/>
            </div>
        </div>
    )
}

export default EditKnowledgeBase;