import { ControlPanel } from '../ControlPanel';
import { ColorMapping, SpaceData } from '../types/NDgraphics';

interface ColorSettingsPanelProps {
  colorMapping: ColorMapping;
  spaceData: SpaceData;
  dimensions: number;
  onColorMappingChange: (mapping: ColorMapping) => void;
}

const hexToRgba = (hex: string, alpha: number = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const rgbaToHex = (rgba: string) => {
  const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/);
  if (!match) return '#000000';
  const r = parseInt(match[1]).toString(16).padStart(2, '0');
  const g = parseInt(match[2]).toString(16).padStart(2, '0');
  const b = parseInt(match[3]).toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
};

const getDistinctColor = (existingColors: string[]) => {
  // Simple HSL-based distinct color generation
  const hue = existingColors.length 
    ? (existingColors.length * 137.508) % 360 // Golden angle approximation
    : Math.random() * 360;
  return `hsl(${hue}, 70%, 50%)`;
};

const getUniqueValuesForKey = (spaceData: SpaceData, key: string): string[] => {
  const values = new Set<string>();
  spaceData.points.forEach(point => {
    if (point.content && point.content[key] !== undefined) {
      values.add(String(point.content[key]));
    }
  });
  return Array.from(values);
};

export function ColorSettingsPanel({
  colorMapping,
  spaceData,
  dimensions,
  onColorMappingChange,
}: ColorSettingsPanelProps) {
  const handleTypeChange = (type: 'gradient' | 'group') => {
    if (type === 'gradient') {
      // Get initial dimension index or content key
      const initialSource = {
        type: 'dimension' as const,
        dimensionIndex: 0
      };

      // Create simple two-stop gradient from transparent to white
      onColorMappingChange({
        type: 'gradient',
        source: initialSource,
        colorStops: [
          { position: 0, color: 'rgba(255, 255, 255, 0)' },  // Transparent white for minimum
          { position: 1, color: 'rgba(255, 255, 255, 1)' }   // Solid white for maximum
        ],
      });
    } else {
      onColorMappingChange({
        type: 'group',
        source: {
          type: 'content',
          key: Object.keys(spaceData.points[0]?.content || {})[0] || '',
        },
        colorMap: {},
        defaultColor: 'rgba(255, 255, 255, 1)',
      });
    }
  };

  const handleSourceTypeChange = (sourceType: 'dimension' | 'content') => {
    if (colorMapping.type === 'gradient') {
      const newSource = {
        type: sourceType,
        dimensionIndex: sourceType === 'dimension' ? 0 : undefined,
        key: sourceType === 'content' ? Object.keys(spaceData.points[0]?.content || {})[0] || '' : undefined,
      };

      // Reset color stops when changing source
      onColorMappingChange({
        ...colorMapping,
        source: newSource,
        colorStops: [
          { position: 0, color: 'rgba(255, 255, 255, 0)' },
          { position: 1, color: 'rgba(255, 255, 255, 1)' }
        ]
      });
    }
  };

  return (
    <ControlPanel title="Color Settings" defaultExpanded={false}>
      <div className="space-y-4">
        <div className="bg-gray-800/70 p-2 rounded">
          <h2 className="font-bold">Color Mapping Type</h2>
          <select
            value={colorMapping.type}
            onChange={(e) => handleTypeChange(e.target.value as 'gradient' | 'group')}
            className="bg-white/20 rounded px-2 py-1 w-full mt-2"
          >
            <option value="gradient">Gradient</option>
            <option value="group">Group</option>
          </select>
        </div>

        {colorMapping.type === 'gradient' && (
          <>
            <div className="bg-gray-800/70 p-2 rounded">
              <h2 className="font-bold">Source Type</h2>
              <select
                value={colorMapping.source.type}
                onChange={(e) => handleSourceTypeChange(e.target.value as 'dimension' | 'content')}
                className="bg-white/20 rounded px-2 py-1 w-full mt-2"
              >
                <option value="dimension">Dimension</option>
                <option value="content">Content</option>
              </select>
            </div>

            {colorMapping.source.type === 'dimension' && (
              <div className="bg-gray-800/70 p-2 rounded">
                <h2 className="font-bold">Dimension</h2>
                <select
                  value={colorMapping.source.dimensionIndex}
                  onChange={(e) => onColorMappingChange({
                    ...colorMapping,
                    source: { ...colorMapping.source, dimensionIndex: Number(e.target.value) },
                  })}
                  className="bg-white/20 rounded px-2 py-1 w-full mt-2"
                >
                  {Array.from({ length: dimensions }, (_, i) => (
                    <option key={i} value={i}>Dimension {i + 1}</option>
                  ))}
                </select>
              </div>
            )}

            {colorMapping.source.type === 'content' && (
              <div className="bg-gray-800/70 p-2 rounded">
                <h2 className="font-bold">Content Key</h2>
                <select
                  value={colorMapping.source.key}
                  onChange={(e) => onColorMappingChange({
                    ...colorMapping,
                    source: { ...colorMapping.source, key: e.target.value },
                  })}
                  className="bg-white/20 rounded px-2 py-1 w-full mt-2"
                >
                  {Object.keys(spaceData.points[0]?.content || {}).map((key) => (
                    <option key={key} value={key}>{key}</option>
                  ))}
                </select>
              </div>
            )}

            <div className="bg-gray-800/70 p-2 rounded">
              <h2 className="font-bold">Gradient Colors</h2>
              <div className="space-y-2 mt-2">
                <div className="flex items-center gap-2">
                  <span className="text-sm">Min Color</span>
                  <div className="flex gap-2">
                    <input
                      type="color"
                      value={rgbaToHex(colorMapping.colorStops[0].color)}
                      onChange={(e) => {
                        const newStops = [...colorMapping.colorStops];
                        const opacity = colorMapping.colorStops[0].color.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1';
                        newStops[0] = { position: 0, color: hexToRgba(e.target.value, parseFloat(opacity)) };
                        onColorMappingChange({ ...colorMapping, colorStops: newStops });
                      }}
                      className="w-8 h-8"
                    />
                    <input
                      type="number"
                      min="0"
                      max="1"
                      step="0.1"
                      value={colorMapping.colorStops[0].color.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1'}
                      onChange={(e) => {
                        const newStops = [...colorMapping.colorStops];
                        const hex = rgbaToHex(colorMapping.colorStops[0].color);
                        newStops[0] = { position: 0, color: hexToRgba(hex, parseFloat(e.target.value)) };
                        onColorMappingChange({ ...colorMapping, colorStops: newStops });
                      }}
                      className="bg-white/20 rounded px-2 py-1 w-20"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm">Max Color</span>
                  <div className="flex gap-2">
                    <input
                      type="color"
                      value={rgbaToHex(colorMapping.colorStops[1].color)}
                      onChange={(e) => {
                        const newStops = [...colorMapping.colorStops];
                        const opacity = colorMapping.colorStops[1].color.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1';
                        newStops[1] = { position: 1, color: hexToRgba(e.target.value, parseFloat(opacity)) };
                        onColorMappingChange({ ...colorMapping, colorStops: newStops });
                      }}
                      className="w-8 h-8"
                    />
                    <input
                      type="number"
                      min="0"
                      max="1"
                      step="0.1"
                      value={colorMapping.colorStops[1].color.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1'}
                      onChange={(e) => {
                        const newStops = [...colorMapping.colorStops];
                        const hex = rgbaToHex(colorMapping.colorStops[1].color);
                        newStops[1] = { position: 1, color: hexToRgba(hex, parseFloat(e.target.value)) };
                        onColorMappingChange({ ...colorMapping, colorStops: newStops });
                      }}
                      className="bg-white/20 rounded px-2 py-1 w-20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {colorMapping.type === 'group' && (
          <>
            <div className="bg-gray-800/70 p-2 rounded">
              <h2 className="font-bold">Content Key</h2>
              <select
                value={colorMapping.source.key}
                onChange={(e) => {
                  const newKey = e.target.value;
                  onColorMappingChange({
                    ...colorMapping,
                    source: { ...colorMapping.source, key: newKey },
                    colorMap: {}, // Reset color map when changing key
                  });
                }}
                className="bg-white/20 rounded px-2 py-1 w-full mt-2"
              >
                {Object.keys(spaceData.points[0]?.content || {}).map((key) => (
                  <option key={key} value={key}>{key}</option>
                ))}
              </select>
            </div>

            <div className="bg-gray-800/70 p-2 rounded">
              <h2 className="font-bold">Default Color</h2>
              <div className="flex items-center gap-2 mt-2">
                <div className="flex gap-2">
                  <input
                    type="color"
                    value={rgbaToHex(colorMapping.defaultColor)}
                    onChange={(e) => {
                      const opacity = colorMapping.defaultColor.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1';
                      onColorMappingChange({
                        ...colorMapping,
                        defaultColor: hexToRgba(e.target.value, parseFloat(opacity)),
                      });
                    }}
                    className="w-8 h-8"
                  />
                  <input
                    type="number"
                    min="0"
                    max="1"
                    step="0.1"
                    value={colorMapping.defaultColor.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1'}
                    onChange={(e) => {
                      const hex = rgbaToHex(colorMapping.defaultColor);
                      onColorMappingChange({
                        ...colorMapping,
                        defaultColor: hexToRgba(hex, parseFloat(e.target.value)),
                      });
                    }}
                    className="bg-white/20 rounded px-2 py-1 w-20"
                  />
                </div>
                <span className="text-sm text-gray-300">Applied to all unselected groups</span>
              </div>
            </div>

            <div className="bg-gray-800/70 p-2 rounded">
              <div className="flex justify-between items-center">
                <h2 className="font-bold">Highlighted Groups</h2>
                <select
                  className="bg-white/20 rounded px-2 py-1"
                  onChange={(e) => {
                    if (e.target.value) {
                      const newMap = { ...colorMapping.colorMap };
                      newMap[e.target.value] = getDistinctColor(Object.values(newMap));
                      onColorMappingChange({
                        ...colorMapping,
                        colorMap: newMap,
                      });
                      e.target.value = ''; // Reset select after adding
                    }
                  }}
                >
                  <option value="">Add group...</option>
                  {getUniqueValuesForKey(spaceData, colorMapping.source.key)
                    .filter(value => !colorMapping.colorMap[value])
                    .map((value) => (
                      <option key={value} value={value}>{value}</option>
                    ))}
                </select>
              </div>
              
              <div className="space-y-2 mt-2">
                {Object.entries(colorMapping.colorMap).map(([value, color]) => (
                  <div key={value} className="flex items-center gap-2">
                    <span className="bg-white/20 rounded px-2 py-1 flex-grow">
                      {value}
                    </span>
                    <div className="flex gap-2">
                      <input
                        type="color"
                        value={rgbaToHex(color)}
                        onChange={(e) => {
                          const newMap = { ...colorMapping.colorMap };
                          const opacity = color.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1';
                          newMap[value] = hexToRgba(e.target.value, parseFloat(opacity));
                          onColorMappingChange({ ...colorMapping, colorMap: newMap });
                        }}
                        className="w-8 h-8"
                      />
                      <input
                        type="number"
                        min="0"
                        max="1"
                        step="0.1"
                        value={color.match(/[\d.]+\)$/)?.[0].replace(')', '') ?? '1'}
                        onChange={(e) => {
                          const newMap = { ...colorMapping.colorMap };
                          const hex = rgbaToHex(color);
                          newMap[value] = hexToRgba(hex, parseFloat(e.target.value));
                          onColorMappingChange({ ...colorMapping, colorMap: newMap });
                        }}
                        className="bg-white/20 rounded px-2 py-1 w-20"
                      />
                    </div>
                    <button
                      onClick={() => {
                        const newMap = { ...colorMapping.colorMap };
                        delete newMap[value];
                        onColorMappingChange({ ...colorMapping, colorMap: newMap });
                      }}
                      className="text-red-500 hover:text-red-400 px-2"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </ControlPanel>
  );
} 