// This is a modal which opens and closes when the user clicks an x in the upper right corner or clicks outside the modal.
// The modal is sized based on the screen size, and the rest of the page is darkened and blurred when the modal is active.

import React, { useState, useEffect } from 'react';
import { IconX } from "@tabler/icons-react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  className?: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, size = "medium", className }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [mouseDownTarget, setMouseDownTarget] = useState<EventTarget | null>(null);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setMouseDownTarget(e.target);
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (mouseDownTarget === e.currentTarget && e.target === e.currentTarget) {
      setIsModalOpen(false);
      onClose();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div 
      className={`fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div 
        className={`bg-gray-800 rounded-lg shadow-xl max-h-[90vh] overflow-y-auto
          ${size === "small" ? "w-[25vw] h-[25vh]" : 
            size === "large" ? "w-[90vw] h-[75vh] md:w-[90vw]" : 
            "w-[92vw] sm:w-[75vw] md:w-[66vw] lg:w-[50vw] xl:w-[40vw]"}
          ${className}`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end p-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(false);
              onClose();
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            <IconX size={24} />
          </button>
        </div>
        <div className="p-8 w-full h-full">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
