import { VertexND } from './VertexND';

export class Edge {
  constructor(
    public start: VertexND,
    public end: VertexND,
    public color: string = '#FFFFFF',
    public lineWidth: number = 5,
    public noCull: boolean = false,
    public endColor?: string
  ) {}

  draw(ctx: CanvasRenderingContext2D) {
    const canvas = ctx.canvas;
    
    // Skip if vertices are outside canvas bounds
    if (!this.noCull && 
        (Math.abs(this.start.projectedPos[0]) > canvas.width || 
         Math.abs(this.start.projectedPos[1]) > canvas.height ||
         Math.abs(this.end.projectedPos[0]) > canvas.width || 
         Math.abs(this.end.projectedPos[1]) > canvas.height)) {
      return;
    }

    // Calculate opacity based on actual depth
    const avgDepth = (this.start.depth + this.end.depth) / 2;
    const opacity = Math.min(1, Math.max(0.2, 1 - Math.abs(avgDepth) / 1000));
    
    if (this.endColor) {
      // Create gradient
      const gradient = ctx.createLinearGradient(
        this.start.projectedPos[0],
        this.start.projectedPos[1],
        this.end.projectedPos[0],
        this.end.projectedPos[1]
      );
      gradient.addColorStop(0, this.color);
      gradient.addColorStop(1, this.endColor);
      
      ctx.beginPath();
      ctx.strokeStyle = gradient;
      ctx.lineWidth = this.lineWidth;
      ctx.moveTo(this.start.projectedPos[0], this.start.projectedPos[1]);
      ctx.lineTo(this.end.projectedPos[0], this.end.projectedPos[1]);
      ctx.stroke();
    } else {
      // Regular single-color drawing
      ctx.beginPath();
      ctx.strokeStyle = this.color;
      ctx.lineWidth = this.lineWidth;
      ctx.moveTo(this.start.projectedPos[0], this.start.projectedPos[1]);
      ctx.lineTo(this.end.projectedPos[0], this.end.projectedPos[1]);
      ctx.stroke();
    }
  }
} 