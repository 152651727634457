import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Scanner from './pages/Scanner';


const DataCTIndex = () => {
  return (
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/scanner" element={<Scanner />} />
          {/* Fallback route */}
          <Route path="*" element={<Homepage />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

export default DataCTIndex;
