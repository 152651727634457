import { Axis } from './axis';
import { RoundedRectangle } from './roundedRectangle';


interface AxisConfig {
    label: string;
    unit: string;
    range?: { min: number; max: number };
    divisions?: number;
    decimals?: number;
    categories?: string[];
}

interface HeatmapCell {
    rectangle: RoundedRectangle;
    value: number;
    centerX: number;
    centerY: number;
}

interface HeatmapChartProps {
    xAxis: AxisConfig;
    yAxis: AxisConfig;
    data: number[][];  // 2D array of values between 0 and 1
    colorScheme?: {
        baseColor: string;  // Base color in rgb format: 'rgb(r,g,b)'
        minOpacity: number;  // Minimum opacity (default: 0.1)
        maxOpacity: number;  // Maximum opacity (default: 0.9)
    };
    padding?: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    cellRadius?: number;  // Radius for rounded corners in pixels
    maintainSquareCells?: boolean;  // New option
    cellText?: {
        decimals?: number;
        color?: string;
        fontSize?: number;
    };
}

export class HeatmapChart {
    private xAxis: Axis;
    private yAxis: Axis;
    private cells: HeatmapCell[] = [];
    private padding: Required<NonNullable<HeatmapChartProps['padding']>>;
    private colorScheme: Required<NonNullable<HeatmapChartProps['colorScheme']>>;
    private cellRadius: number;
    private data: number[][] = [];
    private maintainSquareCells: boolean;
    private cellTextConfig: Required<NonNullable<HeatmapChartProps['cellText']>>;

    constructor({
        xAxis,
        yAxis,
        data,
        colorScheme = {
            baseColor: 'rgb(204, 85, 0)',  // Warm orange base color
            minOpacity: 0.1,
            maxOpacity: 0.9
        },
        padding = { left: 5, right: 1, top: 1, bottom: 5 },
        cellRadius = 4,
        maintainSquareCells = false,
        cellText = {}
    }: HeatmapChartProps) {
        // Create a new object to avoid modifying the input padding
        this.padding = { ...padding };
        this.colorScheme = colorScheme;
        this.cellRadius = cellRadius;
        this.maintainSquareCells = maintainSquareCells;

        this.cellTextConfig = {
            decimals: cellText.decimals ?? 2,
            color: cellText.color ?? '#ffffffbb',
            fontSize: cellText.fontSize ?? 12
        };

        // Create axes
        this.xAxis = new Axis({
            label: xAxis.label,
            unit: xAxis.unit,
            divisions: xAxis.categories?.length ?? xAxis.divisions ?? data[0].length,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: 100 - this.padding.right, y: 100 - this.padding.bottom },
            tickLength: 5,
            categories: xAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.yAxis = new Axis({
            label: yAxis.label,
            unit: yAxis.unit,
            divisions: yAxis.categories?.length ?? yAxis.divisions ?? data.length,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: this.padding.left, y: this.padding.top },
            tickLength: 5,
            categories: yAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.updateData(data);
    }

    private getColorForValue(value: number): string {
        const { baseColor, minOpacity, maxOpacity } = this.colorScheme;
        const opacity = minOpacity + (value * (maxOpacity - minOpacity));
        return baseColor.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }

    updateData(newData: number[][]): void {
        this.data = newData;
        this.cells = [];

        let cellWidth = (100 - this.padding.left - this.padding.right) / newData[0].length;
        let cellHeight = (100 - this.padding.top - this.padding.bottom) / newData.length;

        // Convert 2px to percentage of canvas size
        const gapX = 50 / 100;
        const gapY = 50 / 100;

        for (let i = 0; i < newData.length; i++) {
            for (let j = 0; j < newData[i].length; j++) {
                const value = Math.max(0, Math.min(1, newData[i][j]));
                // Add half of gap to position and subtract full gap from size
                const x = this.padding.left + (j * cellWidth) + (gapX / 2);
                const y = this.padding.top + (i * cellHeight) + (gapY / 2);

                this.cells.push({
                    rectangle: new RoundedRectangle({
                        x,
                        y,
                        width: cellWidth - gapX,
                        height: cellHeight - gapY,
                        radius: this.cellRadius,
                        color: this.getColorForValue(value)
                    }),
                    value,
                    centerX: x + (cellWidth - gapX) / 2,
                    centerY: y + (cellHeight - gapY) / 2
                });
            }
        }
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        if (this.maintainSquareCells) {
            // Calculate available space and desired cell dimensions
            const cellWidth = (100 - this.padding.left - this.padding.right) / this.data[0].length;
            const cellHeight = (100 - this.padding.top - this.padding.bottom) / this.data.length;

            // Convert percentages to absolute values using canvas dimensions
            const absoluteCellWidth = cellWidth * ctx.canvas.width / 100;
            const absoluteCellHeight = cellHeight * ctx.canvas.height / 100;

            if (absoluteCellWidth !== absoluteCellHeight) {
                // Create a new padding object to avoid modifying the original
                const adjustedPadding = { ...this.padding };
                
                if (absoluteCellWidth > absoluteCellHeight) {
                    // Cells are too wide - adjust horizontal padding
                    const desiredWidth = (absoluteCellHeight * this.data[0].length * 100) / ctx.canvas.width;
                    const extraSpace = 100 - this.padding.left - this.padding.right - desiredWidth;
                    adjustedPadding.left += extraSpace / 2;
                    adjustedPadding.right += extraSpace / 2;
                } else {
                    // Cells are too tall - adjust vertical padding
                    const desiredHeight = (absoluteCellWidth * this.data.length * 100) / ctx.canvas.height;
                    const extraSpace = 100 - this.padding.top - this.padding.bottom - desiredHeight;
                    adjustedPadding.top += extraSpace / 2;
                    adjustedPadding.bottom += extraSpace / 2;
                }

                // Update the padding
                this.padding = adjustedPadding;

                // Update axis positions with new padding
                this.xAxis.props.startPoint = { x: this.padding.left, y: 100 - this.padding.bottom };
                this.xAxis.props.endPoint = { x: 100 - this.padding.right, y: 100 - this.padding.bottom };
                
                this.yAxis.props.startPoint = { x: this.padding.left, y: 100 - this.padding.bottom };
                this.yAxis.props.endPoint = { x: this.padding.left, y: this.padding.top };

                // Update the cells with the adjusted padding
                this.updateData(this.data);
            }
        }

        // Render cells first (background)
        this.cells.forEach(cell => {
            cell.rectangle.render(ctx, width, height);
            
            // Render cell value text
            const text = cell.value.toFixed(this.cellTextConfig.decimals);
            ctx.save();
            ctx.fillStyle = this.cellTextConfig.color;
            ctx.font = `${this.cellTextConfig.fontSize}px Arial`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            
            // Convert percentage coordinates to actual pixels
            const xPos = (cell.centerX * width) / 100;
            const yPos = (cell.centerY * height) / 100;
            
            ctx.fillText(text, xPos, yPos);
            ctx.restore();
        });

        // Render axes on top
        this.xAxis.render(ctx, width, height);
        this.yAxis.render(ctx, width, height);
    }
} 