import React from 'react';
import { Link } from 'react-router-dom';
import { CubeAnimation } from '../components/cube_animation';
const Homepage: React.FC = () => {
  return (
    <div className="h-full rounded bg-gray-100">
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Welcome to the Test Suite</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Link to="/datasets" className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">Manage Datasets</h2>
            <p className="text-gray-600">View and manage your datasets efficiently.</p>
          </Link>
          <Link to="/experiments" className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">Create/Manage Experiment</h2>
            <p className="text-gray-600">Start a new experiment or manage existing ones.</p>
          </Link>
          <Link to="/studies" className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">Create/View Studies</h2>
            <p className="text-gray-600">Study your data and view analysis results.</p>
          </Link>
        </div>
        
        {/* Magical Search Button */}
        <div className="mt-12 text-center">
          <Link 
            to="/search"
            className="inline-flex items-center px-8 py-4 text-lg font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-500 rounded-full hover:from-purple-500 hover:to-blue-400 transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            <svg 
              className="w-6 h-6 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
              />
            </svg>
            Search Magic
          </Link>
        </div>
        
      </div>
    </div>
  );
};

export default Homepage;

