import { Renderer } from '../Renderer/Renderer'
import { ScatterChart } from '../Renderer/ScatterChart'
import { LineChart } from '../Renderer/LineChart'
import { BarChart } from '../Renderer/BarChart'
import { ClusterChart } from '../Renderer/ClusterChart'
import { HeatmapChart } from '../Renderer/HeatmapChart'
import { useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useOasisBackend } from '../../hooks/useOasisBackend'
import SidePanel from '../components/SidePanel'

interface ColumnInfo {
    type: 'numeric' | 'categorical' | 'unique' | 'pseudounique'
    nan_count: number
    unique_count: number
    total_rows: number
    min?: number
    max?: number
    categories?: string[]
}

interface DatasetInfo {
    [key: string]: ColumnInfo
}

const Scanner = () => {
    const [searchParams] = useSearchParams()
    const dataset_id = searchParams.get('dataset_id')
    const db = useOasisBackend()
    const [datasetInfo, setDatasetInfo] = useState<DatasetInfo | null>(null)
    const [activeFilters, setActiveFilters] = useState<{[key: string]: any}>({})
    const [chartConfig, setChartConfig] = useState({
        xAxis: '',
        yAxis: '',
        chartType: 'scatter' // Default chart type
    })
    const [chartData, setChartData] = useState(null)

    useEffect(() => {
        const fetchDatasetInfo = async () => {
            if (!dataset_id) return
            try {
                //@ts-ignore
                const response = await db.endpoints.datact.datactApiGetDatasetInfoRetrieve(dataset_id)
                setDatasetInfo(response.data.info)
            } catch (error) {
                console.error('Error fetching dataset info:', error)
            }
        }

        if (dataset_id) {
            fetchDatasetInfo()
        }
    }, [dataset_id, db])

    const handleFilterChange = (columnName: string, value: any) => {
        setActiveFilters(prev => ({
            ...prev,
            [columnName]: value
        }))
    }

    const handleChartTypeChange = (type: string) => {
        setChartConfig(prev => ({
            ...prev,
            chartType: type
        }))
    }

    const handleAxisDrop = (axis: 'x' | 'y', columnName: string) => {
        setChartConfig(prev => ({
            ...prev,
            [axis === 'x' ? 'xAxis' : 'yAxis']: columnName
        }))
    }

    useEffect(() => {
        const fetchChartData = async () => {
            const { xAxis, yAxis, chartType } = chartConfig
            if (!dataset_id || !xAxis || !yAxis) return

            try {
                //@ts-ignore
                const response = await db.endpoints.datact.datactApiGetSimpleChartCreate(
                    dataset_id,
                    {
                        chart_config: {
                            type: chartType,
                            x_column: xAxis,
                            y_column: yAxis,
                            active_filters: Object.entries(activeFilters).map(([key, value]) => ({
                                column_name: key,
                                value: value
                            }))
                        },
                        chart_data: [],
                        dataset_id: Number(dataset_id)
                    }
                );
                setChartData(response.data.chart_data)
            } catch (error) {
                console.error('Error fetching chart data:', error)
            }
        }

        fetchChartData()
    }, [chartConfig, dataset_id, activeFilters, db])

    return (
        <div className="flex h-screen bg-gray-900">
            <div className="flex-1 flex flex-col">
                {/* Chart Type Selector */}
                <div className="bg-gray-800 p-4 flex gap-2">
                    {['scatter', 'line', 'bar', 'histogram', 'heatmap'].map(type => (
                        <button
                            key={type}
                            onClick={() => handleChartTypeChange(type)}
                            className={`px-4 py-2 rounded ${
                                chartConfig.chartType === type
                                    ? 'bg-yellow-500 text-gray-900'
                                    : 'bg-gray-700 text-gray-300'
                            }`}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </button>
                    ))}
                </div>
                
                {/* Renderer with Drop Zones */}
                <div className="flex-1 flex flex-col relative">
                    {/* Y-axis drop zone */}
                    <div
                        className="absolute left-0 top-0 bottom-0 w-16 bg-gray-800/50 flex items-center justify-center"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                            e.preventDefault()
                            const columnName = e.dataTransfer.getData('columnName')
                            handleAxisDrop('y', columnName)
                        }}
                    >
                        <span className="rotate-[-90deg] text-gray-400">
                            {chartConfig.yAxis || 'Drop Y-Axis'}
                        </span>
                    </div>

                    {/* X-axis drop zone */}
                    <div
                        className="absolute left-16 right-0 bottom-0 h-16 bg-gray-800/50 flex items-center justify-center"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                            e.preventDefault()
                            const columnName = e.dataTransfer.getData('columnName')
                            handleAxisDrop('x', columnName)
                        }}
                    >
                        <span className="text-gray-400">
                            {chartConfig.xAxis || 'Drop X-Axis'}
                        </span>
                    </div>

                    {/* Main chart area */}
                    <div className="flex-1 m-16">
                        {chartData && (() => {
                            let chartObject;
                            switch (chartConfig.chartType) {
                                case 'scatter':
                                    chartObject = new ScatterChart(chartData);
                                    break;
                                case 'line':
                                    chartObject = new LineChart(chartData);
                                    break;
                                case 'bar':
                                case 'histogram': // histogram uses the bar chart with specific configuration
                                    chartObject = new BarChart(chartData);
                                    break;
                                case 'cluster':
                                    chartObject = new ClusterChart(chartData);
                                    break;
                                case 'heatmap':
                                    chartObject = new HeatmapChart(chartData);
                                    break;
                                default:
                                    console.error('Unknown chart type:', chartConfig.chartType);
                                    return null;
                            }
                            return <Renderer objects={[chartObject]} />;
                        })()}
                    </div>
                </div>
            </div>
            {datasetInfo && (
                <SidePanel 
                    datasetInfo={datasetInfo}
                    activeFilters={activeFilters}
                    onFilterChange={handleFilterChange}
                />
            )}
        </div>
    )
}

export default Scanner
