import { CameraND } from './CameraND';

export class VertexND {
  public projectedPos: [number, number] = [0, 0];
  public depth: number = 0;
  public loc: number[];

  constructor(
    public n: number,
    position: number[]
  ) {
    this.loc = [...position];
  }

  rotate(rotation: number[]) {
    let location = [...this.loc];

    for (let i = 0; i < this.n; i++) {
      if (rotation[i] > 0) {
        // Create n-dimensional rotation matrix
        const rotationMatrix = [];
        for (let r = 0; r < this.n; r++) {
          const row = [];
          for (let c = 0; c < this.n; c++) {
            if (i === c && i === r) {
              row.push(1);
            } else if (i === c || i === r) {
              row.push(0);
            } else if (r === (i+1) % this.n) {
              if (r === c) {
                row.push(Math.cos(rotation[i]));
              } else if (c === (i+2) % this.n) {
                row.push(Math.sin(rotation[i]));
              } else {
                row.push(0);
              }
            } else if (r === (i+2) % this.n) {
              if (r === c) {
                row.push(Math.cos(rotation[i]));
              } else if (c === (i+1) % this.n) {
                row.push(-Math.sin(rotation[i]));
              } else {
                row.push(0);
              }
            } else if (r === c) {
              row.push(1);
            } else {
              row.push(0);
            }
          }
          rotationMatrix.push(row);
        }

        location = this.multiplyVectorMatrix(location, rotationMatrix);
      }
    }
    
    this.loc = location;
  }

  project(camera: CameraND) {
    const projection = camera.project(this.loc);
    this.projectedPos = projection.projected;
    this.depth = projection.depth;
  }

  private multiplyVectorMatrix(vector: number[], matrix: number[][]): number[] {
    const result = new Array(matrix[0].length).fill(0);
    for (let i = 0; i < matrix[0].length; i++) {
      for (let j = 0; j < vector.length; j++) {
        result[i] += vector[j] * matrix[j][i];
      }
    }
    return result;
  }
}