import Cookies from 'js-cookie';
import { useState } from 'react';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.thoughtful-oasis.com';

const Home = () => {
    const [zipcode, setZipcode] = useState('');
    const [huisnummer, setHuisnummer] = useState('');
    const [estimatedCharacteristicsReport, setEstimatedCharacteristicsReport] = useState<{
        characteristics: Record<string, {
            answer: string;
            explanation: string;
            confidence: number;
        }>;
        report: string;
    } | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto">
                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-900 mb-8">Address Lookup</h1>
                </div>
                <div className="space-y-4">
                    <div>
                        <input
                            type="text"
                            placeholder="Zipcode"
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Huisnummer"
                            value={huisnummer}
                            onChange={(e) => setHuisnummer(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
                        />
                    </div>
                    <button
                        onClick={() => {
                            setLoading(true);
                            setError(null);
                            fetch(`${BACKEND_URL}/vnog/estimate_characteristics/`, {
                                method: 'POST',
                                body: new URLSearchParams({
                                    'zipcode': zipcode,
                                    'huisnummer': huisnummer
                                }),
                                headers: { 
                                    'X-CSRFToken': Cookies.get('csrftoken') || '',
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                credentials: 'include',
                            })  
                            .then(response => response.json())
                            .then(data => {
                                console.log('Data:', data);
                                if (data.error) {
                                    setError(data.error);
                                } else {
                                    setEstimatedCharacteristicsReport(data);
                                }
                            })
                            .catch(error => console.log('Error:', error.error))
                            .finally(() => setLoading(false));
                        }}
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                    >
                        {loading ? 'Loading...' : 'Estimate'}
                    </button>

                </div>
            </div>

            {!!error && (
                <div className="mt-8 space-y-6">
                    <div className="bg-red-500 text-white p-4 rounded-lg">
                        <h3 className="text-lg font-medium">Error</h3>
                        <p>{error}</p>
                    </div>
                </div>
            )}

            {estimatedCharacteristicsReport && (
                <div className="mt-8 space-y-6">
                    <div className="bg-white shadow rounded-lg overflow-hidden">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium text-gray-900">Characteristics</h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Characteristic
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Answer
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Explanation
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Confidence
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {Object.entries(estimatedCharacteristicsReport.characteristics).map(([key, value]) => (
                                        <tr key={key}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {key}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {value.answer}
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-500">
                                                {value.explanation}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                                    value.confidence >= 0.7 ? 'bg-green-100 text-green-800' :
                                                    value.confidence >= 0.4 ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-red-100 text-red-800'
                                                }`}>
                                                    {Math.round(value.confidence * 100)}%
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="bg-white shadow rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium text-gray-900">Report</h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <pre className="p-4 text-sm text-gray-700 whitespace-pre-wrap">
                                {estimatedCharacteristicsReport.report}
                            </pre>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Home;