import React from "react"
import ReactModal from "react-modal"

const Modal = ({isOpen, onClose, children}: {
    isOpen: boolean, 
    onClose: () => void, 
    children: React.ReactNode
}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                overlay: {
                    background: 'transparent',
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                content: {
                    border: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'fit-content',
                    height: 'auto',
                    inset: 'auto',
                    background: 'transparent',
                    zIndex: 1000
                }
            }}
        >
            {children}
        </ReactModal>
    )
}

export default Modal