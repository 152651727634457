import { Dot } from './dot';
import { Axis } from './axis';

interface DataPoint {
    x: number | string;
    y: number | string;
}

interface AxisConfig {
    label: string;
    unit: string;
    range?: { min: number; max: number };
    divisions?: number;
    decimals?: number;
    categories?: string[];
}

interface ClusterChartProps {
    xAxis: AxisConfig;
    yAxis: AxisConfig;
    data: DataPoint[][];  // List of point groups
    colors?: string[];    // Colors for each cluster
    pointSize?: number;
    hullOpacity?: number; // Opacity for the convex hull fills
    padding?: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
}

export class ClusterChart {
    private xAxis: Axis;
    private yAxis: Axis;
    private clusters: { dots: Dot[]; hullPoints: { x: number; y: number }[]; color: string }[] = [];
    private padding: Required<ClusterChartProps['padding']>;
    private pointSize: number;
    private hullOpacity: number;
    private colors: string[];
    private data: DataPoint[][] = [];

    // Default colors for clusters if none provided
    private static DEFAULT_COLORS = [
        'rgb(204, 85, 0)',   // Warm orange
        'rgb(0, 128, 128)',  // Teal
        'rgb(128, 0, 128)',  // Purple
        'rgb(184, 134, 11)', // Golden rod
        'rgb(0, 100, 0)',    // Dark green
        'rgb(139, 0, 0)',    // Dark red
        'rgb(25, 25, 112)',  // Midnight blue
    ];

    constructor({
        xAxis,
        yAxis,
        data = [],
        colors = ClusterChart.DEFAULT_COLORS,
        pointSize = 4,
        hullOpacity = 0.4,
        padding = { left: 5, right: 1, top: 1, bottom: 5 }
    }: ClusterChartProps) {
        this.padding = padding;
        this.pointSize = pointSize;
        this.hullOpacity = hullOpacity;
        this.colors = colors || ClusterChart.DEFAULT_COLORS;

        // Create axes (similar to other charts)
        this.xAxis = new Axis({
            label: xAxis.label,
            unit: xAxis.unit,
            divisions: xAxis.divisions ?? 10,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: 100 - this.padding.right, y: 100 - this.padding.bottom },
            tickLength: 5,
            range: xAxis.range,
            decimals: xAxis.decimals ?? 1,
            categories: xAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.yAxis = new Axis({
            label: yAxis.label,
            unit: yAxis.unit,
            divisions: yAxis.divisions ?? 10,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: this.padding.left, y: this.padding.top },
            tickLength: 5,
            range: yAxis.range,
            decimals: yAxis.decimals ?? 1,
            categories: yAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.updateData(data);
    }

    // Reuse convertToCanvasCoordinates from other charts
    private convertToCanvasCoordinates(point: DataPoint): { x: number; y: number } {
        const padding = {
            left: this.padding?.left ?? 10,
            right: this.padding?.right ?? 10,
            top: this.padding?.top ?? 10,
            bottom: this.padding?.bottom ?? 10
        };

        let xCoord: number;
        let yCoord: number;

        // Handle X coordinate
        if (typeof point.x === 'string' && this.xAxis.props.categories) {
            const categoryIndex = this.xAxis.props.categories.indexOf(point.x);
            if (categoryIndex === -1) throw new Error(`Category "${point.x}" not found in x-axis categories`);
            const xScale = (100 - padding.left - padding.right) / (this.xAxis.props.categories.length - 1);
            xCoord = (categoryIndex * xScale) + padding.left;
        } else if (typeof point.x === 'number' && this.xAxis.props.range) {
            const xRange = this.xAxis.props.range.max - this.xAxis.props.range.min;
            const xScale = (100 - padding.left - padding.right) / xRange;
            xCoord = ((point.x - this.xAxis.props.range.min) * xScale) + padding.left;
        } else {
            throw new Error('Invalid x-axis configuration for data point type');
        }

        // Handle Y coordinate
        if (typeof point.y === 'string' && this.yAxis.props.categories) {
            const categoryIndex = this.yAxis.props.categories.indexOf(point.y);
            if (categoryIndex === -1) throw new Error(`Category "${point.y}" not found in y-axis categories`);
            const yScale = (100 - padding.top - padding.bottom) / (this.yAxis.props.categories.length - 1);
            yCoord = 100 - ((categoryIndex * yScale) + padding.bottom);
        } else if (typeof point.y === 'number' && this.yAxis.props.range) {
            const yRange = this.yAxis.props.range.max - this.yAxis.props.range.min;
            const yScale = (100 - padding.top - padding.bottom) / yRange;
            yCoord = 100 - (((point.y - this.yAxis.props.range.min) * yScale) + padding.bottom);
        } else {
            throw new Error('Invalid y-axis configuration for data point type');
        }

        return { x: xCoord, y: yCoord };
    }

    // Calculate the convex hull using Graham Scan algorithm
    private calculateConvexHull(points: { x: number; y: number }[]): { x: number; y: number }[] {
        if (points.length < 3) return points;

        // Find point with lowest y-coordinate (and leftmost if tied)
        const startPoint = points.reduce((lowest, current) => {
            if (current.y < lowest.y || (current.y === lowest.y && current.x < lowest.x)) {
                return current;
            }
            return lowest;
        });

        // Sort points by polar angle with respect to start point
        const sortedPoints = points
            .filter(p => p !== startPoint)
            .sort((a, b) => {
                const angleA = Math.atan2(a.y - startPoint.y, a.x - startPoint.x);
                const angleB = Math.atan2(b.y - startPoint.y, b.x - startPoint.x);
                return angleA - angleB;
            });

        // Initialize hull with start point and first sorted point
        const hull = [startPoint];
        
        // Process remaining points
        for (const point of sortedPoints) {
            while (hull.length >= 2) {
                const p1 = hull[hull.length - 2];
                const p2 = hull[hull.length - 1];
                
                // Check if turn is counterclockwise
                const cross = (p2.x - p1.x) * (point.y - p1.y) - 
                            (p2.y - p1.y) * (point.x - p1.x);
                
                if (cross > 0) break;
                hull.pop();
            }
            hull.push(point);
        }

        return hull;
    }

    updateData(newData: DataPoint[][]): void {
        this.data = newData;
        this.clusters = newData.map((group, index) => {
            const color = this.colors[index % this.colors.length];
            
            // Convert points to canvas coordinates
            const canvasPoints = group.map(point => this.convertToCanvasCoordinates(point));
            
            // Create dots for each point
            const dots = canvasPoints.map(point => new Dot({
                x: point.x,
                y: point.y,
                size: this.pointSize,
                color: color
            }));

            // Calculate convex hull
            const hullPoints = this.calculateConvexHull(canvasPoints);

            return { dots, hullPoints, color };
        });
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        // Render axes first
        this.xAxis.render(ctx, width, height);
        this.yAxis.render(ctx, width, height);

        // Render hulls first (so they're behind the points)
        this.clusters.forEach(cluster => {
            const { hullPoints, color } = cluster;
            
            if (hullPoints.length >= 3) {
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(
                    (hullPoints[0].x * width) / 100,
                    (hullPoints[0].y * height) / 100
                );
                
                for (let i = 1; i < hullPoints.length; i++) {
                    ctx.lineTo(
                        (hullPoints[i].x * width) / 100,
                        (hullPoints[i].y * height) / 100
                    );
                }
                
                ctx.closePath();
                ctx.fillStyle = color.replace('rgb', 'rgba').replace(')', `, ${this.hullOpacity})`);
                ctx.fill();
                ctx.restore();
            }
        });

        // Render dots on top
        this.clusters.forEach(cluster => {
            cluster.dots.forEach(dot => dot.render(ctx, width, height));
        });
    }

    // Getter for the range of the axes
    get xRange() {
        return this.xAxis.props.range;
    }

    get yRange() {
        return this.yAxis.props.range;
    }
} 