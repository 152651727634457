// show the content of the datum in a card. the content is a json object, which whould be nicely formatted and handle nested objects well. The card should only show a preview and on click expand.

import React, { useState } from 'react';
import { Datum } from '../../../api/OasisBackendApi';

interface DatumCardProps {
  datum: Datum;
  properties: string[];
  rank: number;
  isCorrect?: boolean;
  onCorrectChange?: (isCorrect: boolean) => void;
}

export const DatumCard: React.FC<DatumCardProps> = ({ datum, properties, rank, isCorrect, onCorrectChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderValue = (value: any): string => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    return String(value);
  };

  const getNestedValue = (obj: any, path: string) => {
    const keys = path.split('.');
    let current = obj;
    
    for (const key of keys) {
      if (current === undefined || current === null) return undefined;
      current = current[key];
    }
    
    return current;
  };

  const renderContent = () => {
    const contentKeys = properties?.length ? properties : Object.keys(datum.content);
    const metaDataKeys = Object.keys(datum.meta_data || {});
    
    return (
      <>
        <div className="flex justify-between items-start">
          <span className="text-gray-500">#{rank + 1}</span>
          {onCorrectChange && (
            <div className="flex items-center">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={isCorrect || false}
                  onChange={(e) => onCorrectChange(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-green-600 rounded border-gray-300 focus:ring-green-500"
                />
                <span className="text-sm text-gray-600">Correct</span>
              </label>
            </div>
          )}
        </div>
        <div>
          {contentKeys.map((key) => (
            <div key={key} className="mb-2 ml-2">
              <span className="font-semibold">{key}: </span>
              <span className="font-mono">{renderValue(getNestedValue(datum.content, key))}</span>
            </div>
          ))}
        </div>
        {metaDataKeys.length > 0 && (
          <div className="mb-4">
            <div className="font-bold text-gray-700 mb-2">Metadata:</div>
            {metaDataKeys.map((key) => (
              <div key={`meta-${key}`} className="mb-2 ml-2">
                <span className="font-semibold">{key}: </span>
                <span className="font-mono">{renderValue(datum.meta_data[key])}</span>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`p-4 rounded-lg border ${isCorrect ? 'border-green-500 bg-green-50' : 'border-gray-200 bg-white'}`}>
      <div>
        <div className={`${isExpanded ? '' : 'max-h-32 overflow-hidden'}`}>
          <div className="whitespace-pre-wrap break-words">
            {renderContent()}
          </div>
        </div>
        {!isExpanded && (
          <div className="text-center text-gray-500 mt-2">
            Click to expand
          </div>
        )}
      </div>
    </div>
  );
};