import ApplicationNavbar from "../../components/ApplicationNavbar";
import { useUser } from "../../hooks/useUser";
import APIKeyManager from "../../components/APIKeyManager";
import Usage from "../../components/Usage";

const ManageApi = () => {
    const user = useUser();
    const loggedIn = user.state === "LoggedIn";

    return (
        <div className="flex flex-col min-h-screen pt-16 gap-2 bg-gray-900 text-gray-100">
            <ApplicationNavbar />
            <div className="flex flex-col gap-2 justify-center p-4">
                {loggedIn && <Usage />}
                {loggedIn && <APIKeyManager />}
            </div>
        </div>
    )
}

export default ManageApi;