import { CubeAnimation } from "./cube_animation";

const FullPageLoader = () => {
    return (
    <div className='flex justify-center items-center h-screen w-screen'>
          <div className='mt-12 h-[200px] w-[200px]'>
            <CubeAnimation lineWidth={10}/>
          </div>
    </div>
  );
};

const ContainerLoader = () => {
    return (
    <div className='flex justify-center items-center h-full w-full'>
          <div className='mt-12 h-[200px] w-[200px]'>
            <CubeAnimation lineWidth={10}/>
          </div>
    </div>
  );
};

export { FullPageLoader, ContainerLoader };
