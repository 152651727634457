interface DotProps {
  x: number;          // x position (0-100)
  y: number;          // y position (0-100)
  size: number;       // diameter in pixels
  color: string;      // CSS color string (including rgba)
}

export class Dot {
  private x: number;
  private y: number;
  private size: number;
  private color: string;

  constructor({ x, y, size, color }: DotProps) {
    this.x = Math.max(0, Math.min(100, x));  // Clamp between 0-100
    this.y = Math.max(0, Math.min(100, y));  // Clamp between 0-100
    this.size = size;
    this.color = color;
  }

  render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
    // Convert normalized coordinates to pixel coordinates
    const pixelX = (this.x / 100) * width;
    const pixelY = (this.y / 100) * height;
    
    ctx.beginPath();
    ctx.arc(pixelX, pixelY, this.size / 2, 0, Math.PI * 2);
    ctx.fillStyle = this.color;
    ctx.fill();
  }
} 