import { useState } from 'react';
import { useOasisBackend } from '../../hooks/useOasisBackend';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const db = useOasisBackend();
  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleStart = async () => {
    if (!selectedFile) return;

    try {
      //@ts-ignore
      const response = await db.endpoints.datact.datactApiUploadDatasetCreate({
        file: selectedFile
      });
      navigate(`/scanner?dataset_id=${response.data.dataset_id}`);
    } catch (error) {
      console.error('Error uploading dataset:', error);
      // You might want to add proper error handling here
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-8">
      {/* File Upload Button */}
      <label 
        className="px-6 py-3 bg-gradient-to-r from-amber-500 to-yellow-500 
                   hover:from-amber-600 hover:to-yellow-600 
                   rounded-lg cursor-pointer
                   shadow-lg shadow-amber-500/50
                   transition-all duration-300"
      >
        <span className="text-black font-semibold">
          {selectedFile ? selectedFile.name : 'Select CSV File'}
        </span>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="hidden"
        />
      </label>

      {/* Start Button */}
      <button 
        onClick={handleStart}
        disabled={!selectedFile}
        className={`w-16 h-16 rounded-full 
                   border-2 border-amber-500
                   text-amber-500 font-bold
                   hover:bg-amber-500 hover:text-black
                   shadow-lg shadow-amber-500/50
                   transition-all duration-300
                   ${!selectedFile && 'opacity-50 cursor-not-allowed'}`}
      >
        Start
      </button>
    </div>
  );
};

export default Homepage;
