import { useEffect, useState } from "react";
import { useOasisBackend } from "../hooks/useOasisBackend";
import { ReadKnowledgeBase } from "../api/OasisBackendApi";
import Modal from "../testsuite/components/modal";
import { useNavigate, Link } from "react-router-dom";
import TesseractLoader from "./ui/tesseract-loader";


const CreateKnowledgeBaseForm = ({successCallback}: {successCallback: () => void}) => {
    const db = useOasisBackend();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [createLoading, setCreateLoading] = useState(false);
    const [createError, setCreateError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleCreate = async () => {
        setCreateLoading(true);
        if (name === "") {
            setCreateError("Name is required");
            setCreateLoading(false);
            return;
        }
        if (description === "") {
            setCreateError("Description is required");
            setCreateLoading(false);
            return;
        }
        const response = await db.endpoints.api.apiKnowledgeBasesCreate({
            name,
            description
        });
        setCreateLoading(false);
        if (response.status === 201) {
            navigate(`/knowledge-base/${response.data.id}`);
            successCallback();
        } else {
            setCreateError("Failed to create knowledge base");
        }
    }
    return (
        <div className="flex flex-col gap-2">
            <h2 className="text-white font-semibold">Create Knowledge Base</h2>
            {createError && <div className="text-red-400">{createError}</div>}
            <input 
                onChange={(e) => setName(e.target.value)} 
                value={name} 
                type="text" 
                placeholder="Knowledge Base Name"
                className="bg-gray-800 text-white p-2 rounded-md placeholder-gray-400 border border-gray-700 focus:border-blue-500 focus:outline-none" 
            />
            <textarea 
                onChange={(e) => setDescription(e.target.value)} 
                value={description} 
                placeholder="Knowledge Base Description"
                className="bg-gray-800 text-white p-2 rounded-md placeholder-gray-400 border border-gray-700 focus:border-blue-500 focus:outline-none" 
            />
            <button className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-md transition-colors duration-200" onClick={handleCreate}>Create</button>
        </div>
    )
}

const CreateKnowledgeBaseModal = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <CreateKnowledgeBaseForm successCallback={() => setShowModal(false)} />
            </Modal>
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition-colors duration-200" onClick={() => setShowModal(true)}>Create Knowledge Base</button>
        </div>
    )
}
const ListAndCreateKnowledgeBases = () => {
    const db = useOasisBackend();
    const [knowledgeBases, setKnowledgeBases] = useState<ReadKnowledgeBase[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        const fetchKnowledgeBases = async () => {
            const response = await db.endpoints.api.apiKnowledgeBasesList();
            console.log(response.data);
            setKnowledgeBases(response.data);
            setIsLoading(false);
        }
        fetchKnowledgeBases();
    }, []);

    const deleteKnowledgeBase = async (knowledgeBase: ReadKnowledgeBase) => {
        // Add a confirmation dialog
        const confirmed = window.confirm("Are you sure you want to delete this knowledge base?");
        if (!confirmed) {
            return;
        }
        const response = await db.endpoints.api.apiKnowledgeBasesDestroy(knowledgeBase.id);
        console.log(response);
        if (response.status === 204) {
            setKnowledgeBases(knowledgeBases.filter((kb) => kb.id !== knowledgeBase.id));
        }
    }

    return (
        <div className="flex flex-col gap-4 p-4">
            <h2 className="text-white font-semibold text-xl">Knowledge Bases</h2>
            <CreateKnowledgeBaseModal />
            {isLoading && <TesseractLoader />}
            {!isLoading && knowledgeBases.map((knowledgeBase) => (
                <div key={knowledgeBase.id} className="flex gap-2 bg-gray-800 p-4 rounded-lg">
                    <Link to={`/knowledge-base/${knowledgeBase.id}`} className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition-colors duration-200">Manage {knowledgeBase.name}</Link>
                    <button className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md transition-colors duration-200" onClick={() => deleteKnowledgeBase(knowledgeBase)}>Delete</button>
                </div>
            ))}
        </div>
    )
}


const KnowledgeBases = () => {
    return (
        <div className="flex flex-col min-h-screen pt-16 gap-2 bg-gray-900 text-gray-100">
            <div className="flex flex-row gap-2 justify-center">
            </div>
            <ListAndCreateKnowledgeBases />
        </div>
    )
}

export default KnowledgeBases;