import { useUser } from "../../hooks/useUser";
import ApplicationNavbar from "../../components/ApplicationNavbar";
import KnowledgeBases from "../../components/KnowledgeBases";

const Playground = () => {
    const user = useUser();
    const loggedIn = user.state === "LoggedIn";

    return (
        <div className="flex flex-col min-h-screen bg-gray-900 pt-16">
            <ApplicationNavbar />
            {loggedIn && <KnowledgeBases />}
        </div>
    )
}

export default Playground;