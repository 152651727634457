import { VertexND } from './VertexND';
import { Edge } from './Edge';
import { CameraND } from './CameraND';
import { ColorMapping } from '../components/NDgraphics/types/NDgraphics';

export class CoordinateSystemND {
  private vertices: VertexND[] = [];
  private edges: Edge[] = [];
  private size: number;
  private dimensions: number;

  constructor(
    dimensions: number, 
    size: number = 100,
    private useColor: boolean = true,
    private brightness: number = 1,
    private colorMapping?: ColorMapping
  ) {
    this.dimensions = dimensions;
    this.size = size;
    this.createCoordinateSystem();
  }

  private getColorForPosition(position: number[], dimIndex: number): string {
    if (!this.useColor || !this.colorMapping) {
      return `rgba(255, 255, 255, ${0.8 * this.brightness})`;
    }

    if (this.colorMapping.type === 'gradient') {
      let value: number;
      
      if (this.colorMapping.source.type === 'dimension') {
        // For coordinate system, we always use the current dimension as the source
        value = (position[dimIndex] / this.size + 1) / 2; // Convert from [-1,1] to [0,1]
      } else {
        // Content-based gradients don't make sense for coordinate system
        return `rgba(255, 255, 255, ${0.8 * this.brightness})`;
      }

      // Find the appropriate color stops
      const stops = this.colorMapping.colorStops;
      for (let i = 0; i < stops.length - 1; i++) {
        if (value >= stops[i].position && value <= stops[i + 1].position) {
          const t = (value - stops[i].position) / (stops[i + 1].position - stops[i].position);
          return this.interpolateColors(stops[i].color, stops[i + 1].color, t);
        }
      }
      return this.adjustOpacity(stops[stops.length - 1].color);
    } else {
      // Group-based coloring doesn't make sense for coordinate system
      return `rgba(255, 255, 255, ${0.8 * this.brightness})`;
    }
  }

  private interpolateColors(color1: string, color2: string, t: number): string {
    const c1 = this.parseColor(color1);
    const c2 = this.parseColor(color2);
    
    return `rgba(
      ${Math.round(this.brightness * (c1.r + (c2.r - c1.r) * t))},
      ${Math.round(this.brightness * (c1.g + (c2.g - c1.g) * t))},
      ${Math.round(this.brightness * (c1.b + (c2.b - c1.b) * t))},
      0.8
    )`;
  }

  private parseColor(color: string) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    ctx.fillStyle = color;
    return {
      r: parseInt(ctx.fillStyle.slice(1, 3), 16),
      g: parseInt(ctx.fillStyle.slice(3, 5), 16),
      b: parseInt(ctx.fillStyle.slice(5, 7), 16)
    };
  }

  private adjustOpacity(color: string): string {
    const { r, g, b } = this.parseColor(color);
    return `rgba(${r}, ${g}, ${b}, ${0.8 * this.brightness})`;
  }

  private createCoordinateSystem() {
    // Create origin vertex
    const origin = new VertexND(this.dimensions, Array(this.dimensions).fill(0));
    this.vertices.push(origin);

    // Create axis edges for each dimension
    for (let dim = 0; dim < this.dimensions; dim++) {
      // Create positive axis
      const posAxisEnd = Array(this.dimensions).fill(0);
      posAxisEnd[dim] = this.size;
      const posVertex = new VertexND(this.dimensions, posAxisEnd);
      
      // Create negative axis
      const negAxisEnd = Array(this.dimensions).fill(0);
      negAxisEnd[dim] = -this.size;
      const negVertex = new VertexND(this.dimensions, negAxisEnd);
      
      this.vertices.push(posVertex, negVertex);

      // Create positive gradient edge with full color range
      const posEdge = new Edge(
        negVertex,  // Start from negative end
        posVertex,  // End at positive end
        this.getColorForPosition(negAxisEnd, dim),  // Color at negative end
        2,
        true,
        this.getColorForPosition(posAxisEnd, dim)   // Color at positive end
      );
      this.edges.push(posEdge);
    }
  }

  project(camera: CameraND) {
    this.vertices.forEach(vertex => vertex.project(camera));
  }

  draw(ctx: CanvasRenderingContext2D) {
    // Draw edges
    this.edges.forEach(edge => edge.draw(ctx));

    // Draw axis labels
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Draw dimension labels at both ends of each axis
    for (let dim = 0; dim < this.dimensions; dim++) {
      const posVertex = this.vertices[dim * 2 + 1]; // Get positive end vertex
      const negVertex = this.vertices[dim * 2 + 2]; // Get negative end vertex
      
      // Label positive end
      const [px, py] = posVertex.projectedPos;
      ctx.fillStyle = this.getColorForPosition([this.size], dim);
      ctx.fillText(`+D${dim}`, px + 10, py + 10);
      
      // Label negative end
      const [nx, ny] = negVertex.projectedPos;
      ctx.fillStyle = this.getColorForPosition([-this.size], dim);
      ctx.fillText(`-D${dim}`, nx + 10, ny + 10);
    }
  }

  rotate(rotation: number[]) {
    this.vertices.forEach(vertex => vertex.rotate(rotation));
  }
} 