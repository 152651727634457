import React, { useState } from 'react';
import { LLMJob } from '../../../api/OasisBackendApi';
import Modal from '../../components/modal';
import { IconMessage } from "@tabler/icons-react";

interface JobCardProps {
  job: LLMJob;
}

const MAX_PREVIEW_LENGTH = 100;

const JobCard: React.FC<JobCardProps> = ({ job }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedFields, setExpandedFields] = useState<Set<string>>(new Set());
  const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);

  const formatPassthroughData = (data: any) => {
    if (!data) return 'No data';
    if (typeof data === 'string') return data;
    return JSON.stringify(data, null, 2);
  };

  const truncateText = (text: string, maxLength: number = MAX_PREVIEW_LENGTH) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  const toggleField = (field: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const newExpandedFields = new Set(expandedFields);
    if (expandedFields.has(field)) {
      newExpandedFields.delete(field);
    } else {
      newExpandedFields.add(field);
    }
    setExpandedFields(newExpandedFields);
  };

  const handleCardClick = (e: React.MouseEvent) => {
    if (!(e.target as HTMLElement).closest('.messages-button')) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <>
      <div 
        className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
        onClick={handleCardClick}
      >
        <div className="mb-4 flex justify-between items-start">
          <div>
            <h3 className="text-xl font-semibold">Job ID: {job.id}</h3>
            <p className={`text-sm ${
              job.status === 'COMPLETED' ? 'text-green-600' : 
              job.status === 'FAILED' ? 'text-red-600' : 
              'text-yellow-600'
            }`}>
              Status: {job.status}
            </p>
            {job.error_message && (
              <p className="text-sm text-red-600 mt-1">
                Error: {job.error_message}
              </p>
            )}
          </div>
          <button
            className="messages-button p-2 rounded-full hover:bg-gray-100"
            onClick={() => setIsMessagesModalOpen(true)}
            title="View Messages"
          >
            <IconMessage size={20} className="text-gray-600" />
          </button>
        </div>

        <div className="text-sm text-gray-600">
          <p>Model: {job.parameters.model}</p>
          <p>Created: {new Date(job.created_at).toLocaleString()}</p>
        </div>

        {isExpanded && job.pass_through_data && (
          <div className="mt-4 border-t pt-4">
            <div className="space-y-2">
              {Object.entries(job.pass_through_data).map(([key, value]) => {
                const formattedValue = formatPassthroughData(value);
                const isExpanded = expandedFields.has(key);

                return (
                  <div key={key} className="border-b pb-2">
                    <h4 className="font-semibold">{key}:</h4>
                    <pre className="text-xs bg-gray-50 p-2 rounded overflow-x-auto">
                      {isExpanded ? formattedValue : truncateText(formattedValue)}
                    </pre>
                    {formattedValue.length > MAX_PREVIEW_LENGTH && (
                      <button
                        onClick={(e) => toggleField(key, e)}
                        className="text-blue-500 text-xs mt-1 hover:underline"
                      >
                        {isExpanded ? 'Show less' : 'Show more'}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        
        <div className="mt-2 text-sm text-gray-500 text-center">
          {isExpanded ? 'Click to collapse' : 'Click to expand'}
        </div>
      </div>

      <Modal
        isOpen={isMessagesModalOpen}
        onClose={() => setIsMessagesModalOpen(false)}
        size="large"
      >
        <div className="space-y-4">
          <h2 className="text-2xl font-bold mb-4">Messages for Job {job.id}</h2>
          <div className="space-y-4">
            {job.messages?.map((message: any, index: any) => (
              <div key={index} className="border rounded-lg p-4 bg-gray-50">
                <div className="flex justify-between items-start mb-2">
                  <span className="font-semibold">{message.role}</span>
                  <span className="text-sm text-gray-500">
                    {new Date(message.created_at).toLocaleString()}
                  </span>
                </div>
                <pre className="whitespace-pre-wrap text-sm font-mono bg-white p-3 rounded border">
                  {message.content}
                </pre>
                {message.function_call && (
                  <div className="mt-2">
                    <div className="font-semibold text-sm text-gray-700">Function Call:</div>
                    <pre className="whitespace-pre-wrap text-sm font-mono bg-white p-3 rounded border">
                      {JSON.stringify(message.function_call, null, 2)}
                    </pre>
                  </div>
                )}
              </div>
            ))}
            {(!job.messages || job.messages.length === 0) && (
              <p className="text-gray-500 text-center">No messages available for this job.</p>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default JobCard; 