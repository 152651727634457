import { useEffect, useRef, useState, useCallback } from 'react';
import { CameraND } from '../utils/CameraND';
import { CubeND } from '../utils/CubeND';
import { ArrowND } from '../utils/ArrowND';
import { PointND } from '../utils/PointND';
import { CoordinateSystemND } from '../utils/CoordinateSystemND';
import { CalculateObjectData } from '../components/NDgraphics/SpaceDataVisualizer';
import { ControlPanel } from '../components/NDgraphics/ControlPanel';
import { PerformancePanel } from '../components/NDgraphics/panels/PerformancePanel';
import { SpaceParametersPanel } from '../components/NDgraphics/panels/SpaceParametersPanel';
import { CameraParametersPanel } from '../components/NDgraphics/panels/CameraParametersPanel';
import { SpaceData, ObjectType, DimensionMapping, DatasetConfig } from '../components/NDgraphics/types/NDgraphics';
import { DatasetPanel } from '../components/NDgraphics/panels/DatasetPanel';

const defaultSpaceData: SpaceData = {
  dimensions: [
    { name: "X", min: -1, max: 1 },
    { name: "Y", min: -1, max: 1 },
    { name: "Z", min: -1, max: 1 },
  ],
  points: [
    {
      content: { label: "Point 1" },
      position: [0, 0, 0]
    },
  ]
};

export default function NdGraphics() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [fps, setFps] = useState(0);
  const [multiplications, setMultiplications] = useState(0);
  
  // Configuration state
  const [dimensions, setDimensions] = useState(3);
  const [objectSize, setObjectSize] = useState(10);
  const [objectType, setObjectType] = useState<ObjectType>('cube');
  
  // N-dimensional controls
  const [rotationAngles, setRotationAngles] = useState(Array(12).fill(0));
  const [activeRotations, setActiveRotations] = useState(Array(12).fill(false));
  const [nValues, setNValues] = useState(Array(3).fill(1));
  const [cameraFocalLengths, setCameraFocalLengths] = useState([0,0, 1000]);
  const [cameraPosition, setCameraPosition] = useState([0,0, -100]);
  const [cameraRotation, setCameraRotation] = useState(Array(4).fill(0));
  
  const [globalDistance, setGlobalDistance] = useState(1);
  const [brightness, setBrightness] = useState(1);
  const [useColor, setUseColor] = useState(false);
  const [globalOffset, setGlobalOffset] = useState(1);
  const [globalZoom, setGlobalZoom] = useState(1);
  const [rotationSpeed, setRotationSpeed] = useState(0.01);
  const [cubeOpacity, setCubeOpacity] = useState(1);
  const [showCoordinateSystem, setShowCoordinateSystem] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(true);

  // Add these refs outside the useEffect
  const frameCountRef = useRef(0);
  const lastFpsUpdateRef = useRef(0);
  const userMappingChangeRef = useRef(false);

  const [dimensionMappings, setDimensionMappings] = useState<DimensionMapping[]>([]);

  const [rawSpaceData, setRawSpaceData] = useState<SpaceData>({
    dimensions: [
      { name: "X", min: -1, max: 1 },
      { name: "Y", min: -1, max: 1 },
      { name: "Z", min: -1, max: 1 },
    ],
    points: [
      {
        content: { label: "Point 1" },
        position: [0, 0, 0]
      },
    ]
  });

  const [spaceData, setSpaceData] = useState<SpaceData>(rawSpaceData);

  // Add new state for datasets
  const [datasets, setDatasets] = useState<DatasetConfig[]>([]);

  // Memoize the data update handler
  const handleDataUpdate = useCallback((newData: SpaceData) => {
    if (!userMappingChangeRef.current) {
      setRawSpaceData(newData);
    }
    setSpaceData(newData);
  }, []);

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const applyDimensionPreset = (dims: number) => {
    setDimensions(dims);
    
    const presets: Record<number, {
      focalLengths: number[],
      position: number[],
      rotation: number[]
    }> = {
      3: {
        focalLengths: [0,0, 1000],
        position: [0, 0, -100],
        rotation: Array(4).fill(0)
      },
      4: {
        focalLengths: [0,0, 100000, 21],
        position: [0, 0, -100, -100],
        rotation: Array(5).fill(0)
      },
      5: {
        focalLengths: [0,0, 1000000, 50, 50],
        position: [0, 0, -100, -100, -100],
        rotation: Array(12).fill(0)
      },
    };

    const preset = presets[dims] || {
      focalLengths: Array(dims).fill(1000),
      position: Array(dims).fill(10),
      rotation: Array(dims).fill(0)
    };

    setCameraFocalLengths(preset.focalLengths);
    setCameraPosition(preset.position);
    setCameraRotation(preset.rotation);

    if (nValues.length !== dims) {
      if (nValues.length > dims) {
        setNValues(nValues.slice(0, dims));
      } else {
        setNValues([...nValues, ...Array(dims - nValues.length).fill(1)]);
      }
    }
  };

  useEffect(() => {
    setDimensionMappings(
      Array.from({ length: dimensions }, (_, i) => ({
        sourceIndex: i < spaceData.dimensions.length ? i : -1,
        targetIndex: i
      }))
    );
  }, [dimensions, spaceData.dimensions.length]);


  // Create objects based on type
  const createObject = (type: ObjectType, data: any, arrowLengthMultiplier: number = 1.0) => {
    if (data.direction) {
      return new ArrowND(
        dimensions,
        data.position.map((offset: number, i: number) => 
          (offset - (nValues[i]-1)/2) * (objectSize * globalOffset)
        ),
        data.direction,
        (data.length || 1.0) * arrowLengthMultiplier * objectSize,
        rotationAngles,
        data.color
      );
    }
    
    switch (type) {
      case 'cube':
        return new CubeND(
          dimensions,
          data.position.map((offset: number, i: number) => 
            (offset - (nValues[i]-1)/2) * (objectSize * globalOffset)
          ),
          rotationAngles,
          objectSize,
          data.color
        );
      case 'point':
        return new PointND(
          dimensions,
          data.position.map((offset: number, i: number) => 
            (offset - (nValues[i]-1)/2) * (objectSize * globalOffset)
          ),
          data.color,
          5,
          rotationAngles
        );
      default:
        throw new Error(`Unknown object type: ${type}`);
    }
  };

  // Add function to handle adding new datasets
  const handleAddDataset = (newData: SpaceData) => {
    const newDataset: DatasetConfig = {
      id: `dataset-${datasets.length + 1}`,
      name: `Dataset ${datasets.length + 1}`,
      data: newData,
      visualizationMode: 'raw',
      objectType: 'cube',
      colorMapping: {
        type: 'gradient',
        source: {
          type: 'dimension',
          dimensionIndex: 0
        },
        colorStops: [
          { position: 0, color: '#ffffff' },
          { position: 0.5, color: '#ffffff' },
          { position: 1, color: '#ffffff' }
        ]
      },
      opacity: 1,
      dimensionMappings: Array.from({ length: dimensions }, (_, i) => ({
        sourceIndex: i < newData.dimensions.length ? i : -1,
        targetIndex: i
      })),
      visible: true,
      showPoints: true,
      showArrows: true,
      arrowLengthMultiplier: 1.0,
      normalizeArrows: false
    };
    
    setDatasets(prev => [...prev, newDataset]);
  };

  // Add function to update dataset config
  const updateDatasetConfig = (datasetId: string, updates: Partial<DatasetConfig>) => {
    setDatasets(prev => prev.map(dataset => 
      dataset.id === datasetId ? { ...dataset, ...updates } : dataset
    ));
  };

  // Move this outside the draw function
  const calculateDatasetObjects = useCallback((
    dataset: DatasetConfig, 
    camera: CameraND
  ) => {
    const objects = CalculateObjectData({
      spaceData: dataset.data,
      nValues,
      dimensions,
      colorMapping: dataset.colorMapping,
      opacity: dataset.opacity,
      dimensionMappings: dataset.dimensionMappings,
      visualizationMode: dataset.visualizationMode,
    }).filter(data => {
      if (data.direction) {
        return dataset.showArrows;
      }
      return dataset.showPoints;
    }).map(data => {
      let length = data.length;
      if (data.direction && dataset.normalizeArrows) {
        length = 1;
      }
      
      const object = createObject(
        dataset.objectType, 
        {
          ...data,
          position: data.position,
          length: length ? length * dataset.arrowLengthMultiplier : undefined
        }
      );

      object.project(camera);
      return object;
    });

    return objects;
  }, [dimensions, nValues, objectSize, globalOffset, rotationAngles]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    let animationFrameId: number;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const updateFps = (timestamp: number) => {
      if (!lastFpsUpdateRef.current) {
        lastFpsUpdateRef.current = timestamp;
        frameCountRef.current = 0;
        return;
      }

      frameCountRef.current++;

      const elapsed = timestamp - lastFpsUpdateRef.current;
      if (elapsed >= 1000) {
        const currentFps = Math.round((frameCountRef.current * 1000) / elapsed);
        setFps(currentFps);
        frameCountRef.current = 0;
        lastFpsUpdateRef.current = timestamp;
      }
    };

    const draw = (timestamp: number) => {
      updateFps(timestamp);
      ctx.clearRect(-canvas.width/2, -canvas.height/2, canvas.width, canvas.height);

      const camera = new CameraND(
        cameraFocalLengths.map(f => f * globalZoom),
        cameraPosition.map(p => p * globalDistance * globalZoom),
        cameraRotation
      );

      setRotationAngles(prev => 
        prev.map((angle, i) => activeRotations[i] ? (angle + rotationSpeed*0.1) % (2 * Math.PI) : angle)
      );    

      if (showCoordinateSystem) {
        const coordSystem = new CoordinateSystemND(
          dimensions,
          objectSize * 15,
          useColor,
          brightness,
          useColor && datasets.length > 0 ? datasets[0].colorMapping : undefined
        );
        coordSystem.rotate(rotationAngles);
        coordSystem.project(camera);
        coordSystem.draw(ctx);
      }

      const sortedObjects: (CubeND | PointND | ArrowND)[] = [];

      datasets.forEach(dataset => {
        if (!dataset.visible) return;
        const objects = calculateDatasetObjects(
          dataset, 
          camera
        );
        sortedObjects.push(...objects);
      });

      // Sort and render all objects
      sortedObjects.sort((a, b) => b.getAverageDepth() - a.getAverageDepth());
      sortedObjects.forEach(object => object.draw(ctx));
      setMultiplications(sortedObjects.length);

      animationFrameId = requestAnimationFrame(draw);
    };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.translate(canvas.width/2, canvas.height/2);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animationFrameId = requestAnimationFrame(draw);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, [
    dimensions, objectSize, globalOffset, globalZoom, 
    brightness, useColor, rotationAngles, activeRotations, 
    nValues, cameraFocalLengths, cameraPosition, cameraRotation, 
    globalDistance, rotationSpeed, cubeOpacity, 
    showCoordinateSystem, datasets, calculateDatasetObjects
  ]);

  useEffect(() => {
    // Attempt to request fullscreen on component mount
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen().catch(err => {
        console.log('Error attempting to enable fullscreen:', err);
      });
    }
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className={`${isFullscreen ? 'fixed inset-0 z-50' : 'w-full h-full'} bg-black overflow-hidden`}>
      <div className="relative w-full h-full">
        <canvas ref={canvasRef} className="w-full h-full" />
        
        <button
          onClick={toggleFullscreen}
          className="fixed bottom-4 left-4 text-white hover:text-gray-300 z-50"
        >
          {isFullscreen ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
            </svg>
          )}
        </button>

        {/* Left panel container */}
        <div className="absolute top-[10px] left-4 flex flex-col items-start gap-2">

          <ControlPanel title="Datasets" defaultExpanded={false}>
            <div className="space-y-4">
              <button
                onClick={() => handleAddDataset(defaultSpaceData)}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              >
                Add Dataset
              </button>
              
              {datasets.map(dataset => (
                <DatasetPanel
                key={dataset.id}
                dataset={dataset}
                onUpdate={(updates) => updateDatasetConfig(dataset.id, updates)}
                onDelete={() => setDatasets(prev => prev.filter(d => d.id !== dataset.id))}
                dimensions={dimensions}
                />
              ))}
            </div>
          </ControlPanel>
        </div>

        {/* Right panel container */}
        <div className="absolute top-[10px] right-4 flex flex-col items-end gap-2">
          <PerformancePanel 
            fps={fps}
            multiplications={multiplications}
          />
          
          <CameraParametersPanel
            cameraFocalLengths={cameraFocalLengths}
            cameraPosition={cameraPosition}
            cameraRotation={cameraRotation}
            onFocalLengthsChange={setCameraFocalLengths}
            onPositionChange={setCameraPosition}
            onRotationChange={setCameraRotation}
          />
          <SpaceParametersPanel
            dimensions={dimensions}
            objectSize={objectSize}
            globalOffset={globalOffset}
            globalZoom={globalZoom}
            rotationSpeed={rotationSpeed}
            globalDistance={globalDistance}
            brightness={brightness}
            useColor={useColor}
            cubeOpacity={cubeOpacity}
            showCoordinateSystem={showCoordinateSystem}
            nValues={nValues}
            activeRotations={activeRotations}
            onDimensionsChange={applyDimensionPreset}
            onObjectSizeChange={setObjectSize}
            onGlobalOffsetChange={setGlobalOffset}
            onGlobalZoomChange={setGlobalZoom}
            onRotationSpeedChange={setRotationSpeed}
            onGlobalDistanceChange={setGlobalDistance}
            onBrightnessChange={setBrightness}
            onUseColorChange={setUseColor}
            onCubeOpacityChange={setCubeOpacity}
            onShowCoordinateSystemChange={setShowCoordinateSystem}
            onNValuesChange={setNValues}
            onActiveRotationsChange={setActiveRotations}
          />
        </div>
      </div>
    </div>
  );
}
