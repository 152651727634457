interface ColumnCardProps {
    name: string
    info: {
        type: string
        nan_count: number
        unique_count: number
        total_rows: number
        min?: number
        max?: number
        categories?: string[]
    }
    onDragStart: (e: React.DragEvent) => void
    onDragEnd: () => void
    draggable: boolean
}

const ColumnCard = ({ name, info, onDragStart, onDragEnd, draggable }: ColumnCardProps) => {
    return (
        <div
            draggable
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            className="bg-gray-600 p-2 rounded cursor-move hover:bg-gray-500 transition-all duration-200 group 
                      shadow-md hover:shadow-lg text-center hover:scale-105"
        >
            <span className="text-yellow-500 text-sm font-medium">{name}</span>
            
            {/* Expanded Info */}
            <div className="max-h-0 overflow-hidden group-hover:max-h-48 transition-all duration-200 mt-2">
                <p className="text-yellow-500">Type: {info.type}</p>
                <p className="text-gray-300">NaN: {info.nan_count}</p>
                <p className="text-gray-300">Unique: {info.unique_count}</p>
                <p className="text-gray-300">Total: {info.total_rows}</p>
                {info.type === 'numeric' && (
                    <>
                        <p className="text-gray-300">Min: {info.min}</p>
                        <p className="text-gray-300">Max: {info.max}</p>
                    </>
                )}
                {info.type === 'categorical' && (
                    <p className="text-gray-300">Categories: {info.categories?.join(', ')}</p>
                )}
            </div>
        </div>
    )
}

export default ColumnCard 