import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SpaceData } from '../types/NDgraphics';

interface DimensionMapping {
  sourceIndex: number;
  targetIndex: number;
}

interface DataLoaderProps {
  onDataLoad: (data: SpaceData) => void;
  targetDimensions: number;
  rawData: SpaceData;
  userMappingChangeRef: React.MutableRefObject<boolean>;
  showPoints: boolean;
  setShowPoints: (show: boolean) => void;
  showArrows: boolean;
  setShowArrows: (show: boolean) => void;
  arrowLengthMultiplier: number;
  setArrowLengthMultiplier: (multiplier: number) => void;
}

interface JsonData {
  dimensions: { name: string; min: number; max: number; }[];
  points?: { position: number[]; content: Record<string, any>; }[];
  vectors?: { 
    position: number[]; 
    direction: number[]; 
    length: number; 
    content: Record<string, any>; 
  }[];
}

export function DataLoader({ 
  onDataLoad, 
  targetDimensions, 
  rawData, 
  userMappingChangeRef,
  showPoints,
  setShowPoints,
  showArrows,
  setShowArrows,
  arrowLengthMultiplier,
  setArrowLengthMultiplier
}: DataLoaderProps) {
  const [dimensionMappings, setDimensionMappings] = useState<DimensionMapping[]>([]);
  const [dimensions, setDimensions] = useState<{ name: string }[]>([]);
  const initialMappingRef = useRef(true);
  const [isDataPanelCollapsed, setIsDataPanelCollapsed] = useState(false);

  const mapData = useCallback((data: SpaceData) => {
    const mappedPoints = data.points.map(point => ({
      content: point.content,
      position: dimensionMappings.map(mapping => 
        mapping.sourceIndex === -1 ? 0 : point.position[mapping.sourceIndex]
      ),
      direction: point.direction && dimensionMappings.map(mapping =>
        mapping.sourceIndex === -1 ? 0 : point.direction![mapping.sourceIndex]
      ),
      length: point.length
    }));

    return {
      dimensions: data.dimensions,
      points: mappedPoints
    };
  }, [dimensionMappings]);

  useEffect(() => {
    setDimensionMappings(
      Array.from({ length: targetDimensions }, (_, i) => ({
        sourceIndex: i < dimensions.length ? i : -1,
        targetIndex: i
      }))
    );
  }, [dimensions.length, targetDimensions]);

  const handleMappingChange = (index: number, sourceIndex: number) => {
    userMappingChangeRef.current = true;
    const newMappings = [...dimensionMappings];
    newMappings[index].sourceIndex = sourceIndex;
    setDimensionMappings(newMappings);
    
    if (rawData) {
      onDataLoad(mapData(rawData));
    }
  };

  useEffect(() => {
    if (!rawData) return;
    
    if (initialMappingRef.current) {
      initialMappingRef.current = false;
      onDataLoad(mapData(rawData));
      return;
    }
  }, [rawData, mapData, onDataLoad]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        if (!e.target?.result) return;
        const jsonData = JSON.parse(e.target.result as string) as JsonData;
        
        if (!jsonData.dimensions) {
          throw new Error('Invalid JSON structure: missing dimensions');
        }
        
        // Combine points and vectors into a single points array
        const combinedPoints = [
          ...(jsonData.points || []).map(p => ({
            position: p.position,
            content: p.content
          })),
          ...(jsonData.vectors || []).map(v => ({
            position: v.position,
            direction: v.direction,
            length: v.length,
            content: v.content
          }))
        ];

        if (combinedPoints.length === 0) {
          throw new Error('Invalid JSON structure: no points or vectors found');
        }
        
        setDimensions(jsonData.dimensions);

        const newMappings = Array.from({ length: targetDimensions }, (_, i) => ({
          sourceIndex: i < jsonData.dimensions.length ? i : -1,
          targetIndex: i
        }));
        setDimensionMappings(newMappings);

        const spaceData: SpaceData = {
          dimensions: jsonData.dimensions,
          points: combinedPoints
        };

        onDataLoad(spaceData);
      } catch (error) {
        alert('Invalid JSON file format: ' + (error as Error).message);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div className="space-y-4 bg-gray-800/70 p-4 rounded">
      <div className="flex justify-between items-center cursor-pointer" 
           onClick={() => setIsDataPanelCollapsed(!isDataPanelCollapsed)}>
        <div>
          <h2 className="font-bold text-lg">Data Loader</h2>
          {rawData && (
            <div className="text-sm text-gray-400">
              {rawData.dimensions.length} dimensions, {rawData.points.length} points | 
              Mapping: {dimensionMappings.map((mapping, idx) => 
                `x${idx + 1}: ${mapping.sourceIndex === -1 ? 'zero' : rawData.dimensions[mapping.sourceIndex].name}`
              ).join(', ')}
            </div>
          )}
        </div>
        <span className="text-xl">{isDataPanelCollapsed ? '+' : '−'}</span>
      </div>

      {!isDataPanelCollapsed && (
        <>
          <div className="space-y-4">
            <div className="space-y-2">
              <label className="block">Upload JSON File</label>
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                className="w-full bg-white/20 rounded px-2 py-1"
              />
            </div>

            {rawData && (
              <>
                <div className="space-y-2 bg-black/30 p-2 rounded">
                  <h3 className="font-semibold">Visualization Options</h3>
                  
                  <div className="flex items-center gap-4">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={showPoints}
                        onChange={(e) => setShowPoints(e.target.checked)}
                        className="form-checkbox"
                      />
                      Show Points
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={showArrows}
                        onChange={(e) => setShowArrows(e.target.checked)}
                        className="form-checkbox"
                      />
                      Show Arrows
                    </label>
                  </div>

                  {showArrows && (
                    <div className="space-y-1">
                      <label className="block text-sm">
                        Arrow Length Multiplier: {arrowLengthMultiplier.toFixed(2)}
                      </label>
                      <input
                        type="range"
                        min="0.1"
                        max="5"
                        step="0.1"
                        value={arrowLengthMultiplier}
                        onChange={(e) => setArrowLengthMultiplier(parseFloat(e.target.value))}
                        className="w-full"
                      />
                    </div>
                  )}
                </div>

                <div className="bg-black/30 p-2 rounded space-y-1">
                  <p className="text-sm text-gray-400">
                    Dimensions: {rawData.dimensions.map(d => d.name).join(', ')}
                  </p>
                  <div className="text-sm text-gray-400">
                    <p>Expected JSON format:</p>
                    <pre className="bg-black/30 p-2 rounded mt-1">
{`{
  "dimensions": [
    { "name": "X", "min": -1, "max": 1 },
    ...
  ],
  "points": [
    { "position": [0.1, 0.2, ...], "content": { "label": "Point 1" } },
    ...
  ],
  "vectors": [
    { 
      "position": [0.1, 0.2, ...],
      "direction": [1.0, 0.0, ...],
      "length": 1.5,
      "content": { "label": "Vector 1" }
    },
    ...
  ]
}`}
                    </pre>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="space-y-2">
            <h3 className="font-semibold">Dimension Mapping</h3>
            <div className="space-y-2 max-h-48 overflow-y-auto">
              {dimensionMappings.map((mapping, index) => (
                <div key={index} className="flex items-center gap-2 bg-black/30 p-2 rounded">
                  <select
                    value={mapping.sourceIndex}
                    onChange={(e) => handleMappingChange(index, Number(e.target.value))}
                    className="bg-white/20 rounded px-2 py-1"
                  >
                    <option value={-1}>Zero-filled</option>
                    {dimensions.map((dim, i) => (
                      <option key={i} value={i}>
                        {dim.name}
                      </option>
                    ))}
                  </select>
                  <span>→</span>
                  <span>Target Dimension {index}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
} 