import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IconChevronRight, IconChevronDown } from "@tabler/icons-react";

interface JsonViewerProps {
  data: any;
  initialDepth?: number;
  expandedDepth?: number;
}

interface JsonNodeProps {
  value: any;
  keyName?: string;
  depth: number;
  isLast: boolean;
  expandedDepth: number;
  onExpandAll?: (path: string[]) => void;
  onCollapseAll?: (path: string[]) => void;
  path: string[];
  expandedPaths: Set<string>;
  onToggle: (path: string[]) => void;
}

interface ContextMenuProps {
  x: number;
  y: number;
  onClose: () => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, onClose, onExpandAll, onCollapseAll }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const position = {
    left: Math.min(x, window.innerWidth - 200),
    top: Math.min(y, window.innerHeight - 100)
  };

  return (
    <div
      ref={menuRef}
      className="fixed bg-white shadow-lg border rounded-md py-1 z-50"
      style={position}
    >
      <button
        className="w-full px-4 py-2 text-left hover:bg-gray-100 text-sm"
        onClick={(e) => {
          e.stopPropagation();
          onExpandAll();
        }}
      >
        Expand All
      </button>
      <button
        className="w-full px-4 py-2 text-left hover:bg-gray-100 text-sm"
        onClick={(e) => {
          e.stopPropagation();
          onCollapseAll();
        }}
      >
        Collapse All
      </button>
    </div>
  );
};

const JsonNode: React.FC<JsonNodeProps> = ({ 
  value, 
  keyName, 
  depth, 
  isLast, 
  expandedDepth,
  onExpandAll,
  onCollapseAll,
  path,
  expandedPaths,
  onToggle
}) => {
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number } | null>(null);
  const isExpanded = expandedPaths.has(path.join('.'));
  const valueType = typeof value;
  const isObject = value !== null && (valueType === 'object' || Array.isArray(value));
  
  const handleContextMenu = useCallback((e: React.MouseEvent) => {
    if (isObject) {
      e.preventDefault();
      e.stopPropagation();
      setContextMenu({ x: e.clientX, y: e.clientY });
    }
  }, [isObject]);

  const handleExpandAll = useCallback(() => {
    if (onExpandAll) {
      onExpandAll(path);
    }
    setContextMenu(null);
  }, [onExpandAll, path]);

  const handleCollapseAll = useCallback(() => {
    if (onCollapseAll) {
      onCollapseAll(path);
    }
    setContextMenu(null);
  }, [onCollapseAll, path]);

  const renderValue = () => {
    if (value === null) return <span className="text-gray-500">null</span>;
    if (valueType === 'string') return <span className="text-green-600">"{value}"</span>;
    if (valueType === 'number') return <span className="text-blue-600">{value}</span>;
    if (valueType === 'boolean') return <span className="text-purple-600">{value.toString()}</span>;
    if (Array.isArray(value)) return isExpanded ? renderComplex() : <span className="text-gray-500">[...]</span>;
    if (isObject) return isExpanded ? renderComplex() : <span className="text-gray-500">{'{ ... }'}</span>;
    return String(value);
  };

  const renderComplex = () => {
    if (Array.isArray(value)) {
      return (
        <>
          [
          <div className="ml-4">
            {value.map((item, index) => (
              <JsonNode
                key={index}
                value={item}
                depth={depth + 1}
                isLast={index === value.length - 1}
                expandedDepth={expandedDepth}
                onExpandAll={onExpandAll}
                onCollapseAll={onCollapseAll}
                path={[...path, index.toString()]}
                expandedPaths={expandedPaths}
                onToggle={onToggle}
              />
            ))}
          </div>
          ]
        </>
      );
    }

    return (
      <>
        {'{'}
        <div className="ml-4">
          {Object.entries(value).map(([key, val], index, arr) => (
            <JsonNode
              key={key}
              keyName={key}
              value={val}
              depth={depth + 1}
              isLast={index === arr.length - 1}
              expandedDepth={expandedDepth}
              onExpandAll={onExpandAll}
              onCollapseAll={onCollapseAll}
              path={[...path, key]}
              expandedPaths={expandedPaths}
              onToggle={onToggle}
            />
          ))}
        </div>
        {'}'}
      </>
    );
  };

  const toggleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle(path);
  };

  return (
    <div className="font-mono text-sm">
      <div 
        className="flex items-start hover:bg-gray-50"
        onContextMenu={handleContextMenu}
        onClick={(e) => e.stopPropagation()}
      >
        {isObject && (
          <button
            onClick={toggleExpand}
            className="mr-1 mt-1 focus:outline-none hover:bg-gray-200 rounded"
          >
            {isExpanded ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
          </button>
        )}
        <div>
          {keyName !== undefined && (
            <>
              <span className="text-brown-600">"{keyName}"</span>
              <span className="mr-1">: </span>
            </>
          )}
          {renderValue()}
          {!isLast && <span>,</span>}
        </div>
      </div>
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={() => setContextMenu(null)}
          onExpandAll={handleExpandAll}
          onCollapseAll={handleCollapseAll}
        />
      )}
    </div>
  );
};

const JsonViewer: React.FC<JsonViewerProps> = ({ 
  data, 
  initialDepth = 0,
  expandedDepth = 1
}) => {
  const [expandedPaths, setExpandedPaths] = useState<Set<string>>(new Set());
  const initializedRef = useRef(false);

  // Initialize paths if not already done
  if (!initializedRef.current) {
    const initialPaths = new Set<string>();
    
    const expandInitialPaths = (obj: any, currentPath: string[] = []) => {
      if (obj && typeof obj === 'object' && currentPath.length < expandedDepth) {
        const pathStr = currentPath.join('.');
        if (pathStr) initialPaths.add(pathStr);
        
        if (Array.isArray(obj)) {
          obj.forEach((item, index) => {
            expandInitialPaths(item, [...currentPath, index.toString()]);
          });
        } else {
          Object.entries(obj).forEach(([key, value]) => {
            expandInitialPaths(value, [...currentPath, key]);
          });
        }
      }
    };

    expandInitialPaths(data);
    setExpandedPaths(initialPaths);
    initializedRef.current = true;
  }

  const handleToggle = useCallback((path: string[]) => {
    const pathStr = path.join('.');
    setExpandedPaths(prev => {
      const newPaths = new Set(prev);
      if (newPaths.has(pathStr)) {
        newPaths.delete(pathStr);
      } else {
        newPaths.add(pathStr);
      }
      return newPaths;
    });
  }, []);

  const handleExpandAll = useCallback((path: string[]) => {
    setExpandedPaths(prev => {
      const newPaths = new Set(prev);
      const pathStr = path.join('.');
      
      const addSubPaths = (obj: any, currentPath: string[]) => {
        if (obj && typeof obj === 'object') {
          const currentPathStr = currentPath.join('.');
          if (currentPathStr.startsWith(pathStr)) {
            newPaths.add(currentPathStr);
          }
          
          if (Array.isArray(obj)) {
            obj.forEach((item, index) => {
              addSubPaths(item, [...currentPath, index.toString()]);
            });
          } else {
            Object.entries(obj).forEach(([key, value]) => {
              addSubPaths(value, [...currentPath, key]);
            });
          }
        }
      };
      
      const target = path.reduce((obj, key) => obj?.[key], data);
      addSubPaths(target, path);
      return newPaths;
    });
  }, [data]);

  const handleCollapseAll = useCallback((path: string[]) => {
    setExpandedPaths(prev => {
      const newPaths = new Set(prev);
      const pathStr = path.join('.');
      
      Array.from(newPaths).forEach(existingPath => {
        if (existingPath.startsWith(pathStr)) {
          newPaths.delete(existingPath);
        }
      });
      return newPaths;
    });
  }, []);

  return (
    <div className="bg-white rounded-lg p-4 overflow-auto">
      <JsonNode
        value={data}
        depth={initialDepth}
        isLast={true}
        expandedDepth={expandedDepth}
        onExpandAll={handleExpandAll}
        onCollapseAll={handleCollapseAll}
        path={[]}
        expandedPaths={expandedPaths}
        onToggle={handleToggle}
      />
    </div>
  );
};

export default JsonViewer; 