import { useState } from 'react'
import ColumnCard from '../components/ColumnCard'
import FilterSlider from '../components/FilterSlider'

interface ColumnInfo {
    type: 'numeric' | 'categorical' | 'unique' | 'pseudounique'
    nan_count: number
    unique_count: number
    total_rows: number
    min?: number
    max?: number
    categories?: string[]
}

interface SidePanelProps {
    datasetInfo: { [key: string]: ColumnInfo }
    activeFilters: { [key: string]: any }
    onFilterChange: (columnName: string, value: any) => void
}

const SidePanel = ({ datasetInfo, activeFilters, onFilterChange }: SidePanelProps) => {
    const [activeColumns, setActiveColumns] = useState<string[]>([])
    const [isDraggingColumn, setIsDraggingColumn] = useState(false)

    const handleDragStart = (e: React.DragEvent, columnName: string) => {
        e.dataTransfer.setData('columnName', columnName)
        setIsDraggingColumn(true)
    }

    const handleDragEnd = () => {
        setIsDraggingColumn(false)
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault()
    }

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault()
        const columnName = e.dataTransfer.getData('columnName')
        setIsDraggingColumn(false)
        
        if (!activeColumns.includes(columnName)) {
            setActiveColumns(prev => [...prev, columnName])
        }
    }

    const handleRemoveFilter = (columnName: string) => {
        setActiveColumns(prev => prev.filter(col => col !== columnName))
        onFilterChange(columnName, null)
    }

    return (
        <div className="w-80 bg-gray-800 p-4 flex flex-col">
            {/* Column Cards Section */}
            <div className="mb-4 p-2 bg-gray-700 rounded-lg">
                <h3 className="text-yellow-500 font-semibold mb-2">Available Columns</h3>
                <div className="grid grid-cols-2 gap-2">
                    {Object.entries(datasetInfo).map(([columnName, info]) => (
                        <ColumnCard
                            key={columnName}
                            name={columnName}
                            info={info}
                            onDragStart={(e) => handleDragStart(e, columnName)}
                            onDragEnd={handleDragEnd}
                            draggable
                        />
                    ))}
                </div>
            </div>

            {/* Active Filters Section */}
            <div 
                className={`flex-1 bg-gray-700 rounded-lg p-2 ${isDraggingColumn ? 'ring-2 ring-yellow-500' : ''}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <h3 className="text-yellow-500 font-semibold mb-2">Active Filters</h3>
                {activeColumns.map(columnName => (
                    <FilterSlider
                        key={columnName}
                        columnName={columnName}
                        columnInfo={datasetInfo[columnName]}
                        value={activeFilters[columnName]}
                        onChange={(value) => onFilterChange(columnName, value)}
                        onRemove={() => handleRemoveFilter(columnName)}
                    />
                ))}
            </div>
        </div>
    )
}

export default SidePanel 