import { Dot } from './dot';
import { Axis } from './axis';

interface DataPoint {
    x: number | string;
    y: number | string;
}

interface AxisConfig {
    label: string;
    unit: string;
    range?: { min: number; max: number };
    divisions?: number;
    decimals?: number;
    categories?: string[];
}

type SmoothingMethod = 'none' | 'bezier' | 'monotone' | 'catmullRom';

interface LineChartProps {
    xAxis: AxisConfig;
    yAxis: AxisConfig;
    data: DataPoint[];
    lineColor?: string;
    lineWidth?: number;
    showPoints?: boolean;
    pointSize?: number;
    pointColor?: string;
    padding?: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    smoothing?: number;
    smoothingMethod?: SmoothingMethod;
}

export class LineChart {
    private xAxis: Axis;
    private yAxis: Axis;
    private dots: Dot[] = [];
    private padding: Required<LineChartProps['padding']>;
    private pointSize: number;
    private pointColor: string;
    private lineColor: string;
    private lineWidth: number;
    private showPoints: boolean;
    private data: DataPoint[] = [];
    private smoothing: number;
    private smoothingMethod: SmoothingMethod;

    constructor({
        xAxis,
        yAxis,
        data = [],
        lineColor = 'rgb(204, 85, 0)', // Warm orange
        lineWidth = 2,
        showPoints = true,
        pointSize = 4,
        pointColor = 'rgb(204, 185, 0)', // warm yellow
        padding = { left: 5, right: 1, top: 1, bottom: 5 },
        smoothing = 0,
        smoothingMethod = 'monotone'
    }: LineChartProps) {
        this.padding = padding;
        this.pointSize = pointSize;
        this.pointColor = pointColor;
        this.lineColor = lineColor;
        this.lineWidth = lineWidth;
        this.showPoints = showPoints;
        this.smoothing = Math.max(0, Math.min(1, smoothing));
        this.smoothingMethod = smoothingMethod;

        // Create axes (same as ScatterChart)
        this.xAxis = new Axis({
            label: xAxis.label,
            unit: xAxis.unit,
            divisions: xAxis.divisions ?? 10,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: 100 - this.padding.right, y: 100 - this.padding.bottom },
            tickLength: 5,
            range: xAxis.range,
            decimals: xAxis.decimals ?? 1,
            categories: xAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.yAxis = new Axis({
            label: yAxis.label,
            unit: yAxis.unit,
            divisions: yAxis.divisions ?? 10,
            color: '#777777',
            gridColor: '#33333333',
            startPoint: { x: this.padding.left, y: 100 - this.padding.bottom },
            endPoint: { x: this.padding.left, y: this.padding.top },
            tickLength: 5,
            range: yAxis.range,
            decimals: yAxis.decimals ?? 1,
            categories: yAxis.categories,
            labelFontSize: 16,
            numberFontSize: 12
        });

        this.updateData(data);
    }

    // Reuse the convertToCanvasCoordinates method from ScatterChart
    private convertToCanvasCoordinates(point: DataPoint): { x: number; y: number } {
        // ... same implementation as ScatterChart ...
        const padding = {
            left: this.padding?.left ?? 10,
            right: this.padding?.right ?? 10,
            top: this.padding?.top ?? 10,
            bottom: this.padding?.bottom ?? 10
        };

        let xCoord: number;
        let yCoord: number;

        // Handle X coordinate
        if (typeof point.x === 'string' && this.xAxis.props.categories) {
            const categoryIndex = this.xAxis.props.categories.indexOf(point.x);
            if (categoryIndex === -1) throw new Error(`Category "${point.x}" not found in x-axis categories`);
            const xScale = (100 - padding.left - padding.right) / (this.xAxis.props.categories.length - 1);
            xCoord = (categoryIndex * xScale) + padding.left;
        } else if (typeof point.x === 'number' && this.xAxis.props.range) {
            const xRange = this.xAxis.props.range.max - this.xAxis.props.range.min;
            const xScale = (100 - padding.left - padding.right) / xRange;
            xCoord = ((point.x - this.xAxis.props.range.min) * xScale) + padding.left;
        } else {
            throw new Error('Invalid x-axis configuration for data point type');
        }

        // Handle Y coordinate
        if (typeof point.y === 'string' && this.yAxis.props.categories) {
            const categoryIndex = this.yAxis.props.categories.indexOf(point.y);
            if (categoryIndex === -1) throw new Error(`Category "${point.y}" not found in y-axis categories`);
            const yScale = (100 - padding.top - padding.bottom) / (this.yAxis.props.categories.length - 1);
            yCoord = 100 - ((categoryIndex * yScale) + padding.bottom);
        } else if (typeof point.y === 'number' && this.yAxis.props.range) {
            const yRange = this.yAxis.props.range.max - this.yAxis.props.range.min;
            const yScale = (100 - padding.top - padding.bottom) / yRange;
            yCoord = 100 - (((point.y - this.yAxis.props.range.min) * yScale) + padding.bottom);
        } else {
            throw new Error('Invalid y-axis configuration for data point type');
        }

        return { x: xCoord, y: yCoord };
    }

    updateData(newData: DataPoint[]): void {
        this.data = newData;
        if (this.showPoints) {
            this.dots = newData.map(point => {
                const canvasCoords = this.convertToCanvasCoordinates(point);
                return new Dot({
                    x: canvasCoords.x,
                    y: canvasCoords.y,
                    size: this.pointSize,
                    color: this.pointColor
                });
            });
        }
    }

    // Add this helper method for curve interpolation
    private getCurvePoints(points: { x: number; y: number }[]): { x: number; y: number }[] {
        if (this.smoothing === 0 || points.length < 3) return points;

        switch (this.smoothingMethod) {
            case 'bezier':
                return this.getBezierPoints(points);
            case 'monotone':
                return this.getMonotonePoints(points);
            case 'catmullRom':
                return this.getCatmullRomPoints(points);
            default:
                return points;
        }
    }

    private getBezierPoints(points: { x: number; y: number }[]): { x: number; y: number }[] {
        const result: { x: number; y: number }[] = [];
        const tension = this.smoothing;

        for (let i = 0; i < points.length - 1; i++) {
            const curr = points[i];
            const next = points[i + 1];
            
            // Calculate control points
            const ctrl1 = {
                x: curr.x + (next.x - curr.x) * tension / 3,
                y: curr.y
            };
            const ctrl2 = {
                x: next.x - (next.x - curr.x) * tension / 3,
                y: next.y
            };

            // Add points along the bezier curve
            for (let t = 0; t <= 1; t += 0.1) {
                const x = Math.pow(1 - t, 3) * curr.x +
                         3 * Math.pow(1 - t, 2) * t * ctrl1.x +
                         3 * (1 - t) * Math.pow(t, 2) * ctrl2.x +
                         Math.pow(t, 3) * next.x;
                         
                const y = Math.pow(1 - t, 3) * curr.y +
                         3 * Math.pow(1 - t, 2) * t * ctrl1.y +
                         3 * (1 - t) * Math.pow(t, 2) * ctrl2.y +
                         Math.pow(t, 3) * next.y;

                result.push({ x, y });
            }
        }

        return result;
    }

    private getMonotonePoints(points: { x: number; y: number }[]): { x: number; y: number }[] {
        const result: { x: number; y: number }[] = [];
        const n = points.length;

        // Calculate slopes
        const m: number[] = new Array(n);
        
        for (let i = 0; i < n; i++) {
            if (i === 0) {
                m[i] = (points[1].y - points[0].y) / (points[1].x - points[0].x);
            } else if (i === n - 1) {
                m[i] = (points[n-1].y - points[n-2].y) / (points[n-1].x - points[n-2].x);
            } else {
                const slope1 = (points[i].y - points[i-1].y) / (points[i].x - points[i-1].x);
                const slope2 = (points[i+1].y - points[i].y) / (points[i+1].x - points[i].x);
                m[i] = (slope1 + slope2) / 2;
            }
        }

        // Adjust slopes for monotonicity
        for (let i = 0; i < n - 1; i++) {
            if (points[i+1].x === points[i].x) continue;
            
            const slope = (points[i+1].y - points[i].y) / (points[i+1].x - points[i].x);
            if (slope === 0) {
                m[i] = 0;
                m[i+1] = 0;
                continue;
            }

            const alpha = m[i] / slope;
            const beta = m[i+1] / slope;
            const h = Math.sqrt(alpha * alpha + beta * beta);
            
            if (h > 3) {
                const t = 3 / h;
                m[i] = t * alpha * slope;
                m[i+1] = t * beta * slope;
            }
        }

        // Generate points
        for (let i = 0; i < n - 1; i++) {
            result.push(points[i]);
            const dx = points[i+1].x - points[i].x;
            const dy = points[i+1].y - points[i].y;
            
            for (let t = 0.1; t < 1; t += 0.1) {
                const h00 = 2*t*t*t - 3*t*t + 1;
                const h10 = t*t*t - 2*t*t + t;
                const h01 = -2*t*t*t + 3*t*t;
                const h11 = t*t*t - t*t;

                const x = points[i].x + t * dx;
                const y = h00 * points[i].y + 
                         h10 * dx * m[i] + 
                         h01 * points[i+1].y + 
                         h11 * dx * m[i+1];

                result.push({ x, y });
            }
        }
        result.push(points[n-1]);

        return result;
    }

    private getCatmullRomPoints(points: { x: number; y: number }[]): { x: number; y: number }[] {
        const result: { x: number; y: number }[] = [];
        const tension = this.smoothing * 0.5;

        for (let i = 0; i < points.length; i++) {
            if (i === 0) {
                result.push(points[i]);
                continue;
            }

            const p0 = points[i - 1];
            const p1 = points[i];
            const p2 = points[i + 1] || p1;
            const p3 = points[i + 2] || p2;

            // Calculate control points
            for (let t = 0; t <= 1; t += 0.1) {
                const t2 = t * t;
                const t3 = t2 * t;

                const x = 0.5 * (
                    (2 * p1.x) +
                    (-p0.x + p2.x) * t +
                    (2 * p0.x - 5 * p1.x + 4 * p2.x - p3.x) * t2 +
                    (-p0.x + 3 * p1.x - 3 * p2.x + p3.x) * t3
                );

                const y = 0.5 * (
                    (2 * p1.y) +
                    (-p0.y + p2.y) * t +
                    (2 * p0.y - 5 * p1.y + 4 * p2.y - p3.y) * t2 +
                    (-p0.y + 3 * p1.y - 3 * p2.y + p3.y) * t3
                );

                result.push({ x, y });
            }
        }

        return result;
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        // Render axes first
        this.xAxis.render(ctx, width, height);
        this.yAxis.render(ctx, width, height);

        // Draw the line connecting points
        if (this.data.length > 1) {
            ctx.save();
            ctx.strokeStyle = this.lineColor;
            ctx.lineWidth = this.lineWidth;
            ctx.beginPath();

            // Convert all points to canvas coordinates first
            const points = this.data.map(point => this.convertToCanvasCoordinates(point));
            
            // Get interpolated points if smoothing is enabled
            const drawPoints = this.getCurvePoints(points);

            // Draw the path
            ctx.moveTo((drawPoints[0].x * width) / 100, (drawPoints[0].y * height) / 100);
            for (let i = 1; i < drawPoints.length; i++) {
                ctx.lineTo(
                    (drawPoints[i].x * width) / 100,
                    (drawPoints[i].y * height) / 100
                );
            }

            ctx.stroke();
            ctx.restore();
        }

        // Render dots on top if enabled
        if (this.showPoints) {
            this.dots.forEach(dot => dot.render(ctx, width, height));
        }
    }

    // Getter for the range of the axes
    get xRange() {
        return this.xAxis.props.range;
    }

    get yRange() {
        return this.yAxis.props.range;
    }
} 