import { VertexND } from './VertexND';
import { Edge } from './Edge';
import { CameraND } from './CameraND';
export class CubeND {
  vertices: VertexND[] = [];
  edges: Edge[] = [];
  baseVectors: number[][] = [];
  private averageDepth: number = 0;

  constructor(
    public dimensions: number,
    public position: number[] = Array(dimensions).fill(0),
    public rotationAngles: number[] = [],
    public size: number = 100,
    public color: string = '#FFFFFF',
    public lineWidth: number = 2
  ) {
    this.initializeVertices();
    this.initializeEdges();
    this.rotate(rotationAngles);
  }

  private initializeVertices() {
    const generateCombinations = (current: number[]) => {
      if (current.length === this.dimensions) {
        this.baseVectors.push([...current]);
        
        const vertexPosition = current.map((v, i) => 
          v * this.size/2 + this.position[i]
        );
        this.vertices.push(new VertexND(this.dimensions, vertexPosition));

        return;
      }

      current.push(1);
      generateCombinations(current);
      current.pop();
      
      current.push(-1);
      generateCombinations(current);
      current.pop();
    };

    generateCombinations([]);
  }

  private initializeEdges() {
    for (let i = 0; i < Math.pow(2, this.dimensions); i++) {
      for (let j = i; j < Math.pow(2, this.dimensions); j++) {
        const elementSums = this.baseVectors[i].map((num, index) => 
          num + this.baseVectors[j][index]
        );
        
        if (elementSums.reduce((sum, val) => sum + Math.abs(val), 0) === (this.dimensions-1)*2) {
          this.edges.push(new Edge(this.vertices[i], this.vertices[j], this.color, this.lineWidth));
        }
      }
    }
  }

  private rotate(angles: number[]) {
    this.vertices.forEach(vertex => vertex.rotate(angles));
  }

  
  project(camera: CameraND) {
    this.vertices.forEach(vertex => vertex.project(camera));

    // Calculate average depth after projection
    this.averageDepth = this.vertices.reduce((sum, vertex) => 
      sum + vertex.depth, 0) / this.vertices.length;
  }

  draw(ctx: CanvasRenderingContext2D) {
    // Sort edges based on the current projected depths
    const sortedEdges = [...this.edges].sort((a, b) => {
      const aDepth = (a.start.depth + a.end.depth) / 2;
      const bDepth = (b.start.depth + b.end.depth) / 2;
      return bDepth - aDepth; // Draw back to front
    });

    sortedEdges.forEach(edge => edge.draw(ctx));
  }

  getAverageDepth(): number {
    //console.log(this.averageDepth);
    return this.averageDepth;
  }
} 