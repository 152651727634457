import { ControlPanel } from '../ControlPanel';
import { DatasetConfig, VisualizationMode, ObjectType } from '../types/NDgraphics';
import { DataLoader } from './DataLoader';
import { ColorSettingsPanel } from './ColorSettingsPanel';
import { useState } from 'react';

interface DatasetPanelProps {
  dataset: DatasetConfig;
  onUpdate: (updates: Partial<DatasetConfig>) => void;
  onDelete: () => void;
  dimensions: number;
}

export function DatasetPanel({ dataset, onUpdate, onDelete, dimensions }: DatasetPanelProps) {
  return (
    <div className="bg-gray-800/70 p-4 rounded space-y-4">
      {/* Dataset Name */}
      <div className="flex items-center gap-2">
        <input
          type="text"
          value={dataset.name}
          onChange={(e) => onUpdate({ name: e.target.value })}
          className="bg-white/20 rounded px-2 py-1 flex-grow"
        />
        <button
          onClick={onDelete}
          className="text-red-500 hover:text-red-400 px-2"
        >
          Delete
        </button>
      </div>

      {/* Visibility Toggle */}
      <div className="flex items-center gap-2">
        <label>Visible:</label>
        <input
          type="checkbox"
          checked={dataset.visible}
          onChange={(e) => onUpdate({ visible: e.target.checked })}
        />
      </div>

      {/* Visualization Mode */}
      <div className="flex items-center gap-2">
        <label>Mode:</label>
        <select
          value={dataset.visualizationMode}
          onChange={(e) => onUpdate({ visualizationMode: e.target.value as VisualizationMode })}
          className="bg-white/20 rounded px-2 py-1 flex-grow"
        >
          <option value="binned">Binned Density</option>
          <option value="raw">Raw Data Points</option>
        </select>
      </div>

      {/* Object Type */}
      <div className="flex items-center gap-2">
        <label>Type:</label>
        <select
          value={dataset.objectType}
          onChange={(e) => onUpdate({ objectType: e.target.value as ObjectType })}
          className="bg-white/20 rounded px-2 py-1 flex-grow"
        >
          <option value="cube">Cubes</option>
          <option value="point">Points</option>
        </select>
      </div>

      {/* Opacity */}
      <div className="flex items-center gap-2">
        <label>Opacity:</label>
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={dataset.opacity}
          onChange={(e) => onUpdate({ opacity: Number(e.target.value) })}
          className="flex-grow"
        />
        <span>{dataset.opacity.toFixed(1)}</span>
      </div>

      {/* Color Settings */}
      <ColorSettingsPanel
        colorMapping={dataset.colorMapping}
        spaceData={dataset.data}
        dimensions={dimensions}
        onColorMappingChange={(mapping) => onUpdate({ colorMapping: mapping })}
      />

      {/* Data Loader */}
      <DataLoader
        onDataLoad={(data) => onUpdate({ data })}
        targetDimensions={dimensions}
        rawData={dataset.data}
        userMappingChangeRef={{ current: false }}
        showPoints={dataset.showPoints}
        setShowPoints={(showPoints) => onUpdate({ showPoints })}
        showArrows={dataset.showArrows}
        setShowArrows={(showArrows) => onUpdate({ showArrows })}
        arrowLengthMultiplier={dataset.arrowLengthMultiplier}
        setArrowLengthMultiplier={(arrowLengthMultiplier) => onUpdate({ arrowLengthMultiplier })}
      />

      {/* Add visualization controls */}
      <div className="space-y-2">
        <h3 className="font-semibold">Visualization Options</h3>
        <div className="flex items-center gap-4">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={dataset.showPoints}
              onChange={(e) => onUpdate({ showPoints: e.target.checked })}
              className="form-checkbox"
            />
            Show Points
          </label>

          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={dataset.showArrows}
              onChange={(e) => onUpdate({ showArrows: e.target.checked })}
              className="form-checkbox"
            />
            Show Arrows
          </label>
          <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={dataset.normalizeArrows}
                onChange={(e) => onUpdate({ normalizeArrows: e.target.checked })}
              className="form-checkbox"
            />
            Normalize 
          </label>
        </div>

        {dataset.showArrows && (
          <div className="space-y-1">
            <label className="block text-sm">
              Arrow Length Multiplier: {dataset.arrowLengthMultiplier.toFixed(2)}
            </label>
            <input
              type="range"
              min="0.1"
              max="5"
              step="0.1"
              value={dataset.arrowLengthMultiplier}
              onChange={(e) => onUpdate({ 
                arrowLengthMultiplier: parseFloat(e.target.value) 
              })}
              className="w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
} 