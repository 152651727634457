import { ObjectND } from './ObjectND';
import { VertexND } from './VertexND';
import { Edge } from './Edge';
import { CameraND } from './CameraND';

export class ArrowND extends ObjectND {
  private mainEdge: Edge;
  private arrowHeadEdges: Edge[] = [];
  private targetPosition: number[];


  constructor(
    dimensions: number,
    position: number[],
    direction: number[],
    length: number = 100,
    rotationAngles: number[] = Array(dimensions).fill(0),
    color: string = '#FFFFFF'
  ) {
    super(dimensions, position, color);
    
    // Normalize direction vector
    const magnitude = Math.sqrt(direction.reduce((sum, val) => sum + val * val, 0));
    const normalizedDir = direction.map(v => v / magnitude);
    
    // Calculate target position
    this.targetPosition = position.map((p, i) => p + normalizedDir[i] * length);
    
    // Create main vertices and edge
    const startVertex = new VertexND(dimensions, position);
    const endVertex = new VertexND(dimensions, this.targetPosition);
    this.vertices.push(startVertex, endVertex);
    this.mainEdge = new Edge(startVertex, endVertex, color, 3);

    // Create arrow head (90% of the way to target)
    const arrowBasePoint = position.map((p, i) => 
      p + normalizedDir[i] * length * 0.9
    );

    // Create perpendicular vectors for arrow head
    const arrowSize = length * 0.1; // 10% of total length
    for (let i = 0; i < 4; i++) {
      const angle = (Math.PI / 2) * i; // 0, 90, 180, 270 degrees
      const perpVector = this.createPerpendicularVector(normalizedDir, angle, arrowSize);
      
      const arrowTipPos = arrowBasePoint.map((p, j) => p + perpVector[j]);
      const arrowTipVertex = new VertexND(dimensions, arrowTipPos);
      this.vertices.push(arrowTipVertex);
      
      this.arrowHeadEdges.push(
        new Edge(arrowTipVertex, endVertex, color, 1)
      );
    }

    // Apply initial rotation after all vertices are created
    this.rotate(rotationAngles);
  }

  private createPerpendicularVector(dir: number[], angle: number, length: number): number[] {
    // Create a perpendicular vector in the first two dimensions
    const result = Array(this.dimensions).fill(0);
    
    // Find first non-zero component for rotation plane
    let firstNonZeroIndex = 0;
    let secondNonZeroIndex = 1;
    
    for (let i = 0; i < dir.length; i++) {
      if (Math.abs(dir[i]) > 0.001) {
        firstNonZeroIndex = i;
        secondNonZeroIndex = (i + 1) % dir.length;
        break;
      }
    }
    
    // Create perpendicular vector in the plane of the first two non-zero components
    const d1 = dir[firstNonZeroIndex];
    const d2 = dir[secondNonZeroIndex];
    const norm = Math.sqrt(d1 * d1 + d2 * d2);
    
    if (norm > 0.001) {
      result[firstNonZeroIndex] = (-d2 / norm) * Math.cos(angle) * length;
      result[secondNonZeroIndex] = (d1 / norm) * Math.cos(angle) * length;
    }
    
    return result;
  }

  rotate(rotationAngles: number[]) {
    this.vertices.forEach(vertex => vertex.rotate(rotationAngles));
  }

  project(camera: CameraND) {
    this.vertices.forEach(vertex => vertex.project(camera));
    this.averageDepth = this.vertices.reduce((sum, v) => sum + v.depth, 0) / this.vertices.length;
  }

  draw(ctx: CanvasRenderingContext2D) {
    // Draw main edge
    this.mainEdge.draw(ctx);
    
    // Draw arrow head edges
    this.arrowHeadEdges.forEach(edge => edge.draw(ctx));
  }
} 