import React, { useState } from 'react';

interface FilterSliderProps {
    columnName: string
    columnInfo: {
        type: string
        min?: number
        max?: number
        categories?: string[]
    }
    value: any
    onChange: (value: any) => void
}

const FilterSlider = ({ columnName, columnInfo, value, onChange, onRemove }: FilterSliderProps & { onRemove: () => void }) => {
    const [isInteracting, setIsInteracting] = useState(false);

    if (columnInfo.type === 'numeric') {
        const currentValue = value || columnInfo.min;
        const range = columnInfo.max! - columnInfo.min!;
        const stepSize = Math.max(1, Math.floor(range / 10));

        return (
            <div className="mb-4 p-2 bg-gray-600 rounded">
                <div className="flex justify-between items-center mb-2">
                    <span className="text-yellow-500 text-sm">{columnName}</span>
                    <button 
                        onClick={onRemove}
                        className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                        ✕
                    </button>
                </div>
                {/* Inner container that prevents drag */}
                <div 
                    className="relative"
                    draggable={false}
                    onDragStart={(e) => e.preventDefault()}
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    {/* Tick marks and values */}
                    <div className="absolute w-full flex justify-between text-xs text-gray-300 -top-4">
                        {Array.from({ length: 11 }, (_, i) => {
                            const tickValue = columnInfo.min! + (i * stepSize);
                            return tickValue <= columnInfo.max! ? (
                                <span key={i} className="relative" style={{ left: `${(i * 10)}%` }}>
                                    {tickValue}
                                </span>
                            ) : null;
                        })}
                    </div>
                    
                    {/* Current value indicator */}
                    <div 
                        className="absolute text-xs text-yellow-500 font-bold -top-4"
                        style={{ 
                            left: `${((currentValue - columnInfo.min!) / (columnInfo.max! - columnInfo.min!) * 100)}%`,
                            transform: 'translateX(-50%)'
                        }}
                    >
                        {currentValue}
                    </div>

                    <input
                        type="range"
                        min={columnInfo.min}
                        max={columnInfo.max}
                        step={stepSize}
                        value={currentValue}
                        onChange={(e) => onChange(Number(e.target.value))}
                        className="w-full accent-yellow-500 mt-6"
                    />
                </div>
            </div>
        )
    }

    if (columnInfo.type === 'categorical' && columnInfo.categories) {
        const currentValues = value || []
        return (
            <div className="mb-4 p-2 bg-gray-600 rounded">
                <div className="flex justify-between items-center mb-2">
                    <span className="text-yellow-500 text-sm">{columnName}</span>
                    <button 
                        onClick={onRemove}
                        className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                        ✕
                    </button>
                </div>
                {/* Inner container that prevents drag */}
                <div 
                    className="flex flex-wrap gap-1"
                    draggable={false}
                    onDragStart={(e) => e.preventDefault()}
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    {columnInfo.categories.map((category) => (
                        <button
                            key={category}
                            onClick={() => {
                                const newValues = currentValues.includes(category)
                                    ? currentValues.filter((v: string) => v !== category)
                                    : [...currentValues, category]
                                onChange(newValues)
                            }}
                            className={`px-2 py-1 text-xs rounded ${
                                currentValues.includes(category)
                                    ? 'bg-yellow-500 text-gray-900'
                                    : 'bg-gray-700 text-gray-300'
                            }`}
                        >
                            {category}
                        </button>
                    ))}
                </div>
            </div>
        )
    }

    return null // For unique and pseudounique columns
}

export default FilterSlider 