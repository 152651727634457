interface RoundedRectangleProps {
    x: number;          // x position (0-100)
    y: number;          // y position (0-100)
    width: number;      // width in normalized units (0-100)
    height: number;     // height in normalized units (0-100)
    radius: number;     // corner radius in pixels
    color: string;      // CSS color string (including rgba)
}

export class RoundedRectangle {
    private props: RoundedRectangleProps;

    constructor(props: RoundedRectangleProps) {
        this.props = {
            ...props,
            x: Math.max(0, Math.min(100, props.x)),
            y: Math.max(0, Math.min(100, props.y)),
            width: Math.max(0, Math.min(100, props.width)),
            height: Math.max(0, Math.min(100, props.height))
        };
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        const { x, y, width: rectWidth, height: rectHeight, radius, color } = this.props;
        
        // Convert normalized coordinates to pixel coordinates
        const pixelX = (x / 100) * width;
        const pixelY = (y / 100) * height;
        const pixelWidth = (rectWidth / 100) * width;
        const pixelHeight = (rectHeight / 100) * height;
        
        ctx.beginPath();
        ctx.moveTo(pixelX + radius, pixelY);
        ctx.lineTo(pixelX + pixelWidth - radius, pixelY);
        ctx.quadraticCurveTo(pixelX + pixelWidth, pixelY, pixelX + pixelWidth, pixelY + radius);
        ctx.lineTo(pixelX + pixelWidth, pixelY + pixelHeight - radius);
        ctx.quadraticCurveTo(pixelX + pixelWidth, pixelY + pixelHeight, pixelX + pixelWidth - radius, pixelY + pixelHeight);
        ctx.lineTo(pixelX + radius, pixelY + pixelHeight);
        ctx.quadraticCurveTo(pixelX, pixelY + pixelHeight, pixelX, pixelY + pixelHeight - radius);
        ctx.lineTo(pixelX, pixelY + radius);
        ctx.quadraticCurveTo(pixelX, pixelY, pixelX + radius, pixelY);
        ctx.closePath();
        
        ctx.fillStyle = color;
        ctx.fill();
    }
} 