import { ControlPanel } from '../ControlPanel';
import { ObjectType, VisualizationMode } from '../types/NDgraphics';

interface SpaceParametersPanelProps {
  dimensions: number;
  objectSize: number;
  globalOffset: number;
  globalZoom: number;
  rotationSpeed: number;
  globalDistance: number;
  brightness: number;
  useColor: boolean;
  cubeOpacity: number;
  showCoordinateSystem: boolean;
  nValues: number[];
  activeRotations: boolean[];
  onDimensionsChange: (dims: number) => void;
  onObjectSizeChange: (size: number) => void;
  onGlobalOffsetChange: (offset: number) => void;
  onGlobalZoomChange: (zoom: number) => void;
  onRotationSpeedChange: (speed: number) => void;
  onGlobalDistanceChange: (distance: number) => void;
  onBrightnessChange: (brightness: number) => void;
  onUseColorChange: (useColor: boolean) => void;
  onCubeOpacityChange: (opacity: number) => void;
  onShowCoordinateSystemChange: (show: boolean) => void;
  onNValuesChange: (values: number[]) => void;
  onActiveRotationsChange: (rotations: boolean[]) => void;
}

export function SpaceParametersPanel({
  dimensions,
  objectSize,
  globalOffset,
  globalZoom,
  rotationSpeed,
  globalDistance,
  brightness,
  useColor,
  cubeOpacity,
  showCoordinateSystem,
  nValues,
  activeRotations,
  onDimensionsChange,
  onObjectSizeChange,
  onGlobalOffsetChange,
  onGlobalZoomChange,
  onRotationSpeedChange,
  onGlobalDistanceChange,
  onBrightnessChange,
  onUseColorChange,
  onCubeOpacityChange,
  onShowCoordinateSystemChange,
  onNValuesChange,
  onActiveRotationsChange,
}: SpaceParametersPanelProps) {
  return (
    <ControlPanel title="Space parameters" defaultExpanded={false}>
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <label>Dimensions:</label>
            <input
              type="range"
              min="3"
              max="12"
              step="1"
              value={dimensions}
              onChange={(e) => onDimensionsChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{dimensions}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Object Size:</label>
            <input
              type="range"
              min="1"
              max="50"
              step="1"
              value={objectSize}
              onChange={(e) => onObjectSizeChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{objectSize}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Global Offset:</label>
            <input
              type="range"
              min="0.1"
              max="5"
              step="0.1"
              value={globalOffset}
              onChange={(e) => onGlobalOffsetChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{globalOffset.toFixed(1)}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Camera Zoom:</label>
            <input
              type="range"
              min="0.1"
              max="5"
              step="0.1"
              value={globalZoom}
              onChange={(e) => onGlobalZoomChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{globalZoom.toFixed(1)}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Rotation Speed:</label>
            <input
              type="range"
              min="-0.05"
              max="0.05"
              step="0.001"
              value={rotationSpeed}
              onChange={(e) => onRotationSpeedChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{rotationSpeed.toFixed(3)}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Global Distance:</label>
            <input
              type="range"
              min="0.1"
              max="5"
              step="0.1"
              value={globalDistance}
              onChange={(e) => onGlobalDistanceChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{globalDistance.toFixed(1)}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Brightness:</label>
            <input
              type="range"
              min="0.1"
              max="2"
              step="0.1"
              value={brightness}
              onChange={(e) => onBrightnessChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{brightness.toFixed(1)}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Use Color:</label>
            <input
              type="checkbox"
              checked={useColor}
              onChange={(e) => onUseColorChange(e.target.checked)}
              className="w-4 h-4"
            />
          </div>

          <div className="flex items-center gap-2">
            <label>Cube Opacity:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={cubeOpacity}
              onChange={(e) => onCubeOpacityChange(Number(e.target.value))}
              className="w-32 appearance-none accent-gray-400 bg-gray-600 rounded-full h-1"
            />
            <span>{cubeOpacity.toFixed(1)}</span>
          </div>

          <div className="flex items-center gap-2">
            <label>Show Coordinate System:</label>
            <input
              type="checkbox"
              checked={showCoordinateSystem}
              onChange={(e) => onShowCoordinateSystemChange(e.target.checked)}
              className="w-4 h-4"
            />
          </div>

          {/* N-Values Controls */}
          <div className="bg-[#361862] p-2 rounded">
            <h2 className="font-bold">N-Values</h2>
            <div className="grid grid-cols-3 gap-2">
              {nValues.map((value, i) => (
                <input
                  key={i}
                  type="number"
                  min="1"
                  value={value}
                  onChange={(e) => {
                    const newValues = [...nValues];
                    newValues[i] = Number(e.target.value);
                    onNValuesChange(newValues);
                  }}
                  className="bg-white/20 rounded px-2 py-1 w-20"
                />
              ))}
            </div>
          </div>

          {/* Rotation Controls */}
          <div className="bg-[#361862] p-2 rounded">
            <h2 className="font-bold">Rotations</h2>
            <div className="grid grid-cols-3 gap-2">
              {activeRotations.map((active, i) => (
                <div key={i} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={active}
                    onChange={(e) => {
                      const newRotations = [...activeRotations];
                      newRotations[i] = e.target.checked;
                      onActiveRotationsChange(newRotations);
                    }}
                    className="w-4 h-4"
                  />
                  <span>R{i}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ControlPanel>
  );
} 